import React, { useState } from 'react';
import Toggle from 'react-toggle';

const Permission = ({category, categoryPermissions, rolePermissions, setRolePermissions}) => {
  const [searchTerm, setSearchTerm] = useState('');

  const filteredPermissions = categoryPermissions.filter(permission => 
    permission.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    permission.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return(
    <React.Fragment>
      <div key={category} className="permission-category mb-5">
        <div className="panel panel-default border-all">
          <a className="panel-toggle collapsed display-flex align-items-center justify-content-between background-hover" 
            data-toggle="collapse" 
            data-parent="#accordion" 
            href={`#${category}`}>
            {category}
            <i className="fas fa-angle-down"></i>
          </a>

          <div id={category} className="panel-collapse collapse">
            <div className="panel-body">
              <input
                type="text"
                className="form-control-small permission-search"
                placeholder="Search permissions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              {filteredPermissions.map((permission) => (
                <div key={permission.token} className="display-flex align-items-center justify-content-between mb-3">
                  <div>
                    <div className="font-weight-600">
                      {permission.title}
                    </div>
                    <p className="opacity-4 font-12">{permission.description}</p>
                  </div>
                  
                  <Toggle 
                    checked={!rolePermissions.some(p => p.token === permission.token)}
                    onChange={() => {
                      setRolePermissions(prevState => 
                        prevState.some(p => p.token === permission.token)
                          ? prevState.filter(p => p.token !== permission.token)
                          : [...prevState, permission]
                      )
                    }} 
                    icons={false}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Permission;