import React , { useState, useEffect, useContext } from 'react';
import Notifications from "../Notifications/Index.js";
import { ProjectContext } from "../Projects/ProjectContext";

const MobileNavbar = ({}) => {
  const { setShowSidenav } = useContext(ProjectContext);

  return(
    <React.Fragment>
      <div className="platform-mobile-navbar">
        <div className="platform-mobile-navbar-left">
          <i onClick={() => setShowSidenav(true)} class="fal fa-bars border-radius background-hover" style={{padding: "7px 10px"}}></i>
        </div>

        <div className="platform-mobile-navbar-right">
          <Notifications />
        </div>
      </div>
    </React.Fragment>
  )
}

export default MobileNavbar;