import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import CopyToClipboard from 'react-copy-to-clipboard';

const InviteUser = ({invite, refetchData}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleDelete = () => {
    axios.delete(`/api/o/${match.params.organization_id}/invites/${invite.token}`)
    .then(function(response){
      console.log(response);
      refetchData();
    })
  }

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <tr className="hover-parent-opacity border-top">
        <td>
          <div className="display-flex">
            <img src={`https://ui-avatars.com/api/?name=${invite.email[0]}&length=1&background=3fade3&color=fff&format=png`} className="people-avatar"/>
            <div>
              <h5 className="font-weight-500 mb-0 mt-0 display-flex align-items-center">
                {invite.email} <span className="badge badge-orange ml-15">Pending</span>
                <CopyToClipboard text={`${window.location.origin}/organizations/${match.params.organization_id}/invites/${invite.token}`} onCopy={() => {setCopied(true); setTimeout(() => setCopied(false), 1000);}}>
                  {copied ? (
                    <span className="badge ml-8 badge-green animated zoomIn"><i className="fal fa-check"></i></span>
                  ):(
                    <a data-tip="Copy invite link" className="badge background-3 background-hover color-1 ml-8"><i className="fal fa-link"></i></a>
                  )}
                </CopyToClipboard>
              </h5>
            </div>
          </div>
        </td>
        <td></td>
        <td></td>
        {/* <td>
          <Select
            isDisabled={true}
            value={{value: invite.access_level, label: invite.access_level}}
            className="color-1"
            classNamePrefix="react-select"
            placeholder="Select..."
          />
        </td> */}
        <td className="max-width-150px">
          {invite.role &&
            <Select
              isDisabled={true}
              value={{value: invite.role?.token, label: invite.role?.title}}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Select..."
            />
          }
        </td>

        <td className="text-right">
          <div class="btn-group">
            <a type="button" class="btn btn-small background-hover color-1 dropdown-toggle opacity-4 opacity-10-hover" data-toggle="dropdown">
              <i className="fas fa-ellipsis-v"></i>
            </a>
            <ul class="dropdown-menu animated fadeInUp pull-right" role="menu">
              <li><a onClick={handleDelete}><i className="fal fa-trash-alt"></i>Remove invite</a></li>
            </ul>
          </div>
        </td>
      </tr>
    </React.Fragment>
  )
}

export default InviteUser;