import React , { useState } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { useTranslation } from 'react-i18next';

const PaypalAccount = ({paypalAccount, refetchData}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);

  const handleDelete = () => {
    const confirmThis = confirm("ARE YOU SURE?")
    if(confirmThis){
      setDisabled(true);
  
      axios.delete(`/api/o/${match.params.organization_id}/paypal_accounts/${paypalAccount.token}`)
      .then(function(response){
        // console.log(response);
        if(response.data.success){
          refetchData();
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
    }
  }

  return(
    <React.Fragment>
      <div className="row align-items-center animated fadeIn">
        <div className="col-12 col-md-8">
          <h5 className="font-weight-600"><i class="fab fa-paypal-s mr-5"></i>Remove PayPal integration</h5>
          <p>This will remove your PayPal integration. This will not cancel any active subscriptions, but will remove your clients ability to modify them on Queue.</p>
        </div>

        <div className="col-12 col-md-4 text-right">
          <button disabled={disabled} onClick={handleDelete} className="btn btn-danger-outline">Remove PayPal</button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PaypalAccount;