import React , { useState, useEffect, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ReactTooltip from 'react-tooltip';

const Edit = ({user_id, user, type, refetchData, organizationUser}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [accessType, setAccessType] = useState(type);


  // create both apis 
  const updateAccessType = (value) => {
    setAccessType(value);
    setDisabled(true);    
    axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/project_users/update_user_access?access_type=${value}&user_id=${user_id}&original_access_type=${type}`, {
      access_type: value, 
      user_id: user_id,
      original_access_type: type, 
      user_user_id: user.id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData()
        notice(`User permissions has been changed to ${value}.`)
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice(error)
    })
    .then(function () {
      setDisabled(false)
    });
  }

  const removeUser = () => {
    axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/project_users/remove_user?user_id=${user_id}&original_access_type=${type}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("User succesfully removed")
        refetchData()
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice(error)
    })
    .then(function () {
      setDisabled(false)
    });
  }

  const updatePrivate = (boolean) => {
    axios.patch(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/project_users/${user.identification_id}`,{
      private: boolean
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData()
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice(error)
    })
    .then(function () {
      setDisabled(false)
    });
  }

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className={`flex space-between mb-15 hover-parent-opacity ${user.private ? "opacity-4 opacity-10-hover animate" : ""}`}>
        <div className="display-row aic">
          <img src={user.avatar} className="avatar mr-8" />
          <div className="display-column">
            <div className="font-12 color-black-always">
              {user.username}

              {(organizationUser && !location.href.includes("/portal/o/")) && 
                <>
                  {user.type === "Collaborator" && 
                    <>
                      {user.private ?(
                        <span onClick={() => updatePrivate(false)} className="badge badge-red ml-8 cursor-pointer" data-tip="Disable private mode">Private</span>
                        ) : (
                        <span onClick={() => updatePrivate(true)} className="badge badge-red ml-8 hover-child-opacity cursor-pointer animate" data-tip="Enable private mode to prevent client from seeing this person">Private</span>
                      )}
                    </>
                  }
                </>
              }
            </div>
            {/* <div className="font-11 opacity-4 color-black-always">
              {user.email}
            </div> */}
          </div>
        </div>

          <div>
            {(user.organization_owner == true) ? (
              <div class="font-12">Owner</div>
            ):(
              <div className="display-column">
                <div class="btn-group">
                  {location.href.includes("/portal/") ? (
                    <a className="color-1 font-12">{accessType}</a>
                  ):(
                    <button type="button" className="font-13 dropdown-toggle color-black-4 background-none border-none dark-color-black" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{accessType} <span className="caret"></span></button>
                  )}
                    {((organizationUser === true) && (user_id != null)) && 
                      <ul className="dropdown-menu animated fadeIn dropdown-menu-right">
                        <li>
                          <a onClick={() => updateAccessType("Workspace member")} className="display-column" style={{whiteSpace: "normal"}}>
                            <div className="display-row justify-content-between aic">
                              <label className="cp" style={{marginBottom: "0px"}}>Workspace member</label>
                              {type == "Workspace member" && <i class="far fa-check font-13 color-blue"></i>}
                            </div>
                            <p className="font-11 opacity-4 margin-none color-black-always">
                              Can upload & review files in all projects. Can create, delete & edit all projects.
                            </p>
                          </a>
                        </li>
          
                        <li><a onClick={() => updateAccessType("Collaborator")} style={{whiteSpace: "normal"}}>
                          <div className="display-row justify-content-between aic">
                            <label className="cp" style={{marginBottom: "0px"}}>Collaborator</label>
                            {type == "Collaborator" && <i class="far fa-check font-13 color-blue"></i>}

                          </div>                    
                          <p className="font-11 opacity-4 margin-none color-black-always">Can upload and review files in this project only.</p>
                        </a></li>

                        <li><a onClick={() => updateAccessType("Client")} style={{whiteSpace: "normal"}}>
                          <div className="display-row justify-content-between aic">
                            <label className="cp" style={{marginBottom: "0px"}}>Client</label>
                            {type == "Client" && <i class="far fa-check font-13 color-blue"></i>}

                          </div>                    
                          <p className="font-11 opacity-4 margin-none color-black-always">Access to only this project without ability to view payment details.</p>
                        </a></li>
                        
                        <li><a onClick={removeUser}>
                          <label className="font-600 color-red opacity-7 cp font-11 hover-child-opacity">Remove User</label>
                        </a></li>
                      </ul>
                    }
                  </div>
                </div>
              )}
          </div>

      </div>
    </React.Fragment>
  )
}

export default Edit