import React from 'react';
import Skeleton from "react-loading-skeleton";

const DashboardSkeleton = () => {
  return(
    <React.Fragment>
      <div className="row">
        <div className="col-12 default-padding">
          <Skeleton className="skeleton-tab" style={{height: "60px"}} />
        </div>

        <div className="col-12 default-padding">
          <Skeleton className="skeleton-tab" style={{height: "60px"}} />
        </div>

        <div className="col-12 default-padding">
          <Skeleton className="skeleton-tab" style={{height: "60px"}} />
        </div>

        <div className="col-12 default-padding">
          <Skeleton className="skeleton-tab" style={{height: "60px"}} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default DashboardSkeleton