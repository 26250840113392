import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import {
  Menu,
  Item,
  useContextMenu,
  Submenu, 
  Separator
} from "react-contexify";

const User = ({user, task, project_id, setTask}) => {
  const [disabled, setDisabled] = useState(false);
  const match = useRouteMatch();

  if (location.href.includes("/portal/o/") && user.private) {
    // If condition is met, return null or an empty fragment to skip rendering
    return null;
  }

  const handleSubmit = () => {
    setDisabled(true);

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_users`, {
      email: user.email
    })
    .then(function(response){
      if(response.data.success){
        console.log(response);
        setTask(prevTask => ({
          ...prevTask,
          task_users: [...prevTask.task_users, response.data.task_user_data]
        }));
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <Item closeOnClick={false} onClick={handleSubmit}>
      <div className="display-flex align-items-center justify-content-between label-item-hover-parent" style={{width: "100%"}}>
        <div className="display-flex align-items-center">
          <img src={user.avatar} className="task-user-form-avatar"/>{user.username}
        </div>
      </div>
    </Item>
  )
}

export default User;