import React , { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import {UserContext} from "../../../Shared/UserContext.js";
import { Modal } from 'react-responsive-modal';
import axios from "axios"; 
import Linkify from 'react-linkify';
import localForage from "localforage";
import Upload from "./Upload.js";
import Form from "./TaskComments/Form.js";
import TaskUsers from "./TaskForms/TaskUsers.js";
import DefaultQuestions from "./TaskForms/DefaultQuestions.js";
import TaskComment from "./TaskComments/TaskComment.js";
import Pusher from 'pusher-js';
import DisplayHTML from "../../../Shared/DisplayHTML.js";
import DOMPurify from 'dompurify';
import Editor from 'react-simple-wysiwyg';
import TaskReplay from "./TaskReplay.js";
import Skeleton from "react-loading-skeleton"
import SubTask from "./SubTask.js";
import { AppContext } from "../../../Shared/AppContext.js";
import Files from "./Task/Files.js";
import Field from "./Task/Field.js";
import TipTap from "../../../Shared/TipTap.js";
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Menu,
  Item,
  useContextMenu,
  Separator
} from "react-contexify";
import _ from 'lodash';
import TaskLabels from "../../../Labels/TaskLabels.js";
import {TaskboardContext} from "../../../Shared/TaskboardContext.js";
import DatePicker from "react-datepicker";
import TaskOwner from "./Task/TaskOwner.js";
import StageIcon from "../../../Shared/StageIcon.js";
import Cover from "./Task/Cover.js";
import { useHotkeys } from 'react-hotkeys-hook'
import TaskLabelPicked from "../../../Labels/TaskLabelPicked.js";
import ReactTooltip from 'react-tooltip';
import AiSubtasks from "./TaskForms/AiSubtasks.js";
import {OrganizationContext} from "../../../Shared/OrganizationContext.js";
import NewProperty from "./Task/NewProperty.js";
import UploadAttachements from "./Task/UploadAttachements.js";

const FullCard = ({refetchData, project_id, task_id}) => {
  const {hasPermission} = useContext(OrganizationContext)
  const {labels, organizationUser, projectAuthorizations, setOpenNewLabel} = useContext(TaskboardContext);
  const history = useHistory();
  const currentUser = useContext(UserContext);
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [task, setTask] = useState();
  const [description, setDescription] = useState("");
  const [editDescription, setEditDescription] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [taskComments, setTaskComments] = useState([]);
  const [replays, setReplays] = useState([]);
  const [openDefaultQuestions, setOpenDefaultQuestions] = useState(false)
  const [taskAttachements, setTaskAttachements] = useState([]);
  const [limit, setLimit] = useState(10);
  const [loadingComments, setLoadingComments] = useState(false);
  const [taskCommentsSize, setTaskCommentsSize] = useState(null);
  const [taskCommentsLoaded, setTaskCommentsLoaded] = useState(false);
  const [openFiles, setOpenFiles] = useState(false);
  const [subtasks, setSubtasks] = useState([]);
  const [taskLabels, setTaskLabels] = useState([]);
  const [settingVersions, setSettingVersions] = useState(false);
  const [showSubtasks, setShowSubtasks] = useState(true);
  const [usersStrings, setUsersStrings] = useState([]);
  const [openCover, setOpenCover] = useState(false);
  const [openParentTask, setOpenParentTask] = useState(false);
  const [selectedSubtaskIndex, setSelectedSubtaskIndex] = useState(-1);
  const [openSubtaskToken, setOpenSubtaskToken] = useState(null);
  const clientPortal = window.location.pathname.includes("/portal/o/")
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    axios.get(`/api/portal/projects/${project_id || match.params.project_id}/columns.json`)
    .then(function(response){
      console.log(response);
      setColumns(response.data.columns)
    })
  }, [])

  // Add this useEffect hook to scroll the selected subtask into view
  useEffect(() => {
    if (selectedSubtaskIndex >= 0 && selectedSubtaskIndex < subtasks.length) {
      const selectedElement = document.getElementById(`subtask-${subtasks[selectedSubtaskIndex].token}`);
      if (selectedElement) {
        selectedElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [selectedSubtaskIndex]);

  // Add these new hotkey handlers
  // Add this new hotkey handler for the Escape key
  useHotkeys('esc', () => {
    setSelectedSubtaskIndex(-1);
  }, []);

  useHotkeys('enter', () => {
    if (selectedSubtaskIndex >= 0 && selectedSubtaskIndex < subtasks.length) {
      setOpenSubtaskToken(subtasks[selectedSubtaskIndex].token);
    }
  }, [selectedSubtaskIndex, subtasks]);

  useHotkeys('j', () => {
    setSelectedSubtaskIndex((prevIndex) => 
      prevIndex < subtasks.length - 1 ? prevIndex + 1 : prevIndex
    );
  }, [subtasks]);

  useHotkeys('k', () => {
    setSelectedSubtaskIndex((prevIndex) => 
      prevIndex > 0 ? prevIndex - 1 : prevIndex
    );
  }, [subtasks]);
  
  useHotkeys('s', () => setOpenDefaultQuestions(true), [])
  useHotkeys('l', (event) => {
    event.preventDefault();
    displayMenu(event);
  }, [displayMenu]);

  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      const value = await localForage.getItem(`task-show-${task_id}-v2`);
      if(value){
        setTask(value.task);
        setReplays(value.task.replays);
        setTaskAttachements(value.task.task_attachements);
        setSubtasks(value.subtasks || []);
        setUsersStrings(value.task.user_strings);
        setTaskLabels(value.task_labels || []);
        setLoaded(true);  
      }
      await fetchTask(signal);
    }

    fetchData();
    
    return () => {
      controller.abort();
    }
  }, [task_id]);

  // Update LocalForage when subtasks state changes
  useEffect(() => {
    localForage.getItem(`task-show-${task_id}-v2`)
      .then(function (value) {
        if (value) {
          value.task = task;
          value.subtasks = subtasks;
          value.task_labels = taskLabels;
          localForage.setItem(`task-show-${task_id}-v2`, value)
            .then(function (updatedValue) {
              console.log('Subtasks updated in LocalForage:', updatedValue.subtasks);
            })
            .catch(function(err) {
              console.error('Error updating subtasks in LocalForage:', err);
            });
        }
      })
      .catch(function(err) {
        console.error('Error loading data from LocalForage:', err);
      });
  }, [task, subtasks, taskLabels]);

  useEffect(() => {
    // setLoadingComments(true)
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      const value = await localForage.getItem(`task-comments-${task_id}`);
      if(value){
        setTaskComments(value.task_comments);
        setTaskCommentsSize(value.task_comments_size);
        setTaskCommentsLoaded(true)
      }
      await fetchTaskComments(signal);
    }

    fetchData();


    return () => {
      controller.abort();
    }
  },[limit])

  useEffect(() => {
    const pusher = new Pusher('683f89863cff332fae20', {cluster: 'us2'});
    const channel = pusher.subscribe(`project-${project_id || match.params.project_id}-tasks-${task_id}-task_comments`);

    channel.bind('subscription', data => {
      // Handle the incoming event data here
      console.log('Received data:', data);
      if(data.user_id != currentUser.id){
        fetchTask();
        fetchTaskComments();
        refetchData();
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);

  const fetchTask = async (signal) => {
    try {
      const response = await axios.get(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}.json`, {signal})

      if(response.data.success){
        setTask(response.data.task);
        setReplays(response.data.task.replays);
        setTaskAttachements(response.data.task.task_attachements);
        setSubtasks(response.data.task.subtasks)
        setUsersStrings(response.data.task.user_strings);
        setTaskLabels(response.data.task.task_labels);
        setLoaded(true);

        await localForage.setItem(`task-show-${task_id}-v2`, response.data);

        // Initialize jQuery drag and drop
        $(".task-draggable").draggable({
          revert: "invalid",
          zIndex: 100,
          stack: ".task-draggable",
          start: function(event, ui) {
            $(this).addClass("dragging"); // Add a class when dragging starts
            $(this).addClass("background-active"); // Add class when draggable is dragged over
          },
          stop: function(event, ui) {
            $(this).removeClass("dragging"); // Remove the class when dragging stops
            $(this).removeClass("background-active"); // Remove class when draggable is dragged out
          }
        });

        $(".task-droppable").droppable({
          accept: ".task-draggable",
          drop: function(event, ui) {
            const draggedReplay = ui.draggable;
            const droppedReplay = $(this);
            createVersion(draggedReplay.data("replay_id"), droppedReplay.data("replay_id"));
          },
          over: function(event, ui) {
            
          },
          out: function(event, ui) {
            
          }
        });
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
    }
  }

  const createVersion = (dragged_replay_id, dropped_replay_id) => {
    // Filter out the replay with the specified id
    setSettingVersions(true)
    const updatedReplays = replays.filter(replay => replay.token !== dragged_replay_id);
    // Update state with the filtered replays
    setReplays(updatedReplays);

    axios.post(`/api/replays/${dropped_replay_id}/create_ancestory`, {
      dragged_replay_id: dragged_replay_id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        fetchTask();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
      setSettingVersions(false);
    });
  }

  const fetchTaskComments = async (signal) => {
    try {
      const response = await axios.get(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}/task_comments.json?limit=${limit}`, {signal})
      if(response.data.success){
        setTaskComments(response.data.task_comments);
        setTaskCommentsSize(response.data.task_comments_size);
        setTaskCommentsLoaded(true); 
        await localForage.setItem(`task-comments-${task_id}`, response.data)
      } else {
        notice(response.data.error);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
    }
  };

  const updateDescription = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      description: description
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        fetchTask();
        setEditDescription(false);
        // notice("Update successful");
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const updateDate = (date) => {
    console.log(date);
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      deadline: date
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        fetchTask();
        setEditDescription(false);
        // notice("Update successful")
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handlePriorityChange = (selected_priority) => {
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      priority: selected_priority
    })
    .then(function(response){
      if(response.data.success){
        fetchTask();
        setEditDescription(false);
        refetchData();
        // notice("Update successful");
        // Update the task state immediately
        setTask(prevTask => ({
          ...prevTask,
          priority: response.data.task_data.priority
        }));
      } else {
        notice("Failed to update priority");
      }
    })
    .catch(function(error){
      console.log(error);
      notice("An error occurred");
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const handleDeadlineChange = (date) => {

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      deadline: date
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setTask(prevTask => ({
          ...prevTask,
          deadline: response.data.task_data.deadline
        }));
        fetchTask();
        setEditDescription(false);
        refetchData();
        // notice("Update successful");
      } else {
        notice("Failed to update deadline");
      }
    })
    .catch(function(error){
      console.log(error);
      notice("An error occurred");
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const task_comments_list = taskComments.map(task_comment => {
    return(
      <TaskComment task_comment={task_comment} taskComments={taskComments} setTaskComments={setTaskComments} refetchData={fetchTaskComments} project_id={project_id} task={task} users={usersStrings} projectAuthorizations={projectAuthorizations} fetchTask={fetchTask}/>
    )
  })

  const handleTitle = (e) => {
    // Perform any actions needed when editing is finished
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      title: e.target.textContent
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        fetchTask();
        // notice("Update successful");
      }
    })
  };

  const replays_list = replays.map(replay => {
    return(
      <div id={`task-replay-${replay.replay_id}`} className="task-draggable task-droppable background-3" data-parent_id={replay.parent_id} data-replay_id={replay.replay_id}>
        <TaskReplay replay={replay}  refetchData={() => {fetchTaskComments(); fetchTask();}} task_id={task_id} project_id={project_id || match.params.project_id}/>
        <div className="task-droppable-message animated fadeIn">
          <i class="far fa-plus mr-8"></i>Drag on top another file to add new version
        </div>
      </div>
    )
  });

  const field_data_list = task?.field_data.filter(item => item.type != "line").map(field => {
    return(
      <React.Fragment>
        <Field field={field} project_id={project_id || match.params.project_id} refetchData={refetchData} task_id={task_id} task={task} setTask={setTask} users={usersStrings}/> 
      </React.Fragment>
    )
  });

  const changeColumn = (column_id) => {
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}/update_position`, {
      column_id: column_id,
      position: 0,
    })
    .then(function (response) {
      console.log(response);
      if (response.data.success && response.data.task_data) {
        setTask(prevTask => ({
          ...prevTask,
          column: response.data.task_data.column
        }));
        refetchData();
      } else {
        notice("Failed to update column");
      }
    })
    .catch(function (error) {
      console.log(error);
      notice("An error occurred while updating the column");
    });
  }

  const columns_list = columns.filter(column => column.token !== task?.column.token).map(column => (
    <li key={column.token}>
      <a disabled={column.full} className={`stage-dropdown-a display-flex align-items-center justify-content-between ${column.full ? "disabled" : ""}`} onClick={() => changeColumn(column.token)}>
        <div className="display-flex align-items-center">
          <StageIcon stage={column.stage}/>{column.title}
        </div>
        {column.full && <span className="badge background-active ml-15">Full</span>}
      </a>
    </li>
  ));
  

  const task_attachements_list = taskAttachements.map(attachement => {
    return(
      <div className="filepicker-attachement-item">
        <a target="_blank" className="color-blue" href={attachement.url}>{attachement.title}</a>
        {/* <ZipDownloader files={[attachement.url]} message="" classNames="btn btn-primary-outline btn-small hover-child-opacity ml-15 border-none" /> */}
      </div>
    )
  });

  const markFinished = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}`, {
      finished: !task.finished
    })
    .then(function(response){
      if(response.data.success){
        if(!window.location.href.includes(`/o/${match.params.organization_id}/dashboard`)){
          refetchData();
          notice("Saved")
        }
        fetchTask();
        // Immediately update the task.finished state
        setTask(prevTask => ({
          ...prevTask,
          finished: !prevTask.finished
        }));
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occurred");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }
  
  const updatePrivacy = (boolean) => {
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}`, {
      private: boolean
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Updated visability")
        refetchData();
        fetchTask();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {

    });
  }

  // Subtask reorder
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  
    // Create a copy of the current subtasks list
    const newSubtasks = Array.from(subtasks);
  
    // Remove the dragged subtask from its original position
    const [removed] = newSubtasks.splice(result.source.index, 1);
  
    // Insert the dragged subtask into the destination position
    newSubtasks.splice(result.destination.index, 0, removed);
  
    // Update the state with the reordered subtasks
    setSubtasks(newSubtasks);

    // Send Axios POST request to update positions of subtasks in the backend
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${removed.token}/update_subtask_position`, { 
      task_id: removed.token,
      newPosition: result.destination.index,
      newSubtasks: newSubtasks
    })
      .then((response) => {
        // notice("Moved successfully")
      })
      .catch((error) => {
        // Handle error
      });
  };

  const task_labels_list = taskLabels.map(task_label => {
    return(
      <span className={`badge border-all mr-8 font-14`}>
        <span className="badge-label-color" style={{background: `#${task_label.label.color}`}}></span>
        {task_label.label.title}
      </span>
    )
  });

  const picked_task_labels_list = taskLabels.map(task_label => {
    return(
      <TaskLabelPicked task_label={task_label} task={task} refetchData={() => {refetchData();}} setArray={setTaskLabels}/>
    )
  })
  
  const labels_list = labels.filter(label => !taskLabels.some(taskLabel => taskLabel.label.id === label.id)).map(label => {
    return(
      <TaskLabels label={label} task={task} refetchData={() => {refetchData();}} setArray={setTaskLabels} taskLabels={taskLabels}/>
    )
  })

  const labelMenuAnchorRef = useRef(null);
  const { show } = useContextMenu({
    id: `task-${task_id}-labels-menu`
  });
  function displayMenu(e){
    e.preventDefault();
    e.stopPropagation();
    
    const anchorRect = labelMenuAnchorRef.current.getBoundingClientRect();
    
    show({
      event: e,
      position: {
        x: anchorRect.left,
        y: anchorRect.bottom
      }
    });
  }
  
  return(
    loaded ?(
      <React.Fragment>
        {/* Card info */}
        <div className="full-card-wrapper hover-parent-opacity">
          {task.unsplash_data && 
            <div className="unsplash-fullcard-cover cursor-pointer" style={{background: task.unsplash_data.color}} onClick={() => setOpenCover(true)}>
              <img className="unsplash-fullcard-cover-image" src={task.unsplash_data.urls.full}/>
            </div>
          }

          {task.cover_url && 
            <div className="unsplash-fullcard-cover background-3 cursor-pointer" onClick={() => setOpenCover(true)}>
              <img className="unsplash-fullcard-cover-image" src={task.cover_url}/>
            </div>
          }
          
          <div className="display-flex align-items-center mb-15 justify-content-center">
            {(task.cover_url === null && task.cover_url === null) &&
              <a className="color-1 btn btn-small background-hover hover-child-opacity" onClick={() => setOpenCover(true)}>
                <i className="far fa-image-polaroid mr-5"></i>Cover
              </a>
            }

            <Modal open={openCover} onClose={() => setOpenCover(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
              <Cover task_id={task.token} refetchData={() => {refetchData(); fetchTask();}} closeModal={() => setOpenCover(false)} cover_exists={task.cover_url != null || task.unsplash_data != null}/>
            </Modal>
          </div>

          {/* <div className="row full-card-info-wrapper">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-network-wired mr-5"></i>Project
              </label>
            </div>

            <div className="col-12 col-md-9">
              <Link to={`/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}`} className="btn btn-small background-hover color-1">
                {projectAuthorizations.title}
              </Link>
            </div>
          </div> */}
          
          {/* Parent */}
          {(task.parent_task != null) &&
            <div className="row full-card-info-wrapper">
              <div className="col-12 col-md-3 pr-15 task-question-answer-label">
                <label className="task-list-label">
                  <i class="fas fa-network-wired mr-5"></i>Parent Task
                </label>
              </div>

              <div className="col-12 col-md-9">
                <a className="btn btn-small background-hover color-1" onClick={() => setOpenParentTask(true)}>
                  {task.parent_task.title}
                </a>
                <Modal focusTrapped={false} open={openParentTask} onClose={() => setOpenParentTask(false)} className="modal-body-white" classNames={{modal: 'modal-side'}} center>
                  <FullCard refetchData={refetchData} project_id={project_id || match.params.project_id} task_id={task.parent_task.token} />
                </Modal>
              </div>
            </div>
          }

          {/* Title */}
          <div className="row full-card-info-wrapper">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-text mr-5"></i>Title
              </label>
            </div>

            <div className="col-12 col-md-9 dark-color-black task-question-answer" contentEditable onBlur={handleTitle}>
              {task.title}
            </div>
          </div>

          {/* Service Form */}
          {task.service_form && 
            <div className="row full-card-info-wrapper">
              <div className="col-12 col-md-3 pr-15 task-question-answer-label">
                <label className="task-list-label">
                  <i class="fas fa-file-alt mr-5"></i>{t("Task Form")}
                </label>
              </div>

              <div className="col-12 col-md-9 dark-color-black task-question-answer">
                {task.service_form?.title}
              </div>
            </div>
          }

          {/* Column */}
          <div className="row full-card-info-wrapper">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-tasks mr-5"></i>{t("Stage")}
              </label>
            </div>

            <div className="col-12 col-md-9 task-question-answer">
              <div class="btn-group">
                <div type="button" class="color-1 dropdown-toggle badge font-14 pl-0 cursor-pointer display-flex align-items-center" data-toggle={hasPermission("update_stage_task") ? "dropdown" : ""}>
                  <StageIcon stage={task.column.stage}/>{task.column.title}
                </div>

                <ul class="dropdown-menu animated fadeInUp" role="menu">
                  {columns_list}
                </ul>
              </div>
            </div>
          </div>

          {/* Private */}
          {!clientPortal && 
            <div className="row full-card-info-wrapper">
              <div className="col-12 col-md-3 pr-15 task-question-answer-label">
                <label className="task-list-label">
                  <i class="fas fa-lock mr-5"></i>{t("Visibility")}
                </label>
              </div>

              <div className={`col-12 col-md-9 task-question-answer`}>
                <div class="btn-group dropup">
                    {task.private ? (
                      <React.Fragment>
                        <a type="button" class="color-red dropdown-toggle badge font-14 pl-0 cursor-pointer display-flex align-items-center" data-toggle="dropdown">
                          <i class="fal fa-lock mr-15"></i>{t("Team only")}
                        </a>
                      </React.Fragment>
                    ):(
                      <React.Fragment>
                        <a type="button" class="color-1 dropdown-toggle badge font-14 pl-0 cursor-pointer display-flex align-items-center" data-toggle="dropdown">
                          <i class="far fa-globe-americas mr-15"></i>{t("Everyone")}
                        </a>
                      </React.Fragment>
                    )}
                  <ul class="dropdown-menu animated fadeInUp" role="menu">
                    <li><a onClick={() => updatePrivacy(false)}><i class="far fa-globe-americas mr-5"></i>{t("Everyone")}</a></li>
                    <li><a onClick={() => updatePrivacy(true)}><i class="fal fa-lock mr-5"></i>{t("Team only")}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          }

          {/* Creator */}
          <div className="row full-card-info-wrapper">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-user mr-5"></i>{"Created by"}
              </label>
            </div>

            <div className="col-12 col-md-9 task-question-answer">
              <TaskOwner refetchData={fetchTask} task={task} project_id={project_id}/>
            </div>
          </div>

          {/* Created_at */}
          <div className="row full-card-info-wrapper">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-calendar-day mr-5"></i>{t("Created")}
              </label>
            </div>

            <div className="col-12 col-md-9 task-question-answer">
              {task.created_at}
            </div>
          </div>

          {/* deadline */}
          <div className="row full-card-info-wrapper align-items-center">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-calendar-day mr-5"></i>{t("Due date")}
              </label>
            </div>

            <div className={`col-12 col-md-9 ${task.finished ? "color-green" : ""} display-flex align-items-center task-question-answer`}>
              <div style={{display: "flex", alignItems: "center"}}>
                {!clientPortal ? (
                  <>
                    <div onClick={markFinished} style={{marginLeft: "2px"}}>
                      {task.deadline != null &&
                        <>
                          {task.finished ? (
                            <i className="far fa-check-square mr-5 cursor-pointer opacity-4 opacity-10-hover" data-tip="Mark unfinished"></i>
                          ) : (
                            <i className="far fa-square mr-5 cursor-pointer opacity-4 opacity-10-hover" data-tip="Mark finished"></i>
                          )}
                        </>
                      }
                    </div>
                    
                    <DatePicker
                      selected={task.deadline}
                      onChange={(date) => handleDeadlineChange(date)}
                      className="task-question-answer-input task-form-answer-empty"
                      placeholderText="Empty"
                      disabled={!hasPermission("deadline_task")}
                    />
                  </>
                ):(
                  <>
                    {task.deadline_short}
                  </>
                )}

              </div>
            </div>
          </div>

          {/* Priority */}
          <div className="row full-card-info-wrapper align-items-center">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-exclamation-square mr-5"></i>{t("Priority")}
              </label>
            </div>

            <div className="col-12 col-md-9 task-question-answer hover-parent-opacity">
              <div class="btn-group">
                {(task.priority != null && task.priority != "") ? (
                  <>
                    <span class="badge border-all mr-8 font-14 cursor-pointer dropdown-toggle" data-toggle="dropdown">
                      <span class="badge-label-color" style={{background: `${task.priority === "High" ? "#dc3545" : task.priority === "Medium" ? "#ffab58" : "#4169e1"}`}}></span>
                      {task.priority}
                    </span>
                  </>
                ):(
                  <div class="badge task-form-answer-empty dropdown-toggle" data-toggle="dropdown">
                    Empty
                  </div>
                )}
                
                <ul class="dropdown-menu animated fadeInUp" role="menu">
                  <li>
                    <a onClick={() => handlePriorityChange(null)}>
                      <i className="far fa-circle"></i> No priority
                      {task.priority === null && 
                        <i className="fal fa-check ml-8 font-13"></i>
                      }
                    </a>
                  </li>

                  <li>
                    <a onClick={() => handlePriorityChange("High")}>
                      <i className="fas fa-circle color-red"></i>High
                      {task.priority === "High" && 
                        <i className="fal fa-check ml-8 font-13"></i>
                      }
                    </a>
                  </li>

                  <li>
                    <a onClick={() => handlePriorityChange("Medium")}>
                      <i className="fas fa-circle color-orange"></i>Medium
                      {task.priority === "Medium" && 
                        <i className="fal fa-check ml-8 font-13"></i>
                      }
                    </a>
                  </li>

                  <li>
                    <a onClick={() => handlePriorityChange("Low")}>
                      <i className="fas fa-circle color-blue"></i>Low
                      {task.priority === "Low" && 
                        <i className="fal fa-check ml-8 font-13"></i>
                      }
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Task Users  */}
          <div className="row full-card-info-wrapper display-flex align-items-center">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-users mr-5"></i>{t("Members")} 
              </label>
            </div>

            <div className="col-12 col-md-9 task-question-answer">
              <TaskUsers usersStrings={usersStrings} task={task} users={task.users} refetchData={() => {fetchTask();refetchData();}} organizationUser={organizationUser} project_id={project_id} setTask={setTask}/>
            </div>
          </div>

          {/* Labels */}
          <div className="row full-card-info-wrapper display-flex align-items-center">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-tag mr-5"></i>{t("Labels")} 
              </label>
            </div>

            <div className="col-12 col-md-9 dark-color-black task-question-answer task-label-answer-wrapper">
              {task_labels_list}
              
              {taskLabels.length === 0 ? (
                <span className="badge task-form-answer-empty" ref={labelMenuAnchorRef} onClick={displayMenu}>Empty</span>
              ) : (
                <>
                  {(!clientPortal && hasPermission("new_task_label")) && 
                    <span data-html={true} data-tip={`<div class="tooltip-keybind-wrapper"><span class="tooltip-keybind">L</span></div>`} ref={labelMenuAnchorRef} onClick={displayMenu} className="badge border-all cursor-pointer background-hover font-14 task-label-answer-btn"><i class="far fa-plus"></i></span>
                  }
                </>
              )}
              
              {hasPermission("new_task_label") && 
                <Menu id={`task-${task.token}-labels-menu`}>
                  {labels.length > 0 &&
                    <>
                    {taskLabels.length > 0 && 
                      <>
                        {picked_task_labels_list}
                        <Separator />
                      </>
                    }
                      {labels_list}
                    </>
                  }

                  <Item onClick={() => setOpenNewLabel(true)}>
                    <i className="far fa-plus mr-8"></i>New Label
                  </Item>
                </Menu>
              }
            </div>
          </div>

          {/* Description */}
          <div className="row full-card-info-wrapper">
            <div className="col-12 col-md-3 pr-15 task-question-answer-label">
              <label className="task-list-label">
                <i class="fas fa-align-left mr-5"></i>{t("Description")}
              </label>
            </div>

            <div className="col-12 col-md-9 task-question-answer" style={{display: "unset"}} onClick={(e) => {
              if (e.target.tagName.toLowerCase() !== 'a' && !editDescription) {
                setDescription(task.description);
                setEditDescription(!editDescription);
              }
            }}>
              {editDescription ?(
                <form onSubmit={updateDescription}>
                  <TipTap comment={description ? description.replace(/\n/g, '<br/>') : description} setComment={setDescription} users={usersStrings}/>
                  <div className="field text-right default-padding">
                    <a className="btn btn-small background-hover color-1 mr-5" onClick={() => setEditDescription(false)}>Cancel</a>
                    <button type="submit" className="btn btn-primary btn-small">Update</button>
                  </div>
                </form>
              ):(
                <>
                  {task.description ? (
                    <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
                    )}>
                      <DisplayHTML htmlContent={DOMPurify.sanitize(task.description)} classNames="tiptap-render"/>
                    </Linkify>
                  ):(
                    <span className="opacity-4">Empty</span>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Task attachements */}
          {taskAttachements.length > 0 && 
            <div className="row full-card-info-wrapper">
              <div className="col-12 col-md-3 pr-15 task-question-answer-label">
                <label className="task-list-label">
                  <i class="far fa-link mr-5"></i>{t("Attachments")}
                </label>
              </div>
              
              <div className="col-12 col-md-9 task-question-answer display-flex align-items-center">
                <a className="font-14 opacity-8 opacity-10-hover" onClick={() => setOpenFiles(true)}>
                  {taskAttachements.length} Uploads
                </a>
                <Modal open={openFiles} onClose={() => setOpenFiles(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                  <Files attachements={taskAttachements} zipFileName={task.title}/>
                </Modal>

                <UploadAttachements task={task} setTask={setTask} refetchData={fetchTask} project_id={project_id || match.params.project_id}/>
              </div>
            </div>
          }
          
          {field_data_list}
        </div>

        <div className="field mt-10">
          <NewProperty refetchData={fetchTask} task={task} setTask={setTask} project_id={project_id || match.params.project_id}/>
        </div>

        {/* Subtask */}
        <div className="row mt-25">
          {subtasks.length > 0 && 
            <div className="border-bottom width-100-percent mb-10">
              <label className="cursor-pointer btn btn-small background-hover" onClick={() => setShowSubtasks(!showSubtasks)}>
                <i class={`fas ${showSubtasks ? "fa-caret-down" : "fa-caret-right"} mr-8`}></i>
                Subtasks
              </label>
            </div>
          }

          {showSubtasks && 
          <>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {subtasks.map((subtask, index) => (
                      <Draggable key={subtask.token} draggableId={subtask.token} index={index}>
                        {(provided) => (
                          <div className={`row subtask-list-item align-items-center ${index === selectedSubtaskIndex ? 'selected-subtask' : ''}`}  ref={provided.innerRef} {...provided.draggableProps}>
                            <div data-tip="Drag to change position" className="col-1" style={{width: 20}}>
                              <i {...provided.dragHandleProps} style={{width: 'unset'}} className="fa fa-grip-lines opacity-4 mr-8"></i>
                            </div>

                            <SubTask 
                              setSubtasks={setSubtasks}
                              subtask={subtask} 
                              columns={columns} 
                              fetchTask={fetchTask}
                              refetchData={() =>{refetchData()}} 
                              organizationUser={organizationUser} 
                              project_id={project_id} 
                              openSubtaskToken={openSubtaskToken}
                              setOpenSubtaskToken={setOpenSubtaskToken}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>

            <div className="display-flex align-items-center mt-15">
              <a data-html={true} data-tip={`<div class="tooltip-keybind-wrapper"><span class="tooltip-keybind">s</span></div>`} style={{width: "max-content"}} onClick={() => setOpenDefaultQuestions(true)} className="btn btn-small background-hover color-1 border-all mr-5"><i className="fas fa-plus mr-8"></i>Add subtask</a>

              <AiSubtasks refetchData={() => {refetchData(); fetchTask();}} column={task.column} parent_task_id={task.token} closeModal={() => setOpenDefaultQuestions(false)} projectAuthorizations={projectAuthorizations} project_id={project_id} users={usersStrings} setArray={setSubtasks}/>
            </div>


            <Modal open={openDefaultQuestions} onClose={() => setOpenDefaultQuestions(false)} className="modal-body-white" classNames={{modal: 'modal-side'}} center>
              <DefaultQuestions refetchData={() => {refetchData(); fetchTask();}} column={task.column} parent_task_id={task.token} closeModal={() => setOpenDefaultQuestions(false)} projectAuthorizations={projectAuthorizations} project_id={project_id} usersStrings={usersStrings} setArray={setSubtasks}/>
            </Modal>
          </>
          }
        </div>

        
        {/* Review requests */}
        <div className="row mt-25">
          <div className="daas-card-reviews-cta-wrapper">
            <h4 className="font-weight-600">
              {/* <i class="far color-3 fa-upload mr-5"></i> */}
              {t("Reviews requests")}
              {/* <p className="font-12 opacity-6">{t("Share files and websites for click-to-comment reviewing.")}</p> */}
            </h4>

            {(!clientPortal && hasPermission("new_task_replay")) && 
              <Upload refetchData={fetchTask} task_id={task.token} project_id={project_id || match.params.project_id} task={task}/>
            }
          </div>
          
          {settingVersions ? (
            <p className="text-center">
              <i className="fas fa-spinner fa-spin mr-8"></i>Adding version...
            </p>
          ):(
            <div className="daas-card-reviews-replays">
              {replays.length > 0 ? (
                <>
                {replays_list}
                </>
              ):(
                <p className="text-center opacity-4 font-12">{t("No review requests")}</p>
              )}
            </div>
          )}
        </div>
        
        {/* task comments */}
        <div className="row mt-25">
          <h4 className="font-weight-600">
            {t("Comments")}
          </h4>
          
          {(!loadingComments && limit < 1000 && taskCommentsSize > limit) && 
            <div className="field text-center">
              <button className="btn btn-small btn-primary-outline color-black-always" onClick={() => {setLimit(10000);}}>
                {loadingComments ? (
                  <i className="fas fa-spinner fa-spin mr-5"></i>
                ):(
                  <i className="fas fa-plus mr-5"></i>
                )}
                {t('Load all comments')}
              </button>
            </div>
          }

          <div className="field">
            {taskCommentsLoaded ? (
              <>
                {task_comments_list}
                <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
              </>
            ):(
              <React.Fragment>
                <Skeleton className="skeleton-white mb-15" style={{width: "100%", height: "50px"}}/>
                <Skeleton className="skeleton-white mb-15" style={{width: "100%", height: "50px"}}/>
                <Skeleton className="skeleton-white mb-15" style={{width: "100%", height: "50px"}}/>
              </React.Fragment>
            )}
          </div>
          
          <div className="field">
            <Form task={task} refetchData={fetchTaskComments} currentUser={currentUser} setLimit={setLimit} projectAuthorizations={projectAuthorizations} project_id={project_id} users={usersStrings}  taskComments={taskComments} setTaskComments={setTaskComments}/>
          </div>
        </div>
      </React.Fragment>
    ):(
      <div className="text-center large-padding">
        <i className="fas fa-spinner fa-spin color-black-always font-21"></i>
      </div>
    )
  )
}

export default FullCard
