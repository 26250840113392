import React, {useState, useEffect } from "react"
import { useRouteMatch } from "react-router-dom";
import axios from "axios"
import ImportSubscriptionItem from "./ImportSubscriptionItem.js";
import animationData from "../../Shared/Lotties/lottie-empty.json";
import loadingData from "../../Shared/Lotties/lottie-loading.json";
import { Player } from "@lottiefiles/react-lottie-player";

const ImportSubscriptions = ({refetchData, project_id}) => {
  const match = useRouteMatch()
  const [loaded, setLoaded] = useState(false)
  const [subscriptions, setSubscriptions] = useState([]);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    fetchStripeSubscriptions();
  },[])

  const fetchStripeSubscriptions = () => {
    axios.get(`/api/o/${match.params.organization_id}/fetch_subscriptions.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setSubscriptions(response.data.subscriptions);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const subscriptions_list = subscriptions.map(sub => {
    return(
      <ImportSubscriptionItem subscriptions={subscriptions} setSubscriptions={setSubscriptions} sub={sub} refetchData={refetchData} project_id={project_id}/>
    )
  });

  return (
    <React.Fragment>
      <div className="border-radius">
        {(loaded && subscriptions.length > 0) && 
          <div className="row">
            <div className="col-5 opacity-4 font-12 color-black-always">
              Plan
            </div>

            <div className="col-3 opacity-4 font-12 color-black-always">
              Client
            </div>

            <div className="col-2 opacity-4 font-12 color-black-always">
              
            </div>

            <div className="col-2">
              
            </div>
          </div>
        }

        {
          loaded ? (
            subscriptions.length > 0 ? (
              subscriptions_list
            ):(
              <>
                <p className="font-12 large-padding opacity-4 text-center">
                  <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={animationData} />
                  You have no active subscriptions that matches the services you've imported.
                </p>
              </>
            )
          ):(
            <div className="text-center color-black-always padding-top-30">
              <Player style={{width: "100px", margin: "auto"}} mode="normal" autoplay loop src={loadingData} />
              <p className="font-12 mt-15 opacity-6">This can take a minute</p>
            </div>
          )
        }
      </div>
    </React.Fragment>
  )
}

export default ImportSubscriptions