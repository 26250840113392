import React , { useState, useEffect, useContext } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import axios from "axios"; 
import Signin from "../CodeAuth/Signin.js";
import { AppContext } from "../Shared/AppContext.js";
import { UserContext } from "../Shared/UserContext.js";

const Show = ({}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [invite, setInvite] = useState()
  const currentUser = useContext(UserContext);
  const {checkLogin} = useContext(AppContext);
  const [organizationUser, setOrganizationUser] = useState(null)

  useEffect(() => {
    fetchInvite();
  }, []);

  const fetchInvite = () => {
    axios.get(`/api/o/${match.params.organization_id}/invites/${match.params.invite_id}.json`)
    .then(function(response){
      setInvite (response.data.invite);
      setOrganizationUser(response.data.organization_user)
      setLoaded(true);
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true)
    axios.post(`/api/o/${match.params.organization_id}/organization_users`,{
      access_level: invite?.access_level,
      role_id: invite?.role?.token, 
      invite_id: invite?.token
    })
    .then(function(response){
      if(response.data.success){
        if(response.data.success){
          window.location.href = `/o/${response.data.organization.token}/dashboard`;
        } else {
          notice(response.data.error)
        }
      } else {
        notice("An error occured. Please contact support using the live chat button.")
        setDisabled(false)
      }
    })
  }

  return(
    loaded && 
    <React.Fragment>
      {!currentUser ? (
        <Signin refetchData={checkLogin}/>
      ):(
        <React.Fragment>
          <div class="container col-md-6 col-md-offset-2 login-main">
            <div class="login-box no-padding">
              <div class="background-3 border-radius login-box-info border-all animated fadeInUp">
                <form className="text-center" onSubmit={handleSubmit}>
                  <h3 className="font-weight-600 color-1">Join workspace</h3>
                  <p className="color-1 opacity-7">You'll be able to collaborate with the team, view projects, and more.</p>
                  {organizationUser ? (
                    <>
                      <button href={`/`} className="btn btn-primary width-100-percent mt-25">Go to workspace</button>
                    </>
                  ):(
                    <button disabled={disabled} className="btn btn-primary width-100-percent mt-25" type="submit">Join workspace</button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Show;