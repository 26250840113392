import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const Checklist = () => {
  const match = useRouteMatch();
  const [percentage, setPercentage] = useState(100)
  const [loaded, setLoaded] = useState(false)
  const [checklist, setChecklist] = useState([]);
  const [showChecklist, setShowChecklist] = useState(false);
  // const [hideChecklist, setHideChecklist] = useState()

  useEffect(() => {
    // Check if the flag exists in localStorage
    const hideChecklistWidget = localStorage.getItem('hideChecklistWidget');
    if (hideChecklistWidget === 'true') {
      // If the flag is set to true, hide the checklist widget
      setLoaded(false);
    } else {
      // Otherwise, fetch the checklist data and show the widget
      fetchChecklist();
    }
  }, []);

  useEffect(() => {
    setPercentage(calculatePercentage());
  }, [checklist]);

  const fetchChecklist = () => {
    axios.get(`/api/o/${match.params.organization_id}/checklist.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        if(response.data.allow){
          setChecklist(response.data.checklist)
          setLoaded(true);
        }
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const calculatePercentage = () => {
    const completedItems = checklist.filter(item => item.boolean === true).length;
    return (completedItems / checklist.length) * 100;
  };

  // Loop through all the milestones 
  const checklist_list = checklist.map(item => {
    return (
      <Link to={item.link} className="dashboard-checklist-item col-12 dark-color-black opacity-6 opacity-10-hover animate color-1 background-hover">
        <i class={`fas fa-check-circle dashboard-checklist-icon ${item.boolean ? "color-green opacity-1 check-finished" : "opacity-4"}`} style={{marginRight: "8px"}}></i> 
        <span className={`dashboard-checklist-message ${item.boolean && "line-through"}`}>{item.title}</span>
      </Link>
    );
  });

  const handleGiveUp = () => {
    // Save a flag to localStorage to indicate the user gave up
    localStorage.setItem('hideChecklistWidget', 'true');
    setLoaded(false);
  };

  return(
    loaded && 
    percentage != 100 &&
    <React.Fragment>
      {showChecklist ? (
        <div className="checklist-box box-shadow border-all background-3 animated fadeInUp" style={{padding: "0"}}>
          <div className="row default-padding border-bottom-white">
            <div className="checklist-box-close">
              <label className="font-weight-600">Account setup<i onClick={fetchChecklist} class="fas fa-sync opacity-6 opacity-10-hover ml-5"></i></label>
              <div>
                <i onClick={() => setShowChecklist(false)} className="fas fa-angle-down ml-8 opacity-4 opacity-10-hover cursor-pointer checklist-box-close-icon"></i>
                <i onClick={() => handleGiveUp()} className="fas fa-times ml-8 opacity-4 opacity-10-hover cursor-pointer checklist-box-close-icon"></i>
              </div>
            </div>
            <a href="https://guides.usequeue.com" target="blank" className="checklist-guide-link">
              <i class="far fa-video mr-5"></i>Video guide
            </a>
            
            <div className="col-11 no-padding">
              <div class="progress" style={{marginTop: "8px", borderRadius: "500px", height: "7px", marginBottom: "8px"}}>
                <div class="progress-bar" role="progressbar" aria-valuenow={percentage}
                aria-valuemin="0" aria-valuemax="100" style={{width: `${percentage}%`}}>
                  {/* <span class="sr-only">{percentage}% Complete</span> */}
                </div>
              </div>
            </div>

            {/* <div className="col-1 checklist-percentage">
              {percentage}%
            </div> */}
          </div>

          <div className="row">
            {checklist_list}
          </div>
        </div>
      ):(
        <div className="checklist-box box-shadow border-all background-3 animated fadeInDown cursor-pointer" style={{padding: "0"}} onClick={() => setShowChecklist(true)}>
          <div className="row default-padding">
            <div className="checklist-box-close">
              <label className="font-weight-600">Account setup</label>
              {showChecklist ? (
                <i onClick={() => setShowChecklist(false)} className="fas fa-window-minimize opacity-4 opacity-10-hover cursor-pointer checklist-box-close-icon"></i>
              ):(
                <i onClick={() => setShowChecklist(true)} className="fas fa-angle-up opacity-4 opacity-10-hover cursor-pointer checklist-box-close-icon"></i>
              )}
            </div>
            
            <div className="col-11 no-padding">
              <div class="progress" style={{marginTop: "8px", borderRadius: "500px", height: "7px", marginBottom: "8px"}}>
                <div class="progress-bar" role="progressbar" aria-valuenow={percentage}
                aria-valuemin="0" aria-valuemax="100" style={{width: `${percentage}%`}}>
                  <span class="sr-only">{percentage}% Complete</span>
                </div>
              </div>
            </div>

            <div className="col-12">
              <a href="https://guides.usequeue.com" target="blank" className="checklist-guide-link">
                <i class="far fa-video mr-5"></i>Video guide
              </a>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Checklist 