import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import { usePopper } from 'react-popper';

const CompanyProperty = ({company_property, company_id, refetchCompany}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(company_property.value);
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [labelValue, setLabelValue] = useState(company_property.title);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-start',
  });
  const popperRef = useRef(null);
  const labelRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target) &&
          labelRef.current && !labelRef.current.contains(event.target)) {
        setShowPopper(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  
  const handleLabelClick = () => {
    setShowPopper(!showPopper);
  };

  const handleLabelChange = (e) => {
    setLabelValue(e.target.value);
  };

  const handleLabelUpdate = () => {
    // API call to update the label
    axios.put(`/api/o/${match.params.organization_id}/companies/${company_id}/company_properties/${company_property.token}`, { title: labelValue })
      .then(response => {
        if(response.data.success) {
          setShowPopper(false);
        } else {
          // Handle error
        }
      })
      .catch(error => {
        console.error(error);
        // Handle error
      });
  };

  const handleDelete = () => {
    // API call to delete the property
    axios.delete(`/api/o/${match.params.organization_id}/companies/${company_id}/company_properties/${company_property.token}`)
      .then(response => {
        if(response.data.success) {
          // Handle successful deletion (e.g., remove from parent component)
          // setCompanyProperties(companyProperties.filter(cp => cp.token !== company_property.token));
          refetchCompany(company_id);
        } else {
          // Handle error
        }
      })
      .catch(error => {
        console.error(error);
        // Handle error
      });
  };

  const debouncedSubmit = useCallback(
    _.debounce((newValue) => {
      axios.put(`/api/o/${match.params.organization_id}/companies/${company_id}/company_properties/${company_property.token}`, { value: newValue })
        .then(function(response){
          if(response.data.success){
            // Handle success
          } else {
            response.data.errors.forEach((error) => {
              notice(error);
            });
          }
        })
        .catch(function(error){
          console.log(error)
          notice("An error occurred");
          reportError(`File: CompanyProperty.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
        });
    }, 500),
    []
  );

  const handleChange = (evt) => {
    const newValue = evt.target.value;
    setValue(newValue);
    debouncedSubmit(newValue);
  };

  return(
    <React.Fragment>
      <div className="field">
        <label className="popper-label" ref={(el) => { setReferenceElement(el); labelRef.current = el; }} onClick={handleLabelClick}>
          {labelValue}
        </label>
        {showPopper && (
          <div className="popper" ref={(el) => { setPopperElement(el); popperRef.current = el; }} style={styles.popper} {...attributes.popper}>
            <div class="input-group popper-input-group">
              <input className="form-control popper-input" type="text" value={labelValue} onChange={handleLabelChange}/>
              <span class="input-group-btn">
                <a class="btn color-blue popper-button" type="button" onClick={handleLabelUpdate} style={{borderRight: "1px solid #dae1e9", borderLeft: "1px solid #dae1e9"}}><i className="fa fa-check"></i></a>
                <a class="btn color-red popper-button" type="button" onClick={handleDelete}><i className="fa fa-times"></i></a>
              </span>
            </div>
          </div>
        )}

        {company_property.kind === "email" && 
          <input 
            className="form-control" 
            type="email" 
            value={value} 
            placeholder={company_property.title} 
            onChange={handleChange}
          />
        }

        {company_property.kind === "notes" && 
          <textarea 
            className="form-control" 
            value={value} 
            placeholder={company_property.title} 
            onChange={handleChange} 
            rows="5"
          />
        }

        {company_property.kind === "text" && 
          <input 
            className="form-control" 
            type="text" 
            value={value} 
            placeholder={company_property.title} 
            onChange={handleChange}
          />
        }

        {company_property.kind === "number" && 
          <input 
            className="form-control" 
            type="number" 
            value={value} 
            placeholder={company_property.title} 
            onChange={handleChange}
          />
        }

        {company_property.kind === "date" && 
          <input 
            className="form-control" 
            type="date" 
            value={value} 
            placeholder={company_property.title} 
            onChange={handleChange}
          />
        }

        {company_property.kind === "url" && 
          <input 
            className="form-control" 
            type="url" 
            value={value} 
            placeholder={company_property.title} 
            onChange={handleChange}
          />
        }

        {company_property.kind === "phone_number" && 
          <input 
            className="form-control" 
            type="tel" 
            value={value} 
            placeholder={company_property.title} 
            onChange={handleChange}
          />
        }

        {company_property.kind === "checkbox" && 
          <input 
            className="form-check-input" 
            type="checkbox" 
            checked={value === "true"} 
            onChange={(e) => handleChange({ target: { value: e.target.checked.toString() } })}
          />
        }

        {company_property.kind === "address" && 
          <textarea 
            className="form-control" 
            value={value} 
            placeholder={company_property.title} 
            onChange={handleChange} 
            rows="3"
          />
        }
      </div>
    </React.Fragment>
  )
}

export default CompanyProperty;