import React , { useState } from 'react';
import axios from "axios"; 

const Feedback = ({organization_id, updateSubscription}) => {
  const [disabled, setDisabled] = useState(false);
  const [unsubscribeReason, setUnsubscribeReason] = useState("");
  const [unsubscribeFeedback, setUnsubscribeFeedback] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${organization_id}`, {
      unsubscribe_reason: unsubscribeReason,
      unsubscribe_feedback: unsubscribeFeedback
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        updateSubscription();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="mb-15">
          <h4>Help us improve and then unsubscribe</h4>
        </div>
        
        <div className="field">
          <label>What's the main reason you're unsubscribing?</label>
          <select className="form-control" onChange={(e) => setUnsubscribeReason(e.target.value)}>
            <option value="">Select a reason</option>
            <option value="Bugs or performance problems">Bugs or performance problems</option>
            <option value="Missing features I need">Missing features</option>
            <option value="Usability issues">Usability issues</option>
            <option value="I have no clients">No clients</option>
          </select>
        </div>
        
        <div className="field">
          <label>How can we improve?</label>
          <textarea 
            className="form-control" 
            rows="5" 
            required
            onChange={(e) => setUnsubscribeFeedback(e.target.value)}
          ></textarea>
        </div>

        <div className="field text-right mt-25 animated fadeIn">
          <button 
            type="submit" 
            disabled={disabled || !unsubscribeReason || !unsubscribeFeedback || unsubscribeFeedback.length < 10} 
            className="btn btn-danger"
          >Continue to unsubscribe</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Feedback;