import React from 'react';

const Table = ({ datas }) => {
  if (datas.length === 0) {
    return null;
  }

  return (
    <table className="table react-table-table border-all border-radius background-3">
      <thead>
        <tr>
          {Object.keys(datas[0]).map(key => (
            <th className="react-table-th" scope="col" key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {datas.map((data, index) => (
          <tr className="background-3 border-bottom background-hover" key={index}>
            {Object.values(data).map((value, idx) => (
              <td key={idx}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;