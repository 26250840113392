import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Form from "./Form.js"
import Share from "./Share.js"
import ReactTooltip from 'react-tooltip';
import Design from "../../Shared/Images/98-Download-Upload-2.png";

const Index = ({setOpen, task}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [mode, setMode] = useState("index");
  const [requestFiles, setRequestFiles] = useState([]);
  const [requestFile, setRequestFile] = useState(null)

  useEffect(() => {
    fetchRequestFiles();
  }, []);

  const fetchRequestFiles = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/request_files.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setRequestFiles(response.data.request_files)
        setLoaded(true)
      } else {
        notice(data.response.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleDelete = (request_file_id) => {
    setDisabled(true)

    axios.delete(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/request_files/${request_file_id}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Request file deleted");
        fetchRequestFiles();
      } else {
        notice("An error occured when trying to delete the file.")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false)
    });
  }

  const request_files_list = requestFiles.map(request_file => {
    return(
      <tr className="hover-parent-opacity">
        <td>{request_file.title}</td>
        {request_file.folder == null ? <td>Main folder</td> : <td>{request_file.folder.title}</td>}
        
        <td className="truncate" style={{maxWidth: "150px"}}>{request_file.task?.title}</td>
        <td><Link to={request_file.project_link}>{request_file.replays.length}</Link></td>
        <td>{request_file.deadline}</td>
        <td className="text-right">
          <a className="color-1 mr-15" onClick={() => {setRequestFile(request_file); setMode("share")}}><i className="far fa-paper-plane"></i></a>
          <a className="color-1" href={request_file.link} target="_blank"><i className="far fa-link"></i></a>
        </td>
        <td><i className="fas fa-times color-red hover-child-opacity animate cursor-pointer" onClick={() => handleDelete(request_file.token)}></i></td>
      </tr>
    )
  })

  return(
    loaded && 
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className="padding-top-30">
        {
          (mode == "index") && (
            <div className="row">
              <div className="col-12 text-right">
                <a className="btn btn-primary btn-small" onClick={() => setMode("form")}>
                  <i className="fas fa-plus mr-8"></i>Create request
                </a>
              </div>

              <div className="col-12">
                <table class="table">
                  {requestFiles.length > 0 &&
                    <thead>
                      <tr>
                        <th scope="col">Title</th>
                        <th scope="col">Folder</th>
                        <th scope="col">Task</th>
                        <th scope="col">Uploads</th>
                        <th scope="col">Deadline</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                  }
                  <tbody>
                    {requestFiles.length > 0 ? request_files_list : 
                    <div className="display-center-all display-column height-50vh">
                      <div className="opacity-6">Request files from your client</div>
                    </div>}
                  </tbody>
                </table>
              </div>
            </div>
          )
        }

        {mode == "form" && 
          <div className="row">
            <div className="col-12" style={{marginBottom: "15px"}}>
              <a className="color-1 opacity-6 opacity-10-hover" onClick={() => setMode("index")}><i class="far fa-angle-left mr-8"></i>Back</a>
            </div>
            <Form refetchData={fetchRequestFiles} setMode={setMode} task={task}/>
          </div>
        }

        {mode == "show" && 
          <Show requestFile={requestFile} />
        }

        {mode == "share" && 
          <>
            <div className="col-12" style={{marginBottom: "15px"}}>
              <a className="color-1 opacity-6 opacity-10-hover" onClick={() => setMode("index")}><i class="far fa-angle-left mr-8"></i>Back</a>
            </div>
            <Share requestFile={requestFile} setOpen={setOpen}/>
          </>
        }
      </div>
    </React.Fragment>
  )
}

export default Index