import React , { useState, useEffect } from 'react';
import Skeleton from "react-loading-skeleton"


const ChatroomSkeleton = (props) => {

  const ChatSkeleton = () => {
    return (
        <div className="default-padding">
          <div className="display-row aic">
            <div className="display-row aic chat-skeleton-avatar" style={{width: "100%"}}>
              <div className="chat-skeleton-body">
                <Skeleton />  
              </div>
            </div>
          </div>
        </div> 
        )
      }  

  return(
    <div style={{width: "260px"}}>
     <ChatSkeleton />
     <ChatSkeleton />
     <ChatSkeleton />
     <ChatSkeleton />
     <ChatSkeleton />
     
    </div>
  )
}

export default ChatroomSkeleton;