import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import EmbeddableItem from "./EmbeddableItem.js";

const Embeddables = ({closeModal, refetchData}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [embeddables, setEmbeddables] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchEmbeddables();
  }, []);

  const fetchEmbeddables = () => {
    axios.get(`/api/embeddables.json?released=true`)
    .then(function(response){
      console.log(response);
      setEmbeddables(response.data.embeddables);
    })
  }

  const filteredEmbeddables = embeddables.filter(embeddable => 
    embeddable.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    embeddable.description.toLowerCase().includes(searchTerm.toLowerCase())
  );
  

  return(
    <React.Fragment>
      <div className="mt-30">
        <div className="display-flex align-items-center justify-content-between space-between mt-15">
          <label>
            Embeddables
            <a className="btn btn-small background-hover color-1 opacity-6" href="https://guides.usequeue.com/project-collaboration/embedding#how-to-embed-a-3rd-party-website" target="_blank">
              <i className="fal fa-question-circle" style={{cursor: "pointer"}}></i>
            </a>
          </label>
          <input 
            className="form-control-small" 
            placeholder="Search embeddables" 
            style={{width: "300px"}}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="row ml-15-negative">
          {filteredEmbeddables.map((embeddable) => (
            <EmbeddableItem key={embeddable.id} embeddable={embeddable} closeModal={() => {closeModal()}} refetchData={refetchData}/>
          ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Embeddables;