import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const Share = ({requestFile, setOpen}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/request_files/${requestFile.token}/share`, {
      email: document.getElementById("request_file_email").value, 
      description: document.getElementById("request_file_email_description").value
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOpen(false)
        notice("Email sent. We'll automatically remind them to submit before deadline.")
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <div className="modal-title">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>To</label>
            <input className="form-control form-control-white font-weight-400" placeholder="Email" type="email" id="request_file_email" />
          </div>

          <div className="field">
            <textarea id="request_file_email_description" className="form-control form-control-white font-weight-400" rows="4">Please submit all requested files before the deadline.</textarea>
          </div>

          <div className="field text-right">
            <button className="btn btn-primary" type="submit" disabled={disabled}>Send email</button>
          </div>
        </form>
      </div>


    </React.Fragment>
  )
}

export default Share