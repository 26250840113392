import React , { useState, useEffect } from 'react';
import axios from "axios"; 
import ReactTooltip from 'react-tooltip';

const SettingsTab = ({refetchData, onCloseModal, autoClose, replay}) => {
  const [disabled, setDisabled] = useState(false);
  const [passwordProtect, setPasswordProtect] = useState(replay.password != null ? true : false);
  const [deadline, setDeadline] = useState(replay.expiration_date != null ? true : false);
  const [password, setPassword] = useState(replay.password != null ? replay.password : "");
  const [confirmPassword, setConfirmPassword] = useState(replay.password != null ? replay.password : "");
  const [locked, setLocked] = useState(replay.locked == true)
  const [disableApproval, setDisableApproval] = useState(replay.disable_approval == true);
  const [disableComments, setDisableComments] = useState(replay.disable_comments == true);
  const [disableDownload, setDisableDownload] = useState(replay.disable_download == true);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setDisabled(true);    
    try {
      const response = await axios.put(`/api/replays/${replay.token}`, {
        locked: locked, 
        expiration_date:  (((deadline == true) && formData("settings").deadline.length > 1) ? formData("settings").deadline : null), 
        password: (((passwordProtect == true) && confirmPassword.length > 1) ? confirmPassword : null),
        disable_comments: disableComments, 
        disable_approval: disableApproval, 
        disable_download: disableDownload
      })
      console.log(response);

      if(response.data.success){

        if (window.location.href.includes("replays")) {
          refetchData()
        } else {
          await fetchProjectReplaysAsync() ;
        }

        notice("Succesfully updated.");
        autoClose == true && onCloseModal();
        setDisabled(false);
      }


    } catch(error) {
      console.log(error)
      notice("An error occured")
    }
  }

  useEffect(() => {
    checkDate();
  }, [])

  const checkDate = () => {
    if (replay.expiration_date != null) {
      var date = new Date(replay.expiration_date);
      var isoDate = date.toISOString().substr(0, 10);
      document.getElementById("deadline").value = isoDate
    } else {
      setDeadline(false);
    }
  }

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <form id="settings" onSubmit={handleSubmit} className="row no-margin color-black-always">
        <div className="field">
          <label>File settings</label>
          {/* deadline */}
          <div className="border-bottom" style={{margin: "10px 0px"}}>
            <div className="display-row justify-content-between mb-10">
              <div className="font-13">Commenting deadline</div>
              <input className="apple-switch color-white-5" style={{width: "40px", height: "20px", margin: "0px"}} type="checkbox" checked={deadline} onChange={(e) => setDeadline(e.target.checked)} />
            </div>
            {deadline &&
              <input id="deadline" type="date" name="deadline" className="form-control  mt-10 mb-10"/>
            }
          </div>
          
          {/* password */}
          <div className="border-bottom" style={{margin: "10px 0px"}}>
            <div className="display-row justify-content-between mb-10">
              <div className="font-13">Password protect</div>
              <input className="apple-switch color-white-5" style={{width: "40px", height: "20px", margin: "0px"}} type="checkbox" checked={passwordProtect} onChange={(e) => setPasswordProtect(e.target.checked)} />
            </div>
            {passwordProtect &&
              <div className="mt-10">
                <input id="password" type="password" onChange={(e) => setPassword(e.target.value)} value={password} name="password" placeholder="Enter your password..." className="form-control  field"/>

                <input id="password" type="password" name="password" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} placeholder="Confirm password" className="form-control  field"/>
                {(confirmPassword != "") &&
                  <React.Fragment>
                    {(confirmPassword !== password) && <div className="font-11 color-red mb-10" style={{marginTop: "-20px"}}>Passwords don't match.</div>}
                  </React.Fragment>
                }
              </div>
            }
          </div>
          
          {/* disable_download */}
          <div className="border-bottom mt-10 mb-10">
            <div className="display-row justify-content-between mb-10">
              <div className="display-row">
                <div className="mr-8 font-13">Disable download or viewing source</div>
                <div data-tip="Disable anyone outside of your workspace members from downloading or viewing the source file." style={{width: "30px"}}>
                  <i class="fal fa-info-circle color-1-hover animate"></i>
                </div>
              </div>
              
              <input className="apple-switch color-white-5" style={{width: "40px", height: "20px", margin: "0px"}} type="checkbox" checked={disableDownload} onChange={(e) => setDisableDownload(e.target.checked)} />
            </div>
          </div>

          {/* disable_access */}
          <div className="border-bottom mt-10 mb-10">
            <div className="display-row justify-content-between mb-10">
              <div className="display-row">
                <div className="mr-8 font-13">Disable Access</div>
              </div>
              
              
              <input className="apple-switch color-white-5" style={{width: "40px", height: "20px", margin: "0px"}} type="checkbox" checked={locked} onChange={(e) => setLocked(e.target.checked)} />
            </div>
          </div>
        </div>
        
        <div className="field">
          <label>Reviewer permissions</label>
          
          {/* disable_comments */}
          <div className="border-bottom mt-10 mb-10">
            <div className="display-row justify-content-between mb-10">
              <div className="display-row">
                <div className="mr-8 font-13">Disable comments <span className="font-10 opacity-6">Anyone added to approvals list will be able to comment</span></div>
              </div>
              
              <input className="apple-switch color-white-5" style={{width: "40px", height: "20px", margin: "0px"}} type="checkbox" checked={disableComments} onChange={(e) => setDisableComments(e.target.checked)} />
            </div>
          </div>
          
          {/* disable_approvals */}
          <div className="border-bottom mt-10 mb-10">
            <div className="display-row justify-content-between mb-10">
              <div className="display-row">
                <div className="mr-8 font-13">Disable approvals</div>
                <div data-tip="Disable anyone outside of your workspace members from accessing the link." style={{width: "30px"}}>
                  <i class="fal fa-info-circle color-1-hover animate"></i>
                </div>
              </div>
              
              <input className="apple-switch color-white-5" style={{width: "40px", height: "20px", margin: "0px"}} type="checkbox" checked={disableApproval} onChange={(e) => setDisableApproval(e.target.checked)} />
            </div>
          </div>
        </div>
        <div className="field flex" style={{justifyContent: "end"}}>
          <button type="submit" className="btn btn-small btn-primary" disabled={disabled}>{disabled ? "Updating..." : "Update"}</button>
        </div>
      </form> 
     
    </React.Fragment>
  )
}

export default SettingsTab