import React , { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import isURL from 'validator/es/lib/isURL';
import ReactTooltip from 'react-tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';


const Form = ({closeModal, refetchData, task_id, project_id, parent_replay_id, redirect}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(true)
  const [version, setVersion] = useState("v1")
  const [websiteLink, setWebsiteLink] = useState("")
  const { t } = useTranslation();

  const checkBankDomain = () => {

    const bankDomains = [
      "jpmorganchase.com",
      "bankofamerica.com",
      "wellsfargo.com",
      "citibank.com",
      "hsbc.com",
      "barclays.com",
      "pnc.com",
      "capitalone.com",
      "tdbank.com",
      "53.com",
      "usbank.com",
      "suntrust.com",
      "bbt.com",
      "regions.com",
      "frostbank.com",
      "schwab.com",
      "americanexpress.com",
      "navyfederal.org",
      "ally.com",
      "bbvausa.com",
      "fnb-online.com",
      "firstcitizens.com",
      "bokfinancial.com",
      "cnb.com",
      "fnbo.com",
      "peoples.com",
      "zionsbank.com",
      "eastwestbank.com",
      "bankozarks.com",
      "umb.com",
      "comerica.com",
      "huntington.com",
      "mbfinancial.com",
      "key.com",
      "mtb.com",
      "rocklandtrust.com",
      "santanderbank.com",
      "valley.com",
      "hancockwhitney.com",
      "fidelitybankonline.com"
    ];
    
    const url = document.getElementById("website_link").value;
    const urlObj = new URL(url);
    const urlDomain = urlObj.hostname.replace("www.", "");
    return bankDomains.includes(urlDomain)

  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setDisabled(true);
  
    if (checkBankDomain() === false) {

      axios.post(`/replays`, {
        project_id: project_id || match.params.project_id,
        folder_id: match.params.folder_id,
        version: version,
        task_id: task_id, 
        replay: {
          website_link: websiteLink, 
          create_ancestry_id: parent_replay_id
        }
      })
      .then(function(response){
        console.log(response);
        if(response.data.success){
          refetchData();
          closeModal();
          if(redirect){
            // history.push(`/replays/${response.data.replay.token}`)
          }
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
    } else {
      alert("Banks aren't allowed.");
    }
  }
  

  const checkWebsite = (e) => {
    setWebsiteLink(e.target.value)
    if(isURL(e.target.value)){
      setError(null)
    } else {
      setError("That's not a valid url")
    }
  }

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <h4 className="color-black-always modal-title">{t("Website URL")}</h4>

      <form id="replayWebsiteForm" onSubmit={handleSubmit}> 
        <div className="field">
          <input defaultValue="https://" class="form-control" name="website_link" id="website_link" placeholder="https://ycombinator.com" onChange={(e) => checkWebsite(e)} value={websiteLink}/>
          <p class="font-12 color-red" id="error">{error}</p>
        </div>

        <div class="field text-right">
          <button disabled={disabled || error} name="button" type="submit" class="btn btn-primary">{disabled ? "Submitting..." : "Submit"}</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Form