import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import Select from 'react-select';
import { ProjectWrapperContext } from "../Projects/ProjectWrapperContext.js";
import Invite from "../Projects/ProjectUsers/Invite.js";
import ReactTooltip from "react-tooltip";

const AccountManagers = ({}) => {
  const {projectUsers, adminOrganizationUsers, fetchUsers} = useContext(ProjectWrapperContext);
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [accountManagers, setAccountManagers] = useState([]);
  const [openInvite, setOpenInvite] = useState(false);

  useEffect(() => {
    fetchAccountManagers();
  }, []);

  const fetchAccountManagers = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/account_managers.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setAccountManagers(response.data.account_managers);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleAccountManagerSelect = (selectedOptions, actionMeta) => {
    if (actionMeta.action === 'remove-value') {
      axios.delete(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/account_managers/${actionMeta.removedValue.value}`)
      .then(function(response){
        fetchAccountManagers();
        fetchUsers();
        setDisabled(false);
      })
      .catch(function(error){
        console.log(error);
        setDisabled(false);
      })
    } else if (actionMeta.action === 'select-option') {
      if (actionMeta.option.value === 'invite') {
        setOpenInvite(true);
        return;
      }
      axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/account_managers`, {user_id: actionMeta.option.value})
      .then(function(response){
        fetchAccountManagers();
        fetchUsers();
        setDisabled(false);
      })
      .catch(function(error){
        console.log(error);
        setDisabled(false);
      })
    }
  }

  const accountManagerOptions = [
    ...[...adminOrganizationUsers, ...projectUsers]
      .filter(user => !accountManagers.some(am => am.username === user.username))
      .map(user => ({
        value: user.user_id,
        label: `${user.username}`
      })),
    {
      value: 'invite',
      label: <span><i className="fal fa-user-plus mr-8"></i> Invite teammates</span>
    }
  ];

  return(
    loaded && 
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className="field">
        <label>Account Managers</label>
        <Select
          isMulti
          isDisabled={disabled}
          options={accountManagerOptions}
          value={accountManagers.map(accountManager => ({
            value: accountManager.token,
            label: accountManager.username
          }))}
          onChange={handleAccountManagerSelect}
          className="color-1"
          classNamePrefix="react-select"
          placeholder="Select..."
          isClearable={false}
        />
        <p className="font-11 opacity-4 mt-8">Automatically assign new requests to account managers</p>
      </div>

      <Modal open={openInvite} onClose={() => setOpenInvite(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Invite refetchData={() => {fetchUsers()}}/> 
      </Modal>
    </React.Fragment>
  )
}

export default AccountManagers;