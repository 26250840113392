import React, { Component } from "react";

class CommandList extends Component {
  state = {
    selectedIndex: 0
  };

  componentDidUpdate(oldProps) {
    if (this.props.items !== oldProps.items) {
      this.setState({
        selectedIndex: 0
      });
    }
  }

  onKeyDown({ event }) {
    if (event.key === "ArrowUp") {
      this.upHandler();
      return true;
    }

    if (event.key === "ArrowDown") {
      this.downHandler();
      return true;
    }

    if (event.key === "Enter") {
      this.enterHandler();
      return true;
    }

    return false;
  }

  upHandler() {
    this.setState({
      selectedIndex:
        (this.state.selectedIndex + this.props.items.length - 1) %
        this.props.items.length
    });
  }

  downHandler() {
    this.setState({
      selectedIndex: (this.state.selectedIndex + 1) % this.props.items.length
    });
  }

  enterHandler() {
    this.selectItem(this.state.selectedIndex);
  }

  selectItem(index) {
    const item = this.props.items[index];

    if (item) {
      this.props.command(item);
    }
  }

  render() {
    const { items } = this.props;
    return (
      <div className="tiptap-command-items background-3 box-shadow border-all">
        {items.map((item, index) => {
          return (
            <button
              className={`tiptap-command-item ${
                index === this.state.selectedIndex ? "background-active" : ""
              }`}
              key={index}
              onClick={() => this.selectItem(index)}
            >
              <img src={item.image_address} className="tiptap-command-item-image"/>
              <div className="ml-10 ">
                <div className="tiptap-command-item-title">{item.element || item.title}</div>
                <div className="tiptap-command-item-description">{item.description}</div>
              </div>
            </button>
          );
        })}
      </div>
    );
  }
}

export default CommandList;
