import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const TaskNotifications = ({ newTasks, setNewTasks }) => {
  const match = useRouteMatch();
  const { t } = useTranslation();

  const removeNotification = (index) => {
    setNewTasks(prev => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (newTasks.length > 0) {
      const lastTask = newTasks[newTasks.length - 1];
      if (!lastTask.timeoutId) {
        const timeoutId = setTimeout(() => {
          removeNotification(newTasks.length - 1);
        }, 2000);
        
        setNewTasks(prev => prev.map((task, i) => 
          i === prev.length - 1 ? { ...task, timeoutId } : task
        ));
      }
    }
  }, [newTasks]);
  
  return (
    <div className="new-universal-tasks-wrapper">
      {newTasks.map((task, index) => (
        <div
          key={task.id}
          className="new-universal-tasks-item animate fadeIn"
          onMouseEnter={() => clearTimeout(task.timeoutId)}
        >
          <div className="display-flex space-between align-items-center">
            <div>
              <div className="font-12 opacity-6 mb-5">{t("New request")}</div>
              <div className="font-weight-500 max-width-250px truncate">{task.title}</div>
              <div>
                
              </div>
            </div>

            <div className="display-flex align-items-center gap-8">
              <i className="fas fa-times cursor-pointer opacity-4 opacity-10-hover btn btn-small background-hover" onClick={() => removeNotification(index)} style={{marginTop: "-25px", marginRight: "-10px"}}/>
            </div>
          </div>

          <div className="ml-15-negative">
            <Link className="mt-8 btn btn-small opacity-7 opacity-10-hover color-primary" to={`/o/${match.params.organization_id}/projects/${task.project_id}/tasks?task_id=${task.task_id}`}>View task</Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TaskNotifications