import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Select from 'react-select';

const CompanyUsers = ({company}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
    // Initialize selectedUsers with existing company users
    if (company.company_users) {
      const initialSelectedUsers = company.company_users.map(user => ({
        value: user.id,
        label: user.username,
      }));
      setSelectedUsers(initialSelectedUsers);
    }
  }, [company.company_users]);

  const fetchUsers = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/users.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setUsers(response.data.client_users);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      // always executed
    });
  }

  const handleUserSelect = (selected) => {
    const previousUsers = selectedUsers;
    setSelectedUsers(selected);

    // Check for added users
    selected.forEach(user => {
      if (!previousUsers.find(prevUser => prevUser.value === user.value)) {
        handleUserAdded(user);
      }
    });

    // Check for removed users
    previousUsers.forEach(prevUser => {
      if (!selected.find(user => user.value === prevUser.value)) {
        handleUserRemoved(prevUser);
      }
    });
  }

  const handleUserAdded = (user) => {
    axios.post(`/api/o/${match.params.organization_id}/companies/${company.token}/company_users.json?user_id=${user.value}`)
    .then(function(response){
      console.log(response);
    })
    .catch(function(error){
      console.log(error);
    });
    // Add your logic for when a user is added
  }

  const handleUserRemoved = (user) => {
    console.log(`User removed: ${user.label} (ID: ${user.value})`);
    axios.delete(`/api/o/${match.params.organization_id}/companies/${company.token}/company_users/${user.value}.json`, {
      user_id: user.value,
      
    })
    .then(function(response){
      console.log(response);
    })
    .catch(function(error){
      console.log(error);
    });
    // Add your logic for when a user is removed
  }

  const userOptions = users.map((user) => ({
    value: user.id,
    label: user.username,
  }));

  return(
    <React.Fragment>
      <div className="field">
        <label>Clients</label>
        <Select
          isMulti
          options={userOptions}
          value={selectedUsers}
          onChange={handleUserSelect}
          className="color-1"
          classNamePrefix="react-select"
          placeholder="Select..."
        />
      </div>
    </React.Fragment>
  )
}

export default CompanyUsers;