import React from 'react';
import { useRouteMatch } from 'react-router-dom'
import "@lottiefiles/lottie-player";

const NotFound = (props) => {
  const match = useRouteMatch();
  // const [redirectTo, setRedirectTo] = useState("");

  // useEffect(() => {
  //   $.ajax({
  //     url: `/api/redirect.json`,
  //     type: 'GET',
  //     dataType: 'json',
  //     success: (response) => {
  //       // redirect(response.redirect_to);
  //       setRedirectTo(response.redirect_to)
  //     }
  //   });
  // }, []);

  return(
    <React.Fragment>
      <div className="not-found-wrapper">
        <div className="col-md-6 col-md-offset-3 col-lg-6 col-lg-offset-3 col-xs-12 large-padding">
          <div className="field medium-padding">
            <lottie-player autoplay loop mode="normal" src="https://assets7.lottiefiles.com/packages/lf20_ezfvsceg.json" style={{width: "300px", margin: "auto"}}></lottie-player>
          </div>

          <div className="field text-center">
            <p className="color-1">
              The page you're looking for doesn't exist or we f&@#%d up. <a className="color-blue showIntercom">Let us know</a> if it's our fault.
            </p>
          </div>

          <div className="field text-center">
            {/* <a className="btn btn-primary" href="/">Go home</a> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
};

export default NotFound