const getSuggestionItems = (query) => {
  // Convert query to string explicitly to handle cases where it might not be a string
  const queryString = String(query);

  return [
    {
      image_address: "https://queue-images.nyc3.cdn.digitaloceanspaces.com/h1.png",
      title: "H1",
      description: "Big text size",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 1 })
          .run();
      }
    },
    {
      image_address: "https://queue-images.nyc3.cdn.digitaloceanspaces.com/h2.png",
      title: "H2",
      description: "Medium text size",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setNode("heading", { level: 2 })
          .run();
      }
    },
    {
      image_address: "https://queue-images.nyc3.cdn.digitaloceanspaces.com/bullet-list.png", // Update with the correct image address
      title: "Bullets",
      description: "Add a bullet list",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .toggleBulletList()
          .run();
      }
    },
    {
      image_address: "https://queue-images.nyc3.cdn.digitaloceanspaces.com/numbered-list.png", // Update with the correct image address
      title: "Numbered List",
      description: "Add a numbered list",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .toggleOrderedList()
          .run();
      }
    },
    {
      image_address: "https://www.notion.so/images/blocks/code.a8b201f4.png",
      title: "Code",
      description: "Code",
      command: ({ editor, range }) => {
        editor.chain().focus().deleteRange(range).setMark("code").run();
      }
    },
    // {
    //   image_address: "https://www.notion.so/images/blocks/header.57a7576a.png",
    //   title: "Italics",
    //   description: "Italic text",
    //   command: ({ editor, range }) => {
    //     editor.chain().focus().deleteRange(range).setMark("italic").run();
    //   }
    // },
    // {
    //   image_address: "https://www.notion.so/images/blocks/header.57a7576a.png",
    //   title: "image",
    //   description: "Upload an image",
    //   command: ({ editor, range }) => {
    //     console.log("call some function from parent");
    //     editor.chain().focus().deleteRange(range).setNode("paragraph").run();
    //   }
    // },
    {
      image_address: "https://queue-images.nyc3.cdn.digitaloceanspaces.com/icons8-quote-48.png", // Update with the correct image address
      title: "Quote",
      description: "Add a blockquote",
      command: ({ editor, range }) => {
        editor
          .chain()
          .focus()
          .deleteRange(range)
          .setBlockquote()
          .run();
      }
    },
    // {
    //   image_address: "https://queue-images.nyc3.cdn.digitaloceanspaces.com/table.png", // Update with the correct image address
    //   title: "Table",
    //   description: "Add a table",
    //   command: ({ editor, range }) => {
    //     editor
    //       .chain()
    //       .focus()
    //       .deleteRange(range)
    //       .insertTable()
    //       .run();
    //   }
    // }
  ]
    .filter((item) => item.title)
    .slice(0, 10);
};

export default getSuggestionItems;
