import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import Company from "./Company.js";
import loadingData from "../Shared/Lotties/lottie-loading.json";
import { Player } from "@lottiefiles/react-lottie-player";

const Index = ({project_id, refetchData}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectCompanies, setProjectCompanies] = useState([]);
  const [company, setCompany] = useState(null);
  const [clientUserCompanies, setClientUserCompanies] = useState([]);
  const [companyProperties, setCompanyProperties] = useState([]);

  useEffect(() => {
    fetchProjectCompanies();
  }, [project_id ||match.params.project_id]);

  const fetchProjectCompanies = () => {
    setCompany(null);
    axios.get(`/api/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}/project_companies.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setProjectCompanies(response.data.project_companies);
        setClientUserCompanies(response.data.client_user_companies);
        if(response.data.project_companies.length > 0){
          fetchCompany(response.data.project_companies[0].company.token);
        }

        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const fetchCompany = (token) => {
    axios.get(`/api/o/${match.params.organization_id}/companies/${token}.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setCompany(response.data.company);
        setCompanyProperties(response.data.company.company_properties);
        if (typeof refetchData === 'function') {
          refetchData();
        }
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
    })
    .then(function () {
      // always executed
    });
  } 

  const handleSubmit = () => {
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/projects/${project_id ||match.params.project_id}/project_companies.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        fetchProjectCompanies();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const createProjectCompany = (company_id) => {
    setDisabled(true);
    axios.post(`/api/o/${match.params.organization_id}/projects/${project_id ||match.params.project_id}/project_companies.json?company_id=${company_id}`)
    .then(function(response){
      // console.log(response);
      fetchProjectCompanies();
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const client_user_companies_list = clientUserCompanies.map((client_user_company) => {
    return(
      <>
        <a className="btn btn-small border-all color-1 background-hover" onClick={() => createProjectCompany(client_user_company.token)}>
          {client_user_company.title}
        </a>
      </>
    )
  });

  return(
    loaded ?(
      <React.Fragment>
        <div className="show-company-project-view-sidebar background-3 border-left box-shadow">
          <div className="row">
            {projectCompanies.length === 0 &&
              <>
                {clientUserCompanies.length > 0 ? (
                  <>
                    <div className="col-12 display-flex flex-direction-column align-items-center justify-content-center height-70vh">
                      <p className="opacity-6 font-12 text-center pl-20 pr-20 mb-15">We detected these companies associated to your client. Pick one to make this projects primary company.</p>
                      {client_user_companies_list}
                      {/* <a disabled={disabled} onClick={() => handleSubmit()} className="btn btn-small background-hover color-1 mt-20 opacity-4 opacity-10-hover"><i className="fa fa-building mr-5"></i>New company</a> */}
                    </div>
                  </>
                ):(
                  <div className="col-12 display-flex flex-direction-column align-items-center justify-content-center height-70vh">
                    <p className="opacity-6 font-12 text-center">Add your clients company to keep track of important info</p>
                    <a disabled={disabled} onClick={() => handleSubmit()} className="btn btn-small btn-primary mt-20"><i className="fa fa-building mr-5"></i> Add company</a>
                  </div>
                )}
              </>
            }

            {company != null && 
              <Company company={company} refetchCompany={fetchCompany} refetchData={fetchProjectCompanies} companyProperties={companyProperties} setCompanyProperties={setCompanyProperties}/>
            }
          </div>
        </div>
      </React.Fragment>
    ) : (
      <div className="show-company-project-view-sidebar background-3 border-left box-shadow display-flex justify-content-center align-items-center">
        {/* <Player style={{width: "100px", margin: "auto"}} mode="normal" autoplay loop src={loadingData} /> */}
      </div>
    )
  )
}

export default Index;