import React , { useState, useEffect, useCallback } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import CompanyProperty from "./CompanyProperty.js";
import CompanyUsers from "./CompanyUsers.js";
import { confirmAlert } from 'react-confirm-alert';
import AccountManagers from "./AccountManagers.js";
import { ProjectWrapperContext } from "../Projects/ProjectWrapperContext.js";

const Company = ({company, refetchCompany, refetchData, companyProperties, setCompanyProperties}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();

  const company_properties_list = companyProperties.map((company_property) => {
    return(
      <CompanyProperty setCompanyProperties={setCompanyProperties} companyProperties={companyProperties} company_property={company_property} company_id={company.token} refetchCompany={refetchCompany} />
    )
  }); 

  const createCompanyProperty = (kind, title) => {
    axios.post(`/api/o/${match.params.organization_id}/companies/${company.token}/company_properties.json`, {
      kind: kind,
      title: title
    })
    .then(function(response){
      console.log(response);
      refetchCompany(company.token);
    })
    .catch(function(error){
      console.log(error);
    });
  };

  const removeCompanyFromProject = () => {
    axios.delete(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/project_companies/${company.token}.json?company_id=${company.token}`)
    .then(function(response){
      // console.log(response);
      refetchCompany(company.token);
      refetchData();
    })
    .catch(function(error){
      console.log(error);
    });
  }

  const confirmRemoveCompanyFromProject = () => {
    confirmAlert({
      title: "Are you sure about that?",
      message: "Just double check, ya know?",
      buttons: [
        {
          label: 'No',
          className: 'btn background-3 btn-small',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Yes',
          className: 'btn btn-danger btn-small',
          onClick: () => removeCompanyFromProject()
        },
      ]
    });
  }

  const confirmDelete = () => {
    confirmAlert({
      title: "Are you sure about that?",
      message: "We can't undo this after deleting it. So think twice.",
      buttons: [
        {
          label: 'No',
          className: 'btn background-3 btn-small',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Yes',
          className: 'btn btn-danger btn-small',
          onClick: () => deleteCompany()
        },
      ]
    });
  }

  const deleteCompany = () => {
    axios.delete(`/api/o/${match.params.organization_id}/companies/${company.token}`)
    .then(function(response){
      refetchData();
    })
    .catch(function(error){
      console.log(error);
    });
  }

  return(
    <div className="animated fadeInUp">
      <div className="display-flex align-items-center space-between">
        <div>Company info</div>
        <div class="btn-group">
          <a type="button" class="btn btn-small color-1 background-hover dropdown-toggle" data-toggle="dropdown">
            <i class="far fa-ellipsis-v"></i>
          </a>
          <ul class="dropdown-menu animated fadeInUp pull-right" role="menu">
            <li><a onClick={() => confirmRemoveCompanyFromProject()}><i className="fal fa-unlink"></i>Remove from project</a></li>
            <li><a className='color-red-important' onClick={() => confirmDelete()}><i className="fal fa-trash-alt"></i>Delete company</a></li>
          </ul>
        </div>
      </div>

      <p className="opacity-6 mb-20 font-12 mt-0">Enter information about the client's company.</p>

      <AccountManagers />

      {company_properties_list}

      <div className="field">
        <div class="btn-group">
          <a class="color-1 btn btn-small background-hover dropdown-toggle" data-toggle="dropdown">
            <i className="fa fa-plus mr-8"></i>Add a property
          </a>

          <ul class="dropdown-menu animated fadeInUp" role="menu">
            <li><a onClick={() => createCompanyProperty("phone_number", "Phone number")}><i className="fal fa-phone"></i>Phone number</a></li>
            <li><a onClick={() => createCompanyProperty("email", "Email")}><i className="fal fa-envelope"></i>Email</a></li>
            <li><a onClick={() => createCompanyProperty("address", "Address")}><i className="fal fa-map-marker"></i>Address</a></li>
            <li><a onClick={() => createCompanyProperty("url", "URL")}><i className="fal fa-globe"></i>URL</a></li>
            <li><a onClick={() => createCompanyProperty("notes", "Notes")}><i className="fal fa-sticky-note"></i>Notes</a></li>
            <li><a onClick={() => createCompanyProperty("number", "Number")}><i className="fal fa-hashtag"></i>Number</a></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Company;