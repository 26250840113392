import React from 'react';
function Priority3() {
  return (
    <svg width="23" height="23" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="49" height="49" rx="7.5" fill="#DC3545" fill-opacity="0.368627"/>
      <path d="M17.4106 14L17.1763 31.25H14.4575L14.2231 14H17.4106ZM15.8169 38.1875C15.2388 38.1875 14.7427 37.9805 14.3286 37.5664C13.9146 37.1523 13.7075 36.6563 13.7075 36.0781C13.7075 35.5 13.9146 35.0039 14.3286 34.5898C14.7427 34.1758 15.2388 33.9687 15.8169 33.9687C16.395 33.9687 16.8911 34.1758 17.3052 34.5898C17.7192 35.0039 17.9263 35.5 17.9263 36.0781C17.9263 36.4609 17.8286 36.8125 17.6333 37.1328C17.4458 37.4531 17.1919 37.7109 16.8716 37.9062C16.5591 38.0938 16.2075 38.1875 15.8169 38.1875ZM26.5952 14L26.3608 31.25H23.6421L23.4077 14H26.5952ZM25.0015 38.1875C24.4233 38.1875 23.9272 37.9805 23.5132 37.5664C23.0991 37.1523 22.8921 36.6563 22.8921 36.0781C22.8921 35.5 23.0991 35.0039 23.5132 34.5898C23.9272 34.1758 24.4233 33.9687 25.0015 33.9687C25.5796 33.9687 26.0757 34.1758 26.4897 34.5898C26.9038 35.0039 27.1108 35.5 27.1108 36.0781C27.1108 36.4609 27.0132 36.8125 26.8179 37.1328C26.6304 37.4531 26.3765 37.7109 26.0562 37.9062C25.7437 38.0938 25.3921 38.1875 25.0015 38.1875ZM35.7798 14L35.5454 31.25H32.8267L32.5923 14H35.7798ZM34.186 38.1875C33.6079 38.1875 33.1118 37.9805 32.6978 37.5664C32.2837 37.1523 32.0767 36.6563 32.0767 36.0781C32.0767 35.5 32.2837 35.0039 32.6978 34.5898C33.1118 34.1758 33.6079 33.9687 34.186 33.9687C34.7642 33.9687 35.2603 34.1758 35.6743 34.5898C36.0884 35.0039 36.2954 35.5 36.2954 36.0781C36.2954 36.4609 36.1978 36.8125 36.0024 37.1328C35.8149 37.4531 35.561 37.7109 35.2407 37.9062C34.9282 38.0938 34.5767 38.1875 34.186 38.1875Z" fill="#FF0019"/>
    </svg>
  )
}

export default Priority3;