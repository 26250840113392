import React , { useState, useEffect, useContext, useCallback } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import { PickerOverlay } from 'filestack-react';

const Cover = ({task_id, refetchData, closeModal, cover_exists}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    axios.get(`/api/unsplash_search?query=futuristic abstract`)
      .then(function(response){
        setLoading(false);
        setResults(response.data.results);
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setLoading(false);
      });
  }, [task_id]); 

  const handleSearchChange = (event) => {
    event.preventDefault();
    setLoading(true);

    axios.get(`/api/unsplash_search?query=${searchTerm}`)
      .then(function(response){
        setLoading(false);
        setResults(response.data.results);
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setLoading(false);
      })
  };

  const results_list = results.map((result) => {
    const handleImageClick = () => {  
      console.log("image clicked");

      axios.post(`/api/portal/projects/${match.params.project_id}/tasks/${task_id}/update_cover`, {
        unsplash_data: result
      })
      .then(function(response){
        if(response.data.success){
          console.log(response.data.task);
          closeModal();
          refetchData();
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setLoading(false);
      })
    }

    return(
      <div className="col-4" style={{cursor: "pointer"}} >
        <div className="default-margin">
          <img className="border-radius width-100-percent unsplash-search-results-image animate grow" src={result.urls.small} alt={result?.description} onClick={handleImageClick}/>
          <a className="unsplash-search-results-image-description color-1 opacity-7 opacity-10-hover" href={result.links.html} target="_blank">
            <i className="far fa-link mr-5"></i>{result.user.name}
          </a>
        </div>
      </div>
    )
  });

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    const imageFiles = Object.values(filesUploaded).filter(file => file.mimetype.startsWith('image/'));
    if (imageFiles.length > 0) {
      const imageFile = imageFiles[0];
      console.log(imageFile);

      axios.post(`/api/portal/projects/${match.params.project_id}/tasks/${task_id}/update_cover`, {
        cover_url: imageFile.url
      })
      .then(function(response){
        if(response.data.success){
          console.log(response.data.task);
          closeModal();
          refetchData();
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setLoading(false);
      })
    } else {
      console.log('No image file uploaded');
    }
  };

  const deleteCover = () => {
    axios.post(`/api/portal/projects/${match.params.project_id}/tasks/${task_id}/update_cover`)
    .then(function(response){
      if(response.data.success){
        console.log(response.data.task);
        closeModal();
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setLoading(false);
    })
  }

  return(
    <React.Fragment>
      <div className="row">
        <div className="col-12 pb-30 pt-30">
          <form className="field" onSubmit={handleSearchChange}>
            <div class="input-group">
              <input className="form-control" placeholder="Search on Unsplash" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
              <span class="input-group-btn">
                <button class="btn btn-primary" type="submit">Search</button>
              </span>
            </div>
          </form>

          <div className="row" style={{marginLeft: "-15px", marginRight: "-15px"}}>
            {loading ? ( 
              <div className="col-12 large-padding text-center">
                <i className="fas fa-spinner fa-spin mr-8"></i>Loading images...
              </div>
            ):(
              results_list
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="pt-30 pb-30 display-flex align-items-center justify-content-center border-top">
            {cover_exists && 
              <a className="btn border-all color-red border-all-red mr-15 border-radius background-hover" onClick={deleteCover}>
                <i className="far fa-trash-alt mr-8"></i>Remove cover
              </a>
            }
            <a className="btn border-all color-1 border-radius background-hover" onClick={() => setOpen(true)}>
              <i className="far fa-upload mr-8"></i>Upload image
            </a>

            {open && 
              <PickerOverlay
                apikey={"AKVhxMbEQhkOIryqBvSEQz"}
                onSuccess={(res) => console.log(res)}
                onUploadDone={(res) => {
                  createReplay(res);
                }}
                clientOptions={{
                  sessionCache: true
                }}
                pickerOptions={{
                  onClose: () => {
                    setOpen(false);
                  }, 
                  fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
                  maxFiles: 1,
                  accept: 'image/*',
                  storeTo: {
                    location: 'gcs',
                    path: '/',
                    container: 'queue-filestack'
                  }
                }}
              />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Cover;