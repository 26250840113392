import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Share from "./Share.js";

const Form = ({setMode, refetchData, task}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [folderChoice, setFolderChoice] = useState(null)
  const [folders, setFolders] = useState([]);
  const [requestFile, setRequestFile] = useState(null)
  const [tasks, setTasks] = useState([]);
  const [taskChoice, setTaskChoice] = useState(task?.token || null);
  const [tasksLoaded, setTasksLoaded] = useState(false);

  useEffect(() => {
    fetchFolders();
    fetchTasks();
  }, []);

  const fetchFolders = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/folders.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setFolders(response.data.folders)
        setLoaded(true)
      } else {
        notice("response.data.error")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const fetchTasks = () => {
    axios.get(`/api/portal/projects/${match.params.project_id}/tasks.json?tasks_only=true`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setTasks(response.data.tasks)
        setTasksLoaded(true)
      } else {
        notice("response.data.error")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/request_files.json`, {
      title: document.getElementById('request_file_title').value, 
      description: document.getElementById('request_file_description').value, 
      deadline: document.getElementById('request_file_deadline').value,
      folder_id: folderChoice, 
      project_id: match.params.project_id, 
      task_id: taskChoice
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Created file request");
        setRequestFile(response.data.request_file);
        setMode("index")
        refetchData()
      } else {
        notice(response.data.success)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const folders_list = folders.map(folder => {
    return (
      <option value={folder.token}>{folder.title}</option>
    )
  });

  const tasks_list = tasks.map(task => {
    return (
      <option value={task.token}>{task.title}</option>
    )
  })

  return (
    loaded && 
    <React.Fragment>
      {requestFile == null ? (
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Title</label>
            <input id="request_file_title" className="form-control" placeholder="Explain what the request is for" required="true"/>
          </div>

          <div className="field">
            <label>Description</label>
            <textarea id="request_file_description" rows="5" className="form-control" placeholder="Add any extra details for the request" name="description"></textarea>
          </div>

          {task == null ?(
            <div className="field">
              <label for="cars">Task (optional)</label>
              <p className="font-12 opacity-6">Files will be uploaded to the task</p>

              <select name="folders" id="folders" value={taskChoice} onChange={e => setTaskChoice(e.target.value)}>
                <option value={null}>
                  {tasksLoaded ? (
                    "Choose task (optional)"
                  ):(
                    "Loading tasks...."
                  )}
                </option>
                {tasks_list}
              </select>
            </div>
          ):(
            <div className="field">
              <label for="cars">Task</label>
              <p className="font-12 opacity-6">Files will be uploaded to this task</p>

              <select disabled={true} name="folders" id="folders" value={taskChoice} onChange={e => setTaskChoice(e.target.value)}>
                <option value={null}>
                  {task.title}
                </option>
              </select>
            </div>
          )}

          <div className="field">
            <label for="cars">Folder (optional)</label>

            <select name="folders" id="folders" value={folderChoice} onChange={e => setFolderChoice(e.target.value)}>
              <option value={null}>Main folder</option>
              {folders_list}
            </select>
          </div>

          <div className="field">
            <label>Deadline</label>
            <input id="request_file_deadline" type="date" name="deadline" className="form-control" required="true"/>
          </div>

          <div className="field text-right">
            <button className="btn btn-primary" type="submit">Create request</button>
          </div>
        </form>
      ):(
        <Share request_file={requestFile}/>
      )}
    </React.Fragment>
  )
}

export default Form