import React , { useState, useEffect } from 'react';
import axios from "axios"; 

const LinkForm = ({project_id, closeModal, task_id, refetchData}) => {
  const [disabled, setDisabled] = useState(false);
  const [title, setTitle] = useState(""); 
  const [link, setLink] = useState("");

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setDisabled(true);
  
    try {
      const response = await axios.post('/replays', {
        project_id: project_id,
        task_id: task_id,
        replay: {
          title: title,
          link: link
        }
      });
      console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
        setDisabled(false);
      }
      
    } catch (error) {
      console.log(error);
      notice('An error occurred');
    } 
  };
  


  return(
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="field">
            <label className="color-black-always">Title</label>
            <input className="form-control" placeholder="" type="text" required="true" onChange={(e) => setTitle(e.target.value)}/>
          </div>
          
          <div className="field">
            <label className="color-black-always">Link</label>
            <input className="form-control" placeholder="http://" type="url" required="true" onChange={(e) => setLink(e.target.value)}/>
          </div>

          <div className="col-12 field text-right">
            <button disabled={disabled} className="btn btn-primary" type="submit">{disabled ? "Submitting..." : "Submit"}</button>
          </div>
        </div>
      </form>
    </React.Fragment>
  )
}

export default LinkForm