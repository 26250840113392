import React from 'react';

const EmbeddableView = ({embeddable, callToAction}) => {
  return(
    <React.Fragment>
      <div className="row pb-15 border-bottom mb-15">
        <div className="display-flex align-items-center space-between">
          <div className="display-flex align-items-center">
            <img src={embeddable.preview_image} style={{width: "40px", height: "40px", borderRadius: "8px", objectFit: "cover", marginRight: "15px"}}/>
            <div>
              <h4 className="mb-0">{embeddable.title}</h4>
              <p className="opacity-6">{embeddable.description}</p>
            </div>
          </div>

          <a className="btn btn-primary btn-small ml-auto" onClick={() => {callToAction()}}>
            <i className="fal fa-plus mr-8"></i>
            Add
          </a>
        </div>
      </div>
      
      {embeddable.video && 
        <div className="row mt-15 mb-15">
          <video src={embeddable.video} style={{width: "100%", borderRadius: "8px"}} controls/>
        </div>
      }

      <div className="row">
        <div dangerouslySetInnerHTML={{ __html: embeddable.body }} />
      </div>
    </React.Fragment>
  )
}

export default EmbeddableView;