import React , { useState } from 'react';
import {  useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';

const ProjectUser = ({user, refetchData}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);

  const handleRemoveUser = () => {
    axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/remove_project_user`, {email: user.email})
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleToggle = () => {
    setDisabled(true);
    if(user.account_manager){
      axios.delete(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/account_managers/${user.account_manager?.token}`)
      .then(function(response){
        refetchData();
        setDisabled(false);
      })
      } else {
      axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/account_managers`, {user_id: user.user_id})
      .then(function(response){
        refetchData();
        setDisabled(false);
      })
    }
   }

  return(
    <React.Fragment>
      <div className="display-flex justify-content-between align-items-center mb-15 hover-parent-opacity">
        <div className="display-flex align-items-center">
          <img src={user.avatar} className="avatar mr-8" />
          <div className="font-12 display-flex align-items-center">
            {user.username}
            <div className="ml-8 hover-child-opacity cursor-pointer animate" onClick={() => handleRemoveUser()}>
              <a className="opacity-4 opacity-10-hover color-1 btn btn-small background-hover">
                <i className="fas fa-times"></i>
              </a>
            </div>
          </div>
        </div>

        <div className="font-12 display-flex align-items-center">
          <span className="ml-8 opacity-4">
            Account manager <i className="fal fa-question-circle ml-8" data-tip="Account Managers will be automatically assigned to all new requests."></i>
          </span>
          <div style={{scale: "0.7"}}>
            <Toggle icons={false} checked={user.account_manager} onChange={handleToggle}/>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProjectUser;