import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Table from "../Admin/Table.js";

const ManyRequests = ({organization_id}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [projectUrl, setProjectUrl] = useState("");
  const [requestURL, setRequestURL] = useState("");
  const [requests, setRequests] = useState([]);
  const [teamURL, setTeamURL] = useState("");
  const [teammates, setTeammates] = useState([]);
  const [clientsURL, setClientsURL] = useState("");
  const [clients, setClients] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [commentsURL, setCommentsURL] = useState("");
  const [comments, setComments] = useState([]);
  

  const fetchRequests = () => {
    axios.get(requestURL)
    .then(function(response){
      console.log(response);
      setRequests(response.data.data);
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = () => {
    setDisabled(true);

    axios.post(`/api/o/${organization_id}/import_manyrequests_requests`, { requests: requests })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Imported successfully");
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const fetchTeammates = () => {
    axios.get(teamURL)
    .then(function(response){
      console.log(response);
      setTeammates(response.data.data);
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const createTeammates = () => {
    setDisabled(true);

    axios.post(`/api/o/${organization_id}/import_manyrequests_teammates`, { teammates: teammates })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Imported successfully");
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const fetchClients = () => {
    axios.get(clientsURL)
    .then(function(response){
      console.log(response);
      setClients(response.data.data);
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const createClients = () => {
    setDisabled(true);

    axios.post(`/api/o/${organization_id}/import_manyrequests_clients`, { clients: clients })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Imported successfully");
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const fetchComments = () => {
    axios.get(commentsURL)
    .then(function(response){
      console.log(response);
      setComments(response.data.data);
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const createComments = () => {
    setDisabled(true);

    axios.post(`/api/o/${organization_id}/import_manyrequests_comments`, { task_title: taskTitle, comments: comments })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Imported successfully");
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const addClientsToProjects = () => {
    setDisabled(true);

    axios.post(`/api/o/${organization_id}/import_manyrequests_add_users_to_project`)
    .then(function(response){
      console.log(response);
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  
  return(
    <React.Fragment>
      <div className="field">
        <h4>Step 1: Import Team</h4>
        <label>Team URL</label>
        <div class="input-group">
          <input type="text" class="form-control" onChange={(e) => setTeamURL(e.target.value)}/>
          <span class="input-group-btn">
            <button disabled={disabled} class="btn btn-default" type="button" onClick={fetchTeammates}>Fetch</button>
            {teammates.length > 0 && 
              <button onClick={createTeammates} className="btn btn-primary"><i class="fal fa-download mr-8"></i>Import {teammates.length} teammates</button>
            }
          </span>
        </div>
      </div>

      <div className="field">
        <h4>Step 2: Import Clients</h4>
        <label>Clients URL</label>
        <div class="input-group">
          <input type="text" class="form-control" onChange={(e) => setClientsURL(e.target.value)}/>
          <span class="input-group-btn">
            <button disabled={disabled} class="btn btn-default" type="button" onClick={fetchClients}>Fetch</button>
            {clients.length > 0 && 
              <button onClick={createClients} className="btn btn-primary"><i class="fal fa-download mr-8"></i>Import {clients.length} clients</button>
            }
          </span>
        </div>
      </div>

      <div className="field">
        <h4>Step 3: Import Requests</h4>
        <label>Requests URL</label>
        <div class="input-group">
          <input type="text" class="form-control" onChange={(e) => setRequestURL(e.target.value)}/>
          <span class="input-group-btn">
            <button disabled={disabled} class="btn btn-default" type="button" onClick={fetchRequests}>Fetch</button>
            {requests.length > 0 && 
              <button onClick={handleSubmit} className="btn btn-primary"><i class="fal fa-download mr-8"></i>Import {requests.length} requests</button>
            }
          </span>
        </div>
      </div>

      <div className="field">
        <h4>Step 4: Import Comments</h4>
        <label>Task Title</label>
        <div class="input-group">
          <input type="text" class="form-control" onChange={(e) => setCommentsURL(e.target.value)}/>
          <span class="input-group-btn">
            <button disabled={disabled} class="btn btn-default" type="button" onClick={fetchComments}>Fetch</button>
          </span>
        </div>

        <div className="input-group">
          <label>Task title</label>
          <div className="input-group">
            <input type="text" class="form-control" onChange={(e) => setTaskTitle(e.target.value)}/>
            <span class="input-group-btn">
              <button disabled={disabled} class="btn btn-default" type="button" onClick={fetchComments}>Fetch</button>
              {comments.length > 0 && 
                <button disabled={taskTitle.length === 0} onClick={createComments} className="btn btn-primary"><i class="fal fa-download mr-8"></i>Import {comments.length} comments</button>
              }
            </span>
          </div>
        </div>

        <div className="field">
          <h4>Step 5: Add clients to projects</h4>
          <label>Add clients to projects</label>
          <button onClick={addClientsToProjects} className="btn btn-primary"><i class="fal fa-download mr-8"></i>Add clients to projects</button>
        </div>
      </div>

    </React.Fragment>
  )
}

export default ManyRequests;