import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import { Modal } from 'react-responsive-modal';
import axios from "axios";

const New = ({refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [disable, setDisable] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [title, setTitle] = useState("");

  const onCloseModal = () => setOpen(false);
  const onOpenModal = () => {
    setOpen(true);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault(); 
    setDisable(true);

    axios.post(`/api/o`, {
      title: title,
      organization_id: match.params.organization_id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Workspace created. Redirecting...")
        // redirect(`/o/${response.data.organization.token}?token=${response.data.user_id}`)
        window.location.href = `https://${response.data.organization.subdomain}.usequeue.com?token=${response.data.user_id}`
        console.log(`https://${response.data.organization.subdomain}.usequeue.com?token=${response.data.user_id}`)
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
    });
  }

  

  useEffect(() => {
    fetchOrganization();
  }, []);

  const fetchOrganization = () => {
    axios.get(`/api/o/${match.params.organization_id}.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganization(response.data.organization);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  return(
    loaded && 
    <React.Fragment>
      <a onClick={onOpenModal} className="navigation-organizations-item">
        <i class="fal fa-plus"></i> Add a workspace
      </a>

      <Modal open={open} onClose={onCloseModal} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <h4 className="color-black-always modal-title">Add a workspace</h4>

        <div className="row no-margin">
          <form id="new_organization" onSubmit={handleSubmit}>
            <div className="field">
              <label className="color-black-always">Title</label>
              <input name="title" className="form-control" placeholder="Usually your business name" required={true} onChange={(e) => setTitle(e.target.value)}/>
            </div>

            <div className="field text-right">
              <button type="submit" className="btn btn-primary" disable={disable}>Create workspace</button>
            </div>
          </form>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default New