import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import NewOrganizationProjectUserProfile from "./OrganizationProjectUserProfiles/NewOrganizationProjectUserProfile.js";
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import Select from 'react-select';
import Form from "../Organizations/Roles/Form.js";

const OrganizationUser = ({user, refetchData, roles, setOrganizationUsers}) => {
  const { hasPermission, organizationAuthorizations } = useContext(OrganizationContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  
  const roleOptions = [
    {
      value: null,
      label: 'Admin'
    },
    ...roles.map(role => ({
      value: role.token,
      label: role.title
    })),
    {
      value: 'add_role',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <i className="fal fa-plus mr-8"></i>
          Add Role
        </div>
      )
    }
  ];
  const deleteOrganizationUser = (organization_id, self) => {
    setDisabled(true)
    axios.delete(`/api/o/${match.params.organization_id}/organization_users/${organization_id}`)
    .then(function(response){
      if(self){
        window.location.href("/")
      } else {
        refetchData();
      }
    })
  }

  const handleRoleChange = (selectedRole) => {
    if (selectedRole === null) {
      axios.post(`/api/o/${match.params.organization_id}/organization_users/${user.organization_user_id}/modify_role`, {
        role_id: null
      })
      .then((response) => {
        if(response.data.success){
          console.log(response.data.organization_user)

          setOrganizationUsers(prevUsers => 
            prevUsers.map(orgUser => 
              orgUser.token === response.data.organization_user.token
                ? response.data.organization_user
                : orgUser
            )
          );
        }
      })
      .catch((error) => {
        console.error('Error removing role:', error);
      });
      return;
    }

    if (selectedRole.value === 'add_role') {
      setRoleModalOpen(true);
      return;
    }

    axios.post(`/api/o/${match.params.organization_id}/organization_users/${user.organization_user_id}/modify_role`, {
      role_id: selectedRole.value
    })
    .then((response) => {
      setOrganizationUsers(prevUsers => 
        prevUsers.map(orgUser => 
          orgUser.token === response.data.organization_user.token
            ? response.data.organization_user
            : orgUser
        )
      );
      // refetchData();
    })
    .catch((error) => {
      console.error('Error updating role:', error);
    });
  };

  return(
    <React.Fragment>
      <tr className="queue-table-tr hover-parent-opacity" key={user.organization_user_id}>
        <td>
          <div className="display-flex table-gap-8">
            <img src={user.avatar} className="queue-table-image" />
            <div>
              <div className="font-14">{user.username}</div>
              <div className="opacity-4 font-12">{user.email}</div>
            </div>
          </div>
        </td>

        <td className="queue-table-td">
          {(user.organization_project_user_profile && user.organization_project_user_profile.hourly_rate !== null && user.organization_project_user_profile.hourly_rate != 0 && user.organization_project_user_profile.hourly_rate != '') ? (
            <>
              {user.organization_project_user_profile.hourly_rate} / Hour
              <a onClick={() => setOpen(true)} className="btn btn-small background-hover hover-child-opacity color-1 ml-5"><i className="fas fa-pencil"></i></a>
            </>
          ):(
            <a onClick={() => setOpen(true)} className="btn btn-small background-hover hover-child-opacity color-1 ml-15-negative"><i className="fas fa-plus mr-8"></i>Add rate</a>
          )}
        </td>

        <td className="queue-table-td">
          {user.last_sign_in_at}
        </td>

        <td>
          <div className="max-width-200px">
            <Select
              options={roleOptions}
              onChange={handleRoleChange}
              value={user.role ? {
                value: user.role.token,
                label: user.role.title
              } : {
                value: null,
                label: 'Admin'
              }}
              isClearable={false}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Select..."
            />
          </div>
        </td>

        <td className="text-right">
          {((user.owner) && (!user.organization_owner)) &&
            <>
              <div class="btn-group">
                <a type="button" class="btn btn-small background-hover color-1 dropdown-toggle opacity-4 opacity-10-hover" data-toggle="dropdown">
                  <i className="fas fa-ellipsis-v"></i>
                </a>
                <ul class="dropdown-menu animated fadeInUp pull-right" role="menu">
                  <li><a onClick={() => deleteOrganizationUser(user.organization_user_id, user.user_self)}><i className="fal fa-trash-alt"></i>Remove</a></li>
                </ul>
              </div>
            </>
          }
        </td>
      </tr>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <h4 className="color-black-always modal-title">Workspace member profile</h4>

        <div className="row no-margin">
          <NewOrganizationProjectUserProfile user={user} refetchData={refetchData} closeModal={() => setOpen(false)}/>
        </div>
      </Modal>

      <Modal open={roleModalOpen} onClose={() => setRoleModalOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <h4 className="color-black-always modal-title">Add New Role</h4>
        <div className="row no-margin">
          <Form refetchData={refetchData} closeModal={() => setRoleModalOpen(false)}/>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default OrganizationUser;