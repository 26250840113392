import React from 'react';
function Priority1Icon() {
  return (
    <svg width="23" height="23" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="49" height="49" rx="7.5" fill="#00c2924d" />
      <path d="M26.5952 14L26.3608 31.25H23.6421L23.4077 14H26.5952ZM25.0015 38.1875C24.4233 38.1875 23.9272 37.9805 23.5132 37.5664C23.0991 37.1523 22.8921 36.6563 22.8921 36.0781C22.8921 35.5 23.0991 35.0039 23.5132 34.5898C23.9272 34.1758 24.4233 33.9687 25.0015 33.9687C25.5796 33.9687 26.0757 34.1758 26.4897 34.5898C26.9038 35.0039 27.1108 35.5 27.1108 36.0781C27.1108 36.4609 27.0132 36.8125 26.8179 37.1328C26.6304 37.4531 26.3765 37.7109 26.0562 37.9062C25.7437 38.0938 25.3921 38.1875 25.0015 38.1875Z" fill="#00c292"/>
    </svg>

  )
}

export default Priority1Icon 