import React, {useState, useContext, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import { Modal } from 'react-responsive-modal';
import { NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import ProjectDropdown from "./ProjectDropdown.js";
import EmbedDropdown from "../Apps/Embeds/EmbedDropdown.js";
import { useHotkeys } from 'react-hotkeys-hook'
import { AppContext } from "../Shared/AppContext.js";
import {ProjectContext} from "./ProjectContext.js";
import NewProject from "./New.js";
import {Menu, Item, useContextMenu} from "react-contexify";

const Project = ({project, fetchProjects, privateProject, index}) => {
  const {testers} = useContext(ProjectContext);
  const {unreadNotifications} = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const [openSubmenu, setOpenSubmenu] = useState(localStorage.getItem(`project-${project.token}-submenu`) || false);
  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ id: `project-${project.token}-nested-dropdown` }).show({ 
      event: e,
      position: { x: e.clientX - 190, y: e.clientY + 20 }
    });
  };

  useHotkeys(
    `shift+${index+1}`,
    () => {
      if (!project.private) {
        history.push(`${(project.project_access_type === "Client" || location.href.includes(`/portal/o/`)) ? "/portal" : ""}/o/${project.organization.token}/projects/${project.token}/tasks`);
      }
    },
    [project.private, project.project_access_type, project.organization.token, project.token]
  );

  const embeds_list = project?.embeds?.map(embed => {
    return(
      <div className="display-flex align-items-center hover-parent-opacity">
        <li className="daas-platform-links-li animate sidebar-text-color side-nav-submenu-li animated fadeInUp fast-delay-1 width-100-percent">
          {embed.direct_link ? ( 
            <a activeClassName="background-active" href={embed.direct_link} target="_blank" className="daas-platform-links-a sidebar-text-color side-nav-submenu">
              {embed.title}
            </a>
          ):(
            <NavLink activeClassName="background-active" to={`/o/${match.params.organization_id}/projects/${project.token}/embeds/${embed.token}`} exact className="daas-platform-links-a sidebar-text-color side-nav-submenu">
              {embed.title}
            </NavLink>
          )}
        </li>
        <EmbedDropdown embed={embed} project_id={project.token} refetchData={fetchProjects} className="pull-right mr-5"/>
      </div>
    )
  })

  const nested_projects_list = project?.nested_projects?.map(nested_project => {
    return(
      <div className="display-flex align-items-center hover-parent-opacity">
        <li className="daas-platform-links-li animate sidebar-text-color side-nav-submenu-li width-100-percent">
          <NavLink activeClassName="background-active" to={`/o/${match.params.organization_id}/projects/${nested_project.token}/tasks`} className="daas-platform-links-a sidebar-text-color side-nav-submenu">
            {nested_project.title}
            {unreadNotifications.some(notification => notification.project?.token === nested_project.token) && 
              <span className="notification-dot-small" style={{position: "absolute", left: "-3px", top: "3px"}}></span>
            }
          </NavLink>

          <div className="align-items-center hover-icons-container">
            {!location.pathname.includes("/portal/o/") && 
              <ProjectDropdown project={nested_project} refetchData={fetchProjects}/>
            }
          </div>
        </li>
      </div>
    )
  })
  
  const handleProjectClick = () => {
    const isCurrentProject = location.href.includes(project.token);
    const newState = isCurrentProject ? !openSubmenu : true;
    setOpenSubmenu(newState);
    localStorage.setItem(`project-${project.token}-submenu`, newState);
  };

  return (
    <>
      <li className="daas-platform-links-li color-1 hover-parent-opacity hover-parent" data-html={true} data-tip={index+1 > 9 ? "" : `<div class="tooltip-keybind-wrapper"><span class='tooltip-keybind'>SHIFT</span> + <span class='tooltip-keybind'>${index + 1}</span></div>`}>
        <NavLink 
          activeClassName="background-active" 
          to={`${(project.project_access_type === "Client" || location.href.includes(`/portal/o/`)) ? "/portal" : ""}/o/${project.organization.token}/projects/${project.token}/tasks`} 
          className={`daas-platform-links-a sidebar-text-color justify-content-between truncate ${location.href.includes(project.token) ? "background-active" :""}`}
          onClick={handleProjectClick}
        >
          <div className="daas-platform-links-li-left-side truncate">
            <span className="sidebar-text-color">
              <i class="sidebar-text-color fas fa-grip-vertical projects-move-icon"></i>
            </span> 
            {project.status?.paused ? (
              <i data-tip="Paused" className="fas fa-pause color-yellow project-list-avatar font-20 text-center"></i>
            ) : project.status?.cancel_at_period_end && project.status?.expired ? (
              <i data-tip="Cancelled" className="fas fa-times color-red project-list-avatar font-20 text-center"></i>
            ) : (
              <img src={project.avatar} className="project-list-avatar" />
            )}

            <div className="display-flex flex-direction-column">
              {project.parent_project && 
                <div className="font-10 opacity-6">
                  {project.parent_project.title}
                </div>
              }
              <div>
                {project.title} 
                {(project?.nested_projects?.length > 0 || project?.embeds?.length > 0) && 
                  <i class={`fas font-12 ml-10 color-1 ${openSubmenu ? "fa-caret-down" : "fa-caret-right"}`}></i>
                }
              </div>
            </div>
            {unreadNotifications.some(notification => (
              notification.project?.token === project.token || 
              project?.nested_projects?.some(nested_project => nested_project.token === notification.project?.token)
            )) && 
              <span className="notification-dot-small" style={{position: "absolute", left: "-3px", top: "3px"}}></span>
            }
            
            {privateProject && <i class="fas fa-lock opacity-6 sidebar-text-color font-10 ml-5"></i>}
          </div>
        </NavLink>
        
        <div className="align-items-center hover-icons-container">
          <a onClick={handleContextMenu} data-tip="Add nested boards, documents, and more." className="color-1 mr-5 btn navigation-project-item-btn background-hover color-1 opacity-4 opacity-10-hover sidebar-text-color">
            <i className="fal fa-plus"></i>
          </a>

          <Menu id={`project-${project.token}-nested-dropdown`}>
            <Item onClick={() => setOpen(true)}>
              <i className="fal fa-columns"></i>Taskboard
            </Item>

            <Item disabled>
              <i className="fal fa-file-alt"></i>Documents
              <span className="badge background-active ml-15">Soon</span>
            </Item>
          </Menu>

          {!location.pathname.includes("/portal/o/") && 
            <ProjectDropdown project={project} refetchData={fetchProjects}/>
          }
        </div>
      </li>
      
      {openSubmenu && 
        <div className="animated fadeIn">
          {nested_projects_list}
          {embeds_list}
        </div>
      }

      {open && 
        <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
          <NewProject organization_id={match.params.organization_id} parent_project_id={project.token} onCloseModal={() => {setOpen(false); }} refetchData={() => {fetchProjects(); setOpenSubmenu(true)}}/>
        </Modal>
      }
    </>
  )
}

export default Project