import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Toggle from 'react-toggle';

const Settings = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [enable, setEnable] = useState(false);

  return(
    <React.Fragment>
      <div className="col-8">
        <label className="opacity-10">Renew subscription automatically</label>
        <p className="opacity-6">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae voluptatibus corrupti atque repudiandae nam.
        </p>
      </div>

      <div className="col-4 text-right">
        <Toggle icons={false} checked={enable} onChange={() => {setEnable(!enable);}} />
      </div>
    </React.Fragment>
  )
}

export default Settings;