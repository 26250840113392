import React, { useState, useContext} from 'react'
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import {UserContext} from "../Shared/UserContext";
import Edit from "./Edit.js";
import Share from "./Share.js";
import Premium from "../Subscriptions/Premium.js";
import SettingsTab from "./SettingsTab.js";
import { useTranslation } from 'react-i18next';

const ReplayDropdown = ({replay, refetchData, removeReplay}) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const [openEdit, setOpenEdit] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openSubscriptionModal, SetOpenSubscriptionModal] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [awaitFavorite, setAwaitFavorite] = useState(false);
  const currentUser = useContext(UserContext);

  const addToPreviousFolder = () => {
    notice("Adding...")

    $.ajax({
      type: "POST",
      url: `/replays/${replay.token}/add_to_previous_folder?folder_id=${match.params.folder_id}`,
      success: (response) => {
        console.log(response);
        fetchProjectReplays(false);
      }
    });
  }

  const trashThisReplay = (e) => {
    e.preventDefault();

    setDisabled(true);
    axios.post(`/api/replays/${replay.token}/trash`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        // fetchProjectReplays(false);
        removeReplay(replay.token)
        setDisabled(false);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const createFavorite = (e) => {
    e.preventDefault();
    notice("Favoriting...")


    axios.post(`/api/replays/${replay.token}/favorite`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        fetchProjectReplays();
        notice("Favorite created");
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const removeFavorite = (e) => {
    e.preventDefault();
    notice("Removing...")

    axios.post(`/api/replays/${replay.token}/unfavorite`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        fetchProjectReplaysAsync();
        notice("Favorite removed");
        setDisabled(false);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
    });
  }

  const duplicate = (e) => {
    notice("Duplicating...")
    e.preventDefault();
    
    axios.post(`/api/replays/${replay.token}/duplicate`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        fetchProjectReplays();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const downloadFile = () => {
    const url = replay.download_link;

    // Get the last portion of the URL after the last "/"
    const filename = url.substring(url.lastIndexOf("/") + 1);

    // Get the file extension by getting the portion of the filename after the last "."
    const fileExtension = filename.substring(filename.lastIndexOf(".") + 1);

    console.log(fileExtension); // Output: "png"

    fetch(replay.download_link, {
      method: 'GET',
      headers: {
        'Content-Disposition': 'attachment'
      }
    })
      .then(response => response.blob())
      .then(blob => {
        // Create a URL for the blob
        const url = URL.createObjectURL(blob);

        // Create a link element with the download attribute
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', replay.title.includes(fileExtension) ? `${replay.title}` : `${replay.title}`);

        // Add the link to the page and click it to download the file
        document.body.appendChild(link);
        link.click();

        // Cleanup the URL and link element
        URL.revokeObjectURL(url);
        link.remove();
      })
      .catch(error => console.error(error));
  }

  const removeTrash = (e) => {
    notice("Removing from trash...")
    e.preventDefault();
    
    axios.put(`/api/replays/${replay.token}`, {
      trash: null
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const moveToDeliverables = (e) => {
    notice("Moving...")
    e.preventDefault();
    
    axios.post(`/api/replays/${replay.token}/move_to_deliverables`, {
      trash: null
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Move complete")
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  return(
    <React.Fragment>
      <div className="dropdown">
        <i className="dropdown-toggle fas fa-ellipsis-v hover-child-opacity color-1 cursor-pointer" data-toggle="dropdown" style={{padding: "0px 10px"}}></i>
        <ul id={`replay_dropdown_menu_${replay.token}`} className="dropdown-menu animated fadeInUp pull-right" style={{minWidth: "200px", right: '-10px', top: '25px'}}>
          <i className="fas fa-caret-up navbar-dropdown-arrow"></i>
            {match.params.folder_id != null &&
              <li>
                <a onClick={addToPreviousFolder}>
                  <i class="fad fa-folders"></i> {t("Move up one folder")}
                </a>
              </li>
            }

            <li>
              <a onClick={() => setOpenShareModal(true)}> <i class="fal fa-share"></i>{t("Share")}</a>
            </li>

            <li>
              <a onClick={() => setOpenEdit(true)}> <i class="fal fa-pen"></i>{t("Rename")}</a>
            </li>

            <li>
              <a onClick={() => setOpenSettings(true)}> <i class="fal fa-cog"></i>{t("Settings")}</a>
            </li>

            <li>
              {(replay.download_link.includes("digitaloceanspaces") || replay.download_link.includes("filestack")) ? (
                <>
                  {replay.download_link.includes("digitaloceanspaces") ? (
                    <a onClick={downloadFile}>
                      <i className="fal fa-download"></i> {t("Download")}
                    </a>
                  ):(
                    <a href={replay.download_link} target="_blank" download={replay.title}>
                      <i className="fal fa-download"></i> {t("Download")}
                    </a>
                  )}
                </>
              ):(
                <a href={replay.download_link} target="_blank">
                  <i className="fal fa-eye"></i> {t("View source")}
                </a>
              )}
            </li>

            {
              replay.favorite_at == null ? (
                <li><a onClick={createFavorite}> <i class="fal fa-star"></i>Favorite</a></li>
              ):(
                <li><a onClick={removeFavorite}> <i class="fal fa-star"></i>{awaitFavorite ? "Removing..." : "Remove favorite"}</a></li>
              )
            }

            <li>
              <a onClick={duplicate}><i className="fal fa-copy"></i>{t("Duplicate")}</a>
            </li>

            {
              replay.trash ? (
                <li><a onClick={removeTrash}> <i class="fal fa-trash"></i>Untrash</a></li>
              ):(
                <li><a onClick={trashThisReplay}> <i class="fal fa-trash"></i>{disabled ? "Trashing.." : "Trash"}</a></li>
              )
            }

            {/* <li><a onClick={trashThisReplay}> <i class="fal fa-trash"></i>Trash</a></li> */}

            <Modal open={openSettings} onClose={() => setOpenSettings(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
              <SettingsTab replay={replay} onCloseModal={() => setOpenSettings(false)} autoClose={true} />

            </Modal>

            <Modal open={openEdit} onClose={() => setOpenEdit(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
              <Edit replay={replay} onCloseModal={() => {setOpenEdit(false)}} refetchData={refetchData}/>
            </Modal>

            <Modal open={openSubscriptionModal} onClose={() => SetOpenSubscriptionModal(false)} className="modal-body-white" classNames={{modal: 'width-1000-modal'}} center>
              <Premium organization_id={match.params.organization_id}/>
            </Modal>

            <Modal open={openShareModal} onClose={() => setOpenShareModal(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
              <Share replay={replay} refetchData={refetchData} index={1}/>
            </Modal>
        </ul>
      </div>
    </React.Fragment>
  )
}

export default ReplayDropdown;