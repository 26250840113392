import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import CompanyIndex from "../Companies/Index.js";

const ClientUser = ({client, setClients, refetchData, companies}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [showCompany, setShowCompany] = useState(false);

  useEffect(() => {
    // Initialize selectedUsers with existing company users
    if (client.companies) {
      const initialCompanies = client.companies.map(company => ({
        value: company.token,
        label: company.title,
      }));
      setSelectedCompanies(initialCompanies);
    }
  }, [client.companies]);

  const companyOptions = companies.map(company => ({
    value: company.token,
    label: company.title
  }));

  const handleCompanyChange = (newSelectedOptions) => {
    // Find added and removed companies
    const addedCompanies = newSelectedOptions.filter(
      option => !selectedCompanies.some(selected => selected.value === option.value)
    );
    const removedCompanies = selectedCompanies.filter(
      selected => !newSelectedOptions.some(option => option.value === selected.value)
    );

    // Call handleCompanyAdded for each added company
    addedCompanies.forEach(company => handleCompanyAdded(company));

    // Call handleCompanyRemoved for each removed company
    removedCompanies.forEach(company => handleCompanyRemoved(company));

    // Update the state with the new selection
    setSelectedCompanies(newSelectedOptions);
  };

  const removeClient = () => {
    const confirmThis = confirm("Are you sure?")
    if(confirmThis){
      axios.delete(`/api/o/${match.params.organization_id}/projects/${client.project.token}/clients/${client.token}`)
      .then(function(response){
        if(self){
          // fetchPeople();
          setClients(prevState => _.reject(prevState, { token: client.token }));
        } else {
          // fetchPeople();
        }
      })
    }
  };

  const handleCompanyAdded = (company) => {
    axios.post(`/api/o/${match.params.organization_id}/companies/${company.value}/company_users.json?user_id=${client.user.id}`)
    .then(function(response){
      // console.log(response);
      refetchData();
    })
    .catch(function(error){
      console.log(error);
    });
    // Add your logic for when a user is added
  }

  const handleCompanyRemoved = (company) => {
    console.log(`User removed: ${company.label} (ID: ${company.value})`);
    axios.delete(`/api/o/${match.params.organization_id}/companies/${company.value}/company_users/${client.user.id}?user_id=${client.user.id}`)
    .then(function(response){
      // console.log(response);
      refetchData();
    })
    .catch(function(error){
      console.log(error);
    });
    // Add your logic for when a user is removed
  }

  const handleOptionClick = (option, event) => {
    if (option.value !== 'add_company') {
      // Open a modal or perform any action for existing companies
      console.log(`Clicked on company: ${option.label}`);
      // You can add your modal opening logic here
    }
  };

  const user_companies_list = client?.companies?.map(company => (
    <a className="btn btn-small background-hover color-1 ml-15-negative opacity-6 opacity-10-hover" onClick={() => setShowCompany(true)}>
      <i className="fal fa-building mr-8"></i> {company.title}
    </a>
  ));


  return(
    <React.Fragment>
      <tr className="queue-table-tr hover-parent-opacity" key={client.token}>
        <td>
          <div className="display-flex table-gap-8">
            <img src={client.user.avatar} className="queue-table-image" />
            <div>
              <div className="font-14">{client.user.username}</div>
              <div className="opacity-4 font-12">{client.user.email}</div>
            </div>
          </div>
        </td>

        <td>{client?.companies?.length > 0 ? user_companies_list : ''}</td>

        <td className="queue-table-td">{client.user.last_sign_in_at}</td>

        <td>
          <Link to={`/o/${match.params.organization_id}/projects/${client.project.token}/tasks`} className="btn btn-small color-1 background-secondary background-hover border-all">
            <i className="fal fa-external-link-alt mr-8"></i>{client.project.title}
          </Link>
        </td>
        
        <td className="text-right">
          <div class="btn-group">
            <a type="button" className="color-1 btn btn-small background-hover opacity-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fas fa-ellipsis-v"></i>
            </a>

            <ul class="dropdown-menu pull-right animated fadeInUp">
              <li><a onClick={removeClient}><i className="fal fa-trash"></i>Delete</a></li>
            </ul>
          </div>
        </td>
      </tr>

      {showCompany && 
        <div className="client-users-item-company box-shadow animated slideInRight">
          <div className="client-users-item-company-close">
            <a className="opacity-4 opacity-10-hover btn btn-small color-1 background-hover-secondary background-active" onClick={() => setShowCompany(false)}>
              <i className="fal fa-times mr-5"></i> Close
            </a>
          </div>

          <CompanyIndex project_id={client.project.token} refetchData={refetchData}/>
        </div>
      }
    </React.Fragment>
  )
}

export default ClientUser;