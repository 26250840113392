import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; 
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import {TaskboardContext} from "../Shared/TaskboardContext.js";

const NewLabel = ({closeModal}) => {
  const {labels, setLabels} = useContext(TaskboardContext);
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("4169E1");
  const [colorClass, setColorClass] = useState("background-blue");
  const [colorTitle, setColorTitle] = useState("Blue");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/labels`, {
      title: title,
      color: color
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setLabels(prevLabels => [...prevLabels, response.data.label]);
        setTitle("");
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
      setTitle("");
      setColor("4169E1")
      setColorClass("background-blue");
      setColorTitle("Blue")
    });
  }

  const labelOptions = [
    { color: '4169E1', title: 'Blue', class: "background-blue" },
    { color: 'ea2c39', title: 'Red', class: "background-red" },
    { color: '00c292', title: 'Green', class: "background-green" },
    { color: 'ffab58', title: 'Orange', class: "background-orange" },
    { color: 'f5c402', title: 'Yellow', class: "background-yellow" },
    { color: '6782f4', title: 'Purple', class: "background-purple" }
  ];

  const label_colors = labelOptions.map(option => {
    return(
      <li>
        <a onClick={() => {setColor(option.color); setColorClass(option.class); setColorTitle(option.title)}} className="text-black-only"><span className={`label-color ${option.class}`}></span>{option.title}</a>
      </li>
    )
  })


  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Create a label</h4>

      <div className="row no-margin">
        <form onSubmit={handleSubmit}>          
          <div class="input-group">
            <div class="input-group-btn">
              <a type="button" class="btn background-hover dropdown-toggle min-width-unset display-flex align-items-center background-3 color-1 border-all" data-toggle="dropdown">
                <span className={`label-color ${colorClass}`}></span> {colorTitle}
                <i class="far fa-angle-down ml-15"></i>
              </a>
              <ul class="dropdown-menu">
                {label_colors}
              </ul>
            </div>

            <input className="form-control" placeholder="New label" onChange={(e) => setTitle(e.target.value)}/>
            <span class="input-group-btn">
              <button disabled={disabled} class="btn btn-primary" type="submit">Create label</button>
            </span>
          </div>
        </form>
      </div>
      
    </React.Fragment>
  )
}

export default NewLabel;