import React , { useState, useEffect, useContext } from 'react';
import axios from "axios"; 
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input'
import {UserContext} from "../Shared/UserContext.js";
import ReactTooltip from 'react-tooltip';

const NotificationSettings = () => {
  const [loaded, setLoaded] = useState(false)
  const currentUser = useContext(UserContext);
  const [notificationSettings, setNotificationSettings] = useState([]);
  const { t } = useTranslation();
  const [value, setValue] = useState(currentUser.phone_number)

  useEffect(() => {
    fetchNotificationSettings();
  }, []);

  const fetchNotificationSettings = () => {
    axios.get(`/api/notification_settings.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setLoaded(true)
        setNotificationSettings(response.data.notification_settings)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (e, kind, type) => {
    console.log(e.target.checked, kind, type)

    if(!e.target.checked){
      axios.post(`/api/notification_settings`, {
        email: type == "email",
        queue: type == "queue", 
        kind: kind
      })
      .then(function(response){
        console.log(response);
        if(response.data.success){
          fetchNotificationSettings()
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {

      });
    } else {
      axios.post(`/api/notification_settings/delete`, {
        email: type == "email",
        queue: type == "queue", 
        kind: kind
      })
      .then(function(response){
        console.log(response);
        if(response.data.success){
          fetchNotificationSettings()
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {

      });
    }
  }

  const handleSubmitPhone = (evt) => {
    evt.preventDefault();

    axios.post(`/api/phone_number_add`, {
      phone_number: value
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Number updated")
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {

    });
  }


  return(
    loaded && 
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div class="container">
        <h4 className="color-black-always modal-title">{t("Notification Settings")}</h4>

        <div className="alert-warning mb-25 display-flex align-items-center">
          <i class="far fa-lightbulb mr-10"></i> 
          <div>We'll notify you about Tasks and Task Comments you're assigned to, even if this setting is off. </div>
        </div>

        {/* Header  */}
        <div className="row opacity-6 mb-10">
          <div className="col-10">

          </div>

          <div className="col-1 pl-15">
            Email
          </div>

          <div className="col-1 pl-15">
            In App
          </div>
        </div>

        {/* Task */}
        <div className="row field">
          <div className="col-10">
            <h5 className="color-black-3 font-weight-700 no-margin">{t("Tasks")}</h5>
            <p className="opacity-6 font-12 no-margin">{t("Whenever someone adds a new task to any column.")} Or updates the stage of the Task. </p>
          </div>

          {["email", "queue"].map((type) => (
            <div key={type} className="col-1 pl-15">
              <input
                type="checkbox"
                checked={!notificationSettings.some(
                  (setting) => setting.kind === "Task" && setting[type]
                )}
                className="notification-setting apple-switch"
                onClick={(e) => handleSubmit(e, "Task", type)}
              />
            </div>
          ))}
        </div>

        {/* TaskComment */}
        <div className="row field">
          <div className="col-10">
            <h5 class="color-black-3 font-weight-700 no-margin">{t("Task Comments")}</h5>
            <p className="opacity-6 font-12 no-margin">{t('Comments left on a task')}</p>
          </div>

          {["email", "queue"].map((type) => (
            <div key={type} className="col-1 pl-15">
              <input
                type="checkbox"
                checked={!notificationSettings.some(
                  (setting) => setting.kind === "TaskComment" && setting[type]
                )}
                className="notification-setting apple-switch"
                onClick={(e) => handleSubmit(e, "TaskComment", type)}
              />
            </div>
          ))}
        </div>

        {/* VideoMarkers */}
        <div className="row field">
          <div className="col-10">
            <h5 class="color-black-3 font-weight-700 no-margin">{t("File Comments")}</h5>
            <p className="opacity-6 font-12 no-margin">{t("Comments left on any file in your workspaces")}</p>
          </div>

          {["email", "queue"].map((type) => (
            <div key={type} className="col-1 pl-15">
              <input
                type="checkbox"
                checked={!notificationSettings.some(
                  (setting) => setting.kind === "VideoMarker" && setting[type]
                )}
                className="notification-setting apple-switch"
                onClick={(e) => handleSubmit(e, "VideoMarker", type)}
              />
            </div>
          ))}
        </div>
        
        {/* Mentions */}
        <div className="row field">
          <div className="col-10">
            <h5 class="color-black-3 font-weight-700 no-margin">{t("Mentions")}</h5>
            <p className="opacity-6 font-12 no-margin">{t("When someone tags you in a comment or reply.")}</p>
          </div>

          {["email", "queue"].map((type) => (
            <div key={type} className="col-1 pl-15">
              <input
                type="checkbox"
                checked={!notificationSettings.some(
                  (setting) => setting.kind === "Mention" && setting[type]
                )}
                className="notification-setting apple-switch"
                onClick={(e) => handleSubmit(e, "Mention", type)}
              />
            </div>
          ))}
        </div>

        {/* Assigned */}
        <div className="row field">
          <div className="col-10">
            <h5 class="color-black-3 font-weight-700 no-margin">{t("Assigned Task")}</h5>
            <p className="opacity-6 font-12 no-margin">{t("When someone assigns you to a Task or File.")}</p>
          </div>

          {["email", "queue"].map((type) => (
            <div key={type} className="col-1 pl-15">
              <input
                type="checkbox"
                checked={!notificationSettings.some(
                  (setting) => setting.kind === "TaskUser" && setting[type]
                )}
                className="notification-setting apple-switch"
                onClick={(e) => handleSubmit(e, "TaskUser", type)}
              />
            </div>
          ))}
        </div>

        {/* Reactions */}
        <div className="row field">
          <div className="col-10">
            <h5 class="color-black-3 font-weight-700 no-margin">{t("Reactions")}</h5>
            <p className="opacity-6 font-12 no-margin">{t("When someone reacts to a comment or task with an emoji.")}</p>
          </div>

          {["email", "queue"].map((type) => (
            <div key={type} className="col-1 pl-15">
              <input
                type="checkbox"
                checked={!notificationSettings.some(
                  (setting) => setting.kind === "Reaction" && setting[type]
                )}
                className="notification-setting apple-switch"
                onClick={(e) => handleSubmit(e, "Reaction", type)}
              />
            </div>
          ))}
        </div>

        <hr />

        <div class="row">
          {/* Phone */}
          <form onSubmit={handleSubmitPhone} class="field row">
            <div class="col-10">
              <h5 class="color-black-3 font-weight-700 no-margin">{t("SMS Notifications")}</h5>
              <p className="opacity-6 font-12 no-margin">{t("SMS notifications when there are any notifications in app.")}</p>
              <p className="font-12 opacity-4">Delete your number to not recieve SMS notifications.</p>
            </div>

            <div class="col-2 notification-settings-checkbox">
              {/* <input type="checkbox" checked={smsNotificationEnabled} className="notification-setting apple-switch" onChange={handleSMSToggle} /> */}
              <PhoneInput defaultCountry="US" placeholder="Enter phone number" value={value} onChange={setValue} name="phone_number" />
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  )
}

export default NotificationSettings