import React from 'react';
import axios from "axios"; 
const Edit = ({refetchData, replay_id, feedbackLink}) => {
  const removeReminders = () => {
    axios.post(`/api/replays/${replay_id}/feedback_links/${feedbackLink.token}/disable_remind`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const deleteFeedbackLink = () => {
    axios.delete(`/api/replays/${replay_id}/feedback_links/${feedbackLink.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  return(
    <React.Fragment>
     <div class="btn-group">
      <a type="button" class="dropdown-toggle color-1 opacity-4 opacity-10-hover animate" data-toggle="dropdown">
        <i class="fas fa-ellipsis-v"></i>
      </a>
      
      <ul class="dropdown-menu" role="menu">
        {feedbackLink.remind && 
          <li><a onClick={removeReminders}><i class="fal fa-bell-slash mr-5"></i>Remove reminder</a></li>
        }
        <li><a onClick={deleteFeedbackLink}><i class="fal fa-trash mr-5"></i>Delete request</a></li>
      </ul>
    </div>
    </React.Fragment>
  )
}

export default Edit