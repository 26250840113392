import React , { useState, useEffect } from 'react';
import axios from "axios"; 

const Versions = ({replay_id, refetchData, closeModal}) => {
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [root, setRoot] = useState();
  const [versions, setVersions] = useState([])
  
  useEffect(() => {
    fetchVersions();
  }, []);

  const fetchVersions = () => {
    axios.get(`/api/replays/${replay_id}/versions.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setRoot(response.data.root)
        setVersions(response.data.versions);
        setLoaded(true);

        $( function() {
          $("#sortable").sortable({
            update: function(e, ui) {
              notice("sorted!")
              // $.ajax({
              //   url: $(this).data("url"), 
              //   type: "PATCH",
              //   data: $(this).sortable('serialize')
              // })
            }, 
            opacity: 0.5, 
            handle: ".handle"
          })
        } );

        // $( function() {
        //   $( "#sortable" ).sortable();
        // } );
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const destroyVersion = (destroy_replay_id) => {
    axios.post(`/api/replays/${replay_id}/destroy_version`, {
      destroy_replay_id: destroy_replay_id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const versions_list = versions.map(version => {
    return (
      <li class="ui-state-default" style={{listStyle: "none"}}>
        <div class="row mb-15 border-top hover-parent-opacity" style={{alignItems: "center", paddingTop: "15px"}}>
          <div className="col-1">
            {/* <i class="fas fa-arrows-v mr-5" style={{cursor: "move"}}></i> */}
            <span className="replay-version-index">V</span>{version.index}
          </div>

          <div class="col-2 versions-background-image">
            <img src={version.preview_image} className="replay-version-preview-image"/>
          </div>

          <div class="col-7 no-padding">
            <a href={`/replays/${version.token}`} target="_blank" class="color-1 opacity-6 opacity-10-hover no-margin font-13 truncate width-100-percent">
              {version.title}
            </a>

            <p class="no-margin font-10">
              {version.user.username} • {version.created_at} ago 
            </p>
          </div>

          <div className="col-1">
            <i onClick={() => destroyVersion(version.token)} class="fas fa-times animate btn btn-small background-hover hover-child-opacity"></i>
          </div>
        </div>
      </li>
    )
  })

  return(
    loaded ? (
      <React.Fragment>
        <div className="modal-title">
          Manage versions
        </div>

        <div id="sortableVersions">
          <ul id="sortable" className="pl-0">
            {versions_list}
          </ul>
        </div>

      </React.Fragment>
    ):(
      <div className="text-center large-padding">
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    )
  )
}

export default Versions