import React , { useState } from 'react';
import axios from "axios";

const Edit = (props) => {
  const [disabled, setDisabled] = useState(false);
  

  async function handleSubmit(evt) {
    setDisabled(true);
    evt.preventDefault();
  
    try {
      const response = await axios.put(`/api/replays/${props.replay.token}`, {
        title: document.getElementById("replayTitle").value
      });
  
      console.log(response);
  
      if (response.data.success) {
        await props.refetchData();
        props.onCloseModal();
        notice("Successfully updated file");
      }
    } catch (error) {
      console.log(error);
      notice("An error occurred");
    }
  
    setDisabled(false);
  }

  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Update file</h4>

      <form onSubmit={handleSubmit}>
        <div className="field">
          <input id="replayTitle" className="form-control" name="title" defaultValue={props.replay.title} />
        </div>

        <div className="field text-right">
          <button disabled={disabled} className="btn btn-primary" type="Submit">{disabled ? "Updating..." : "Update"}</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Edit