import React, {useState} from 'react';
import JSZip from 'jszip';

const ZipDownloader = ({ classNames, files, message, zipFileName}) => {
  const fileUrls = files.map(attachment => attachment.url);
  const [downloading, setDownloading] = useState(false);
  
  const downloadFilesAsZip = () => {
    setDownloading(true);
    const zip = new JSZip();

    const getExtension = (contentType, originalFilename) => {
      const extensionMap = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      };
      
      if (originalFilename.includes('.')) {
        return originalFilename.split('.').pop();
      }
      
      return extensionMap[contentType] || contentType.split('/')[1] || 'unknown';
    };

    const promises = files.map(file => {
      return fetch(file.url)
        .then(response => {
          const contentType = response.headers.get('content-type');
          return response.blob().then(blob => ({ blob, contentType }));
        })
        .then(({ blob, contentType }) => {
          const filename = file.title || file.filename;
          const extension = getExtension(contentType, filename);
          const filenameWithExtension = filename.includes('.') ? filename : `${filename}.${extension}`;
          zip.file(filenameWithExtension, blob);
        });
    });

    Promise.all(promises)
      .then(() => {
        zip.generateAsync({ type: "blob" })
          .then(blob => {
            const zipFilename = `${zipFileName}.zip`;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = zipFilename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setDownloading(false)
          });
      })
      .catch(error => console.error('Error downloading files as zip:', error));
  };

  return (
    <a disabled={downloading} className={classNames} onClick={downloadFilesAsZip}><i class="fal fa-download mr-8"></i>{downloading ? "Please wait..." : message}</a>
  );
};

export default ZipDownloader;