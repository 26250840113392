import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import {OrganizationContext} from "../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import CommandMenu from '../Cmdk/CommandMenu.js';
import {ProjectContext} from "../Projects/ProjectContext.js";
import DefaultQuestionsUniversal from "../Daas/Portal/Projects/TaskForms/DefaultQuestionsUniversal.js";

const SearchOrNew = ({}) => {
  const {openCmdk, setOpenCmdk} = useContext(ProjectContext);
  const {organizationAuthorizations, hasPermission} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  return(
    <React.Fragment>
      <CommandMenu />
      <div className="navigation-search-wrapper">
        <div className="navigation-search-input-wrapper" onClick={() => setOpenCmdk(true)}>
          <div className="navigation-search-input-icon-wrapper">
            <i className="fas fa-search mr-8"></i>Search
          </div>
          <div className="navigation-search-input-keybind-wrapper">
            <div className="navigation-search-input-keybind" style={{fontSize: 9}}>⌘</div>
            <div className="navigation-search-input-keybind" style={{marginRight: 0, fontSize: 9}}>k</div>
          </div>
        </div>
        {hasPermission("create_task") && (
          <a className="navigation-new-request-button" onClick={() => setOpen(true)}>
            <i className="fas fa-plus mr-5"></i>Request
          </a>
        )}
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'modal-side'}} center>
        <DefaultQuestionsUniversal closeModal={() => setOpen(false)}/>
      </Modal>
    </React.Fragment>
  )
}

export default SearchOrNew