import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import "@lottiefiles/lottie-player";

const ImportAuth = (props) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    // Extract the token from the URL when the component mounts
    const url = new URL(window.location.href);
    const fragment = url.hash.substring(1); // Remove the '#' character
    const params = new URLSearchParams(fragment);
    const tokenParam = params.get('token');

    if (tokenParam) {
      setToken(tokenParam);
      handleSubmit();
    }
  }, []);

  useEffect(() => {
    if(token != null){
      handleSubmit();
    }
  },[token])

  const handleSubmit = () => {
    axios.post(`/projects/${match.params.project_id}/auth/trello_callback`, {
      token: token,
      project_id: match.params.project_id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){

      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <div className="import-auth-wrapper">
        <lottie-player autoplay loop mode="normal" src="https://assets5.lottiefiles.com/packages/lf20_wgcqrpue.json" style={{width: "300px", margin: "auto"}}></lottie-player>
        <h1 className="color-black-1 text-center" style={{fontWeight: "600"}}>Authentication finished</h1>
        <p className="color-black-4 text-center" style={{fontSize: "16px"}}>You can close this page</p>
        
      </div>
      {/* project_id: {match.params.project_id}
      <br />

      token: {token} */}
    </React.Fragment>
  )
}

export default ImportAuth