import React , { useState, useEffect, useContext } from 'react';
import axios from "axios"; 
import { useHistory } from 'react-router-dom'
import {UserContext} from "../Shared/UserContext.js";

const NewWorkspace = () => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [teamSize, setTeamSize] = useState('');
  const [title, setTitle] = useState(null)
  const [subdomain, setSubdomain] = useState("");
  const [isTaken, setIsTaken] = useState(false);
  const currentUser = useContext(UserContext);

  const handleTeamSizeChange = (event) => {
    setTeamSize(event.target.value);
  };

  const handleChange = (event) => {
    const newSubdomain = event.target.value.replace(/\s/g, ''); // Remove whitespaces
    setSubdomain(newSubdomain);
    checkIfSubdomainTaken(newSubdomain); // Check if subdomain is taken
  };

  const checkIfSubdomainTaken = (subdomain) => {
    axios.get(`/api/verify_subdomain?subdomain=${subdomain}`)
      .then((response) => {
        console.log(response)
        setIsTaken(response.data.taken);
      })
      .catch((error) => {
        setIsTaken(false); // Request failed, treat subdomain as not taken
      });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
      setDisabled(true);
  
      axios.post(`/api/o`, {
        title: title,  
        team_size: teamSize, 
        subdomain: subdomain
      })
      .then(function(response){
        console.log(response);
        if(response.data.success){
          // history.push(`/onboardings/${response.data.organization.token}/step3`);
          window.location.href = `https://${response.data.organization.subdomain}.${window.location.hostname.includes("ngrok") ? "queue.ngrok.io" : "usequeue.com"}/onboardings/${response.data.organization.token}/step1?token=${currentUser.token}`
        } else {
          notice("An error occured while trying to create your workspace. Try refreshing and trying again.")
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
  }

  // useEffect(() => {
  //   axios.get(`/api/organizations.json`)
  //   .then(function(response){
  //     console.log(response);
  //     if(response.data.organizations.length > 0){
  //       notice("Your account permits only 1 workspace.");
  //       history.push(`/onboardings/${response.data.organizations[0].organization.token}/step3`)
  //     }
  //   })
  //   .catch(function(error){
  //     console.log(error)
  //     notice("An error occured")
  //   })
  //   .then(function () {
  //     // always executed
  //   });
  // },[])

  return(
    <React.Fragment>
      <div className="onboarding-seller-wrapper">
          <div className="onboarding-seller-progress-container">
            <div className="onboarding-seller-progress-bar" style={{width: "16%"}}>
              fill
            </div>
          </div>
          <div className="onboarding-seller-container animated fadeIn box-shadow">
            <h3 className="onboarding-seller-header" style={{marginBottom: "20px"}}>Setup your workspace</h3>
            <p className="font-14 text-center opacity-7 color-black-always">
              Workspaces are shared place where teams can create projects, upload files, and more.
            </p>

            <form onSubmit={handleSubmit} id="edit_organization">
              <div className="onboarding-seller-bio-container field">
                <label>Your domain</label>
                <div class="input-group">
                  {/* <span class="input-group-addon form-control-white" id="basic-addon1">https://</span> */}
                  <input type="text" class="form-control" aria-describedby="basic-addon2" value={subdomain} onChange={handleChange}/>
                  <span class="input-group-addon" id="basic-addon2">.usequeue.com</span>
                </div>
                {isTaken && <p className="color-red font-10">Subdomain is taken. Please choose a different one.</p>}
              </div>

              <div className="onboarding-seller-bio-container">
                {/* Title */}
                <div class="input-group mb-15">
                  <label>Workspace Name</label>
                  <input
                    required={true}
                    name="title"
                    id="organization_title"
                    placeholder="Amazing Studios Inc."
                    class="form-control"
                    style={{borderRadius: "5px"}}
                    aria-describedby="basic-addon3"
                    maxLength={40}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </div>
              </div>

              {/* team_size */}
              <div className="onboarding-seller-bio-container">
                <div class="input-group mb-15">
                  <label>Team size</label>
                  <select className="form-control" value={teamSize} onChange={handleTeamSizeChange}>
                    <option>Select team size</option>
                    <option value="1">Just myself</option>
                    <option value="2-5">2-5</option>
                    <option value="6-10">6-10</option>
                    <option value="11-20">11-20</option>
                    <option value="20+">20+</option>
                  </select>
                </div>
              </div>

              <div className="onboarding-seller-btns-container display-center-ver margin-bottom-15px">
                <button disabled={disabled} className="btn btn-primary btn-block">Create workspace</button>
              </div>
            </form>
          </div>
        </div>
    </React.Fragment>
  )
}

export default NewWorkspace;