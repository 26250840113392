import React from 'react';
import NewAccount from "./NewAccount.js"; 
import NewClientAccount from "./NewClientAccount.js";

const CreateAccount = ({email, subdomainPresent, organization, setStep, refetchData, setEmail}) => {
  return(
    <React.Fragment>
      {subdomainPresent ? (
        <NewClientAccount organization={organization} email={email} setStep={setStep} refetchData={refetchData} setEmail={setEmail}/>
      ):(
        <NewAccount email={email} setStep={setStep} refetchData={refetchData} setEmail={setEmail}/>
      )}
    </React.Fragment>
  )
}

export default CreateAccount