import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './fr.json';
import de from './de.json';
import es from './es.json';
import esLA from './es-LA.json';
import ptBR from './pt-BR.json';
import da from './da.json';
import nl from './nl.json';
import no from './no.json';
import sv from './sv.json';
import pl from './pl.json';
import ro from './ro.json';
import it from './it.json';
import hi from './hi.json';
import bn from './bn.json';
import bg from './bg.json'; // Turkish
import en from './en.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      fr: {
        translation: fr,
      },
      de: {
        translation: de,
      },
      es: {
        translation: es,
      },
      'pt-BR': {
        translation: ptBR,
      },
      da: {
        translation: da,
      },
      nl: {
        translation: nl,
      },
      no: {
        translation: no,
      },
      pl: {
        translation: pl,
      },
      ro: {
        translation: ro,
      },
      it: {
        translation: it,
      },
      hi: {
        translation: hi,
      },
      bn: {
        translation: bn,
      },
      bg: {
        translation: bg,
      }
    },
    lng: 'en', // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
      escapeValue: false, // React already safes from XSS
    },
  });

export default i18n;
