import React from 'react';

function Priority2() {
  return (
    <svg width="23" height="23" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="49" height="49" rx="7.5" fill="#FFCC00" fill-opacity="0.368627"/>
      <path d="M22.0029 14L21.7686 31.25H19.0498L18.8154 14H22.0029ZM20.4092 38.1875C19.8311 38.1875 19.335 37.9805 18.9209 37.5664C18.5068 37.1523 18.2998 36.6563 18.2998 36.0781C18.2998 35.5 18.5068 35.0039 18.9209 34.5898C19.335 34.1758 19.8311 33.9687 20.4092 33.9687C20.9873 33.9687 21.4834 34.1758 21.8975 34.5898C22.3115 35.0039 22.5186 35.5 22.5186 36.0781C22.5186 36.4609 22.4209 36.8125 22.2256 37.1328C22.0381 37.4531 21.7842 37.7109 21.4639 37.9062C21.1514 38.0938 20.7998 38.1875 20.4092 38.1875ZM31.1875 14L30.9531 31.25H28.2344L28 14H31.1875ZM29.5938 38.1875C29.0156 38.1875 28.5195 37.9805 28.1055 37.5664C27.6914 37.1523 27.4844 36.6563 27.4844 36.0781C27.4844 35.5 27.6914 35.0039 28.1055 34.5898C28.5195 34.1758 29.0156 33.9687 29.5938 33.9687C30.1719 33.9687 30.668 34.1758 31.082 34.5898C31.4961 35.0039 31.7031 35.5 31.7031 36.0781C31.7031 36.4609 31.6055 36.8125 31.4102 37.1328C31.2227 37.4531 30.9688 37.7109 30.6484 37.9062C30.3359 38.0938 29.9844 38.1875 29.5938 38.1875Z" fill="#E4B600"/>
    </svg>
  )
}

export default Priority2 