import React, {useCallback, useState} from 'react';
import { BubbleMenu } from '@tiptap/react'
import { Popover, OverlayTrigger, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css';

const TipTapBubbleMenu = ({editor, setNewComment}) => {
  const [showPopover, setShowPopover] = useState(false);

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    
    confirmAlert({
      title: 'Add link',
      childrenElement: () => <input type="text" id="url-input" className="form-control" defaultValue={previousUrl} />,
      buttons: [
        {
          label: 'Add link',
          className: 'btn btn-primary',
          onClick: () => {
            const url = document.getElementById('url-input').value;
            if (url === '') {
              editor.chain().focus().extendMarkRange('link').unsetLink().run()
            } else {
              editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
            }
          }
        },
      ]
    });
  }, [editor]);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        <i className="fas fa-times" onClick={() => setShowPopover(false)}></i>
        <input type="text" placeholder="Enter something..." />
      </Popover.Body>
    </Popover>
  );
  
  return(
    <React.Fragment>
      <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
        <div className="editor-button-menu border-all box-shadow background-3">
          <a
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={`${editor.isActive('bold') ? 'background-active' : ''} border-right color-1 editor-button-menu-button background-hover btn btn-small`}
            style={{fontWeight: "700"}}
          >
            B
          </a>
          
          <a
            onClick={() => editor.chain().focus().toggleItalic().run()}
            style={{fontStyle: "italic"}}
            className={`${editor.isActive('italic') ? 'background-active' : ''} border-right color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            i
          </a>
          <a
            onClick={() => editor.chain().focus().toggleStrike().run()}
            style={{textDecoration: "line-through"}}
            className={`${editor.isActive('strike') ? 'background-active' : ''} border-right color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            S
          </a>
          <a
            onClick={setLink}
            style={{textDecoration: "line-through"}}
            className={`${editor.isActive('link') ? 'background-active' : ''} border-right color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            <i class="far fa-link"></i>
          </a>

          <a
            onClick={() => editor.chain().focus().toggleHighlight({ color: '#999940' }).run()}
            style={{textDecoration: "highlight"}}
            className={`${editor.isActive('highlight') ? 'background-active' : ''} color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            <i class="fal fa-highlighter"></i>
          </a>

          <a
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            style={{textDecoration: "underline"}}
            className={`${editor.isActive('highlight') ? 'background-active' : ''} color-1 editor-button-menu-button background-hover btn btn-small`}
          >
            <i class="fal fa-underline"></i>
          </a>

          {setNewComment && 
            <OverlayTrigger
              trigger="click"
              placement="right"
              overlay={popover}
              show={showPopover}
              onToggle={() => setShowPopover(!showPopover)}
            >
              <Button
                variant="link"
                className={`${editor.isActive('highlight') ? 'background-active' : ''} color-1 editor-button-menu-button background-hover btn btn-small`}
                onClick={() => setShowPopover(!showPopover)}
              >
                <i className="fal fa-comment mr-2"></i>Comment
              </Button>
            </OverlayTrigger>
          }
        </div>
      </BubbleMenu>
    </React.Fragment>
  )
}

export default TipTapBubbleMenu;