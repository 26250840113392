import React , { useState, useEffect } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import axios from "axios"; 
import LineChartDemo from "./LineChartDemo.js";
import Skeleton from "react-loading-skeleton"
import ReactTooltip from 'react-tooltip';
// import Chatgpt from "./Chatgpt.js";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import loadingData from "../../Shared/Lotties/lottie-loading.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Stripe from "../Stripe/Stripe.js";

const localizer = momentLocalizer(moment);
const DragAndDropCalendar = withDragAndDrop(Calendar);

const Analytics = ({projectAuthorizations}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalSingleAmount, setTotalSingleAmount] = useState(0);
  // const [revenueIncrease, setRevenueIncrease] = useState(0); // Add this state variable
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [stripePaymentIntents, setStripePaymentIntents] = useState([]);
  // const [projectTasks, setProjectTasks] = useState([]);
  const [stripeAccount, setStripeAccount] = useState(null);
  const [recurringInvoiceItems, setRecurringInvoiceItems] = useState([]);
  const [stripeSubscriptions, setStripeSubscriptions] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [mrr, setMrr] = useState(0);
  const [calendar, setCalendar] = useState(false);
  const [events, setEvents] = useState([]);
  const [showStripe, setShowStripe] = useState(false);
  const [averageTime, setAverageTime] = useState(null);

  useEffect(() => {
    const mrrValue = calculateMRR(subscriptions);
    setMrr(mrrValue);

    const subscriptionEvents = subscriptions.map(subscription => ({
      ...subscription,
      start: new Date(subscription.event_date), // Convert start date string to Date object
      end: new Date(subscription.event_date),     // Convert end date string to Date object
    }));
    setEvents(subscriptionEvents);
  }, [subscriptions]);

  const calculateMRR = (subscriptions) => {
    const currentDate = new Date();
  
    const activeSubscriptions = subscriptions.filter(subscription => {
      const periodEnd = new Date(subscription.current_period_end);
      return periodEnd > currentDate;
    });
  
    const mrr = activeSubscriptions.reduce((total, subscription) => {
      const { amount, interval, frequency } = subscription;
      
      // Normalize to monthly value based on interval and frequency
      let monthlyAmount;
      switch (interval) {
        case 'day':
          monthlyAmount = amount * frequency * 30; // Approximate days in a month
          break;
        case 'week':
          monthlyAmount = amount * frequency * 4; // Approximate weeks in a month
          break;
        case 'month':
          monthlyAmount = amount * frequency;
          break;
        case 'year':
          monthlyAmount = amount * frequency / 12; // Convert yearly to monthly
          break;
        default:
          monthlyAmount = 0; // In case of an unknown interval
      }
      
      return total + monthlyAmount;
    }, 0);
  
    return mrr;
  };

  useEffect(() => {
    fetchRecurringRevenue();
  }, []);

  const fetchRecurringRevenue = () => {
    axios.get(`/api/o/${match.params.organization_id}/analytics.json`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setStripeAccount(response.data.stripe_account);
        setStripeSubscriptions(response.data.stripe_subscriptions)
        setRecurringInvoiceItems(response.data.recurring_invoice_items);
        setSubscriptions([...response.data.stripe_subscriptions, ...response.data.recurring_invoice_items]);
        const newTotalAmount = response.data.subscriptions.reduce((acc, subscription) => {
          return acc + subscription.amount;
        }, 0);
        setTotalAmount(newTotalAmount);


        const singles = response.data.singles.reduce((acc, single) => {
          return acc + single.amount;
        }, 0);
        setTotalSingleAmount(singles)

        const stripePaymentIntents = response.data.stripe_payment_intents.reduce((acc, single) => {
          return acc + single.amount;
        }, 0);

        setTotalRevenue(stripePaymentIntents);

        setStripePaymentIntents(response.data.payments);
        // setProjectTasks(response.data.project_tasks)
        setAverageTime(response.data.average_time)
        setLoaded(true);
      } else {
        setLoaded(true);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSelectEvent = (event) => {
    history.push(`/o/${match.params.organization_id}/projects/${event.project.token}/details`)
  };

  const subscriptions_list = subscriptions.map(subscription => {
    return(
      <>
        <tr className="background-3">
          <td>
            {numberToCurrency((subscription.amount), stripeAccount != null ? stripeAccount.stripe_account_data.default_currency : "USD")} 
            <span className="ml-5 opacity-4 font-12">{subscription.frequency != 1 ? `every ${subscription.frequency} ${subscription.interval}` : `/ ${subscription.interval}`} </span>
          </td>
          <td>{subscription.event_date}</td>
          <td>
            {subscription.kind === "Stripe" && 
              <span className="badge badge-blue">Stripe</span>
            }

            {subscription.kind === "Invoice" && 
              <span className="badge badge-green">Invoice</span>
            }
          </td>
          <td>
            <Link to={`/o/${match.params.organization_id}/projects/${subscription.project.token}/details`} className="btn btn-small background-hover color-1">
              {subscription.project.title}<i class="far fa-long-arrow-right ml-8"></i>
            </Link>
          </td>
        </tr>
      </>
    )
  })

  return(
    projectAuthorizations.organization.stripe_account != null ? (

        <React.Fragment>
          <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
          <title>Analytics</title>
          <div className="container-fluid no-padding" style={{paddingTop: "15px"}}>
            <div className="row" style={{marginLeft: "-15px", marginRight: "-15px"}}>
              <div className="row" style={{paddingBottom: "0"}}>
                <LineChartDemo payments={stripePaymentIntents}/>
              </div>
              
              <div className="col-md-3 col-12 default-padding">
                <div className="background-3 border-radius border-all default-padding">
                  <div>
                    MRR
                  </div>
                  <h3>
                    {loaded ? (
                      <>
                        {numberToCurrency((mrr), stripeAccount != null ? stripeAccount.stripe_account_data.default_currency : "USD")}
                      </>
                    ):(
                      <Skeleton className="skeleton" style={{width: "100%", height: "100%"}}/>
                    )}
                  </h3>
                </div>
              </div>
    
              <div className="col-md-3 col-12 default-padding">
                <div className="background-3 border-radius border-all default-padding">
                  <div>
                    Single charge revenue
                  </div>
                  <h3>
                    {loaded ? (
                      <>
                        {numberToCurrency((totalSingleAmount / 100), stripeAccount != null ? stripeAccount.stripe_account_data.default_currency : "USD")}
                      </>
                    ):(
                      <Skeleton className="skeleton" style={{width: "100%", height: "100%"}}/>
                    )}
                  </h3>
                </div>
              </div>
    
              <div className="col-md-3 col-12 default-padding">
                <div className="background-3 border-radius border-all default-padding">
                  <div>
                    Total Revenue
                  </div>
                  
                  <h3>
                    {loaded ? (
                      <>
                        {numberToCurrency((totalRevenue / 100), stripeAccount != null ? stripeAccount.stripe_account_data.default_currency : "USD")}
                      </>
                    ):(
                      <Skeleton className="skeleton" style={{width: "100%", height: "100%"}}/>
                    )}
                  </h3>
                </div>
              </div>

              <div className="col-md-3 col-12 default-padding">
                <div className="background-3 border-radius border-all default-padding">
                  <div>
                    Average Task Completion time
                  </div>
                  
                  <h3>
                    {averageTime ? (
                      <>
                        {Math.round(averageTime / 86400)} Days
                      </>
                    ):(
                      <>
                        
                      </>
                    )}
                  </h3>
                </div>
              </div>
            </div>
    
            <div className="border-radius background-3 border-all mb-30 mt-15">
              <div className="default-padding pb-0 mb-20 display-flex align-items-center space-between">
                <div>
                  Upcoming subscription charges
                </div>
                <div>
                  {calendar ? (
                    <>
                      <i onClick={() => setCalendar(false)} class="fal fa-list btn btn-small background-hover mr-8 cursor-pointer"></i>
                    </>
                  ):(
                    <>
                      <i onClick={() => setCalendar(true)} class="fal fa-calendar-alt btn btn-small background-hover mr-8 cursor-pointer"></i>
                    </>
                  )}
                </div>
              </div>
              
              {calendar ? (
                <>
                  <DragAndDropCalendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: "80vh" }}
                    // onSelectSlot={handleSelectSlot}
                    onSelectEvent={handleSelectEvent}
                    // onEventDrop={moveEvent}
                    // resizable
                    // onEventResize={resizeEvent} 
                    // eventPropGetter={eventStyleGetter} // Apply custom styles
                    // popup={true}
                  />
                </>
              ):(
                <>
                  {loaded ? (
                    subscriptions.length > 0 ? (
                      <table class="table react-table-table table-bordered" style={{padding: 0}}>
                        <thead>
                          <tr>
                            <th className="react-table-th" scope="col">Amount</th>
                            <th className="react-table-th" scope="col">Due Date</th>
                            <th className="react-table-th" scope="col">Source</th>
                            <th className="react-table-th" scope="col">Project</th>
                          </tr>
                        </thead>
          
                        <tbody>
                          {subscriptions_list}
                        </tbody>
                      </table> 
                    ):(
                      <p className="text-center default-padding opacity-4">
                        No upcoming subscription charges
                      </p>
                    )
                  ):(
                    <div className="text-center large-padding height-50vh display-flex justify-content-center align-items-center" style={{flexDirection: "column"}}>
                      <Player style={{width: "150px", margin: "auto"}} mode="normal" autoplay loop src={loadingData} />
                    </div>
                  )}
                </>
              )}
            </div>
    
            {/* <Chatgpt /> */}
          </div>
        </React.Fragment>
      ) :(
        <>
        {showStripe ? ( 
          <div className="display-flex justify-content-center pt-15">
            <Stripe title="Create subscriptions and services" description="Connect your Stripe account to get started" refetchData={fetchRecurringRevenue}/>
          </div>
        ):(
          <div className="analytics-no-stripe-account">
            {/* <iframe className="analytics-no-stripe-account-video mb-15 animated fadeIn slow-1" src="https://iframe.mediadelivery.net/embed/239397/1a14796e-ace2-42ee-ac54-bba27c180488?autoplay=false&loop=true&muted=true&preload=true&responsive=true" loading="lazy"allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" allowfullscreen="true"></iframe> */}
            <h3 className="animated fadeIn slow-2">Analytics built for agencies</h3>
            <p className="opacity-7 animated fadeIn slow-4">
              Powerful analytics to understand how your business is performing. Integrated with your payment and project boards.
            </p>
            <a onClick={() => setShowStripe(true)} className="btn btn-primary mt-20"><i class="fab fa-stripe-s mr-8"></i>Connect Stripe Account</a>
          </div>
        )}
        </>
      )
    )
}

export default Analytics