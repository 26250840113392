import React from 'react';
const Btn = ({type, className, disabled, text, disable}) => {
  return(
    <React.Fragment>
      <button type={type} className={className} disabled={disabled || disable} text={text}>
        {disabled ? (
          <i className="fas fa-spinner fa-spin"></i>
        ):(
          <React.Fragment>
            {text}
          </React.Fragment>
        )}
      </button>
    </React.Fragment>
  )
}

export default Btn;