import React, {useState, useEffect} from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const LineChartDemo = ({payments}) => {
  const [data, setData] = useState(payments.monthly_totals);
  const [selectedButton, setSelectedButton] = useState("1y"); // Initialize with the default selection

  useEffect(() => {
    setData(payments?.monthly_totals)
  },[payments]);
  
  // Custom function to format numbers with commas
  const formatNumberWithCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleButtonClick = (buttonName, newData) => {
    setData(newData);
    setSelectedButton(buttonName);
  };

  return (
    <React.Fragment>
      <div className="default-padding col-md-12 col-12">
        <div className="background-3 default-padding border-radius border-all">
          <div className="analytics-header">
            <div>
              Revenue over times
            </div>

            <div class="btn-group">
              <button onClick={() => handleButtonClick("1y", payments.monthly_totals)} type="button" className={`btn background-hover border-all btn-small ${selectedButton === "1y" ? "background-active" : "background-none"}`}>1y</button>
              <button onClick={() => handleButtonClick("6m", payments.six_months)} type="button" className={`btn background-hover border-all btn-small ${selectedButton === "6m" ? "background-active" : "background-none"}`}>6m</button>
              <button onClick={() => handleButtonClick("3m", payments.three_months)} type="button" className={`btn background-hover border-all btn-small ${selectedButton === "3m" ? "background-active" : "background-none"}`}>3m</button>
              <button onClick={() => handleButtonClick("7d", payments.seven_days)} type="button" className={`btn background-hover border-all btn-small ${selectedButton === "7d" ? "background-active" : "background-none"}`}>7d</button>
            </div>
          </div>

          <div style={{height: "300px"}}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
                <XAxis dataKey="date" />
                <YAxis dataKey="amount" tickFormatter={`$${formatNumberWithCommas}`}/>
                <Tooltip labelStyle={{ color: 'gray' }} itemStyle={{ color: '#00c292' }}/>
                <Legend />
                <Line type="monotone" dataKey="amount" stroke="#00c292"/> {/* Use "amount" as the dataKey */}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </React.Fragment>
    
  );
};

export default LineChartDemo;
