import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const ImportSubscriptionItem = ({subscriptions, setSubscriptions, sub, refetchData, project_id}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [inviteClient, setInviteClient] = useState(false);

  // Function to handle checkbox change
  const importSubscription = (subscription, customer_email, customer_name) => {
    setDisabled(true);
    axios.post(`/api/o/${match.params.organization_id}/import_subscription`, {
      subscription: subscription, 
      customer_email: customer_email,
      customer_name: customer_name, 
      invite_client: inviteClient,
      project_id: project_id,
      service: subscription.service,
      service_price: subscription.service_price, 
      product: subscription.product, 
      price: subscription.price,
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        // Find the index of the imported subscription in the subscriptions array
        const subscriptionIndex = subscriptions.findIndex(
          (sub) => sub.id === subscription.id
        );

        if (subscriptionIndex !== -1) {
          // Update the 'exists' property to true
          const updatedSubscriptions = [...subscriptions];
          updatedSubscriptions[subscriptionIndex].exists = true;
          setSubscriptions(updatedSubscriptions);
        }

        refetchData();
        if(window.location.pathname.includes("/o/")){
          history.push(`/o/${match.params.organization_id}/projects/${response.data.project.token}/details`);
        }
        notice("Import finished")
      } else {
        notice(response.data.error)
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };

  // Function to handle checkbox change
  const handleCheckboxChange = () => {
    setInviteClient(!inviteClient); // Toggle the value of inviteClient
  };

  return(
    <React.Fragment>
      <div className="" key={sub.id}>
        <div className="ml-10 onboarding-subscriptions-item border-bottom">
          <div className="row onboarding-subscriptions-item-info">
            <div className="color-1 dark-color-black col-5">
              {sub.product.name}
            </div>
            
            <div className="color-1 dark-color-black col-3">
              {sub.customer_name}
            </div>

            {!project_id && 
              <div className="col-2 font-12 display-flex">
                <input style={{margin: 0, marginRight: "5px"}} className="checkbox" type="checkbox" checked={inviteClient} onChange={handleCheckboxChange} /> Invite client
              </div>
            }

            <div className="col-2 text-right">
              {sub.exists ? (
                <a className="btn btn-small color-green">
                  <i className="fas fa-check"></i>
                </a>
              ):(
                <button disabled={disabled} onClick={() => importSubscription(sub, sub.email, sub.customer_name)} className="btn btn-primary btn-small">
                  {disabled ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ):(
                    "Import"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ImportSubscriptionItem;