import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import {ProjectContext} from "../Projects/ProjectContext.js";
import Select from 'react-select';

const SelectProject = ({selectedProject, setSelectedProject, closeModal}) => {
  const {projects, groups} = useContext(ProjectContext);

  const projectsOptions = [
    ...groups.map((group) => ({
      label: group.title,
      options: group.projects.map((project) => ({
        value: project.token,
        label: project.title,
      })),
    })),
    {
      label: "Clients",
      options: projects.map((project) => {
        // Handle nested projects
        const nestedOptions = project.nested_projects?.map(nested => ({
          value: nested.token,
          label: `- ${nested.title}`,
        })) || [];

        return [
          {
            value: project.token,
            label: project.title,
          },
          ...nestedOptions
        ];
      }).flat(),
    },
  ];

  const handleProjectSelect = (selectedOption) => {
    setSelectedProject(selectedOption);
    closeModal();
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'black', // Text color of options
    }),
  };
  
  return(
    <React.Fragment>
      <label>Project</label>
      <Select
        options={projectsOptions}
        value={selectedProject}
        onChange={handleProjectSelect}
        className="color-1"
        classNamePrefix="react-select"
        placeholder="Select..."
        styles={customStyles} 
      />
    </React.Fragment>
  )
}

export default SelectProject;