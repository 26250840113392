import React , { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Oauth from "./Oauth";
import axios from "axios";

const NewAccount = ({organization, email, setStep, setEmail}) => {
  const [subdomain, setSubdomain] = useState('');
  const [isTaken, setIsTaken] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [toggleCode, setToggleCode] = useState(window.location.href.includes("earlybird") ? true : false);
  const [code, setCode] = useState("");
  const history = useHistory(); // Used to navigate programmatically
  const location = useLocation(); // Used to access the current location

  useEffect(() => {
    // Extract the 'code' parameter from the URL
    const queryParams = new URLSearchParams(location.search);
    const codeParam = queryParams.get('code');

    if (codeParam) {
      setToggleCode(true)
      // Set the 'code' parameter to the component state
      setCode(codeParam);
    }
  }, [location.search]);

  const handleChange = (event) => {
    const newSubdomain = event.target.value.replace(/\s/g, ''); // Remove whitespaces
    setSubdomain(newSubdomain);

    // Check if the subdomain is "app"
    if (newSubdomain.toLowerCase() === 'app') {
      setIsTaken(true);
    } else {
      setIsTaken(false);
      checkIfSubdomainTaken(newSubdomain); // Check if subdomain is taken
    }
  };

  const checkIfSubdomainTaken = (subdomain) => {
    axios.get(`/api/verify_subdomain?subdomain=${subdomain}`)
      .then((response) => {
        console.log(response)
        setIsTaken(response.data.taken);
      })
      .catch((error) => {
        setIsTaken(false); // Request failed, treat subdomain as not taken
      });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);
  
    let errors_list = [];
  
    axios
      .post(`/api/registrations`, {
        user: {
          username: username,
          email: email,
          password: password,
          subdomain: subdomain,
          code: code
        },
      })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          window.location.href = `https://${response.data.subdomain}.${window.location.hostname.includes("ngrok") ? "queue.ngrok.io" : "usequeue.com"}/onboardings/${response.data.organization_id}/step1?token=${response.data.user.token}`;
        } else {
          errors_list = response.data.errors;
          errors_list.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
        notice("An error occurred");
      })
      .then(function () {
        setDisabled(false);
      });
  };  

  return(
    <React.Fragment>
      <form id="registrations" onSubmit={handleSubmit}>
        <div class="field text-center mb-25">
          {organization != null && 
            <img src={organization.avatar} className="avatar-md mb-10" />
          }
          <h3 class="font-weight-600">Create an account</h3>
          <p className="opacity-7">You're one step away from something big</p>
        </div>

        {/* Subdomain */}
        <div className="field">
          <div class="input-group">
            <span class="input-group-addon" id="basic-addon1"><i class="fa fa-globe"></i></span>
            <input autoFocus required="true" type="text" class="form-control form-control-dark" placeholder="Subdomain" aria-describedby="basic-addon2" value={subdomain} onChange={handleChange}/>
            <span class="input-group-addon input-group-dark" id="basic-addon2">.usequeue.com</span>
          </div>
          {isTaken && <p className="color-red font-10">Subdomain is taken. Please choose a different one.</p>}
        </div>

        {/* Username */}
        <div class="field">
          <div class="input-group" style={{display: "block"}}>
            <div class="input-group addon">
              <span class="input-group-addon" id="basic-addon1"><i class="fa fa-user"></i></span>
              <input value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Full Name" class="form-control form-control-dark" required="required" type="text"/>
            </div>
          </div>
        </div>

        {/* Email */}
        <div class="field">
          <div class="input-group" style={{display: "block"}}>
            <div id="emailStatus"></div>
            <div class="input-group addon">
              <span class="input-group-addon" id="basic-addon1"><i class="fa fa-envelope"></i></span>
              <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" class="form-control form-control-dark" required="required" type="email" />
            </div>
          </div>
        </div>

        {/* Code */}
        <div class="field">
          {toggleCode && 
            <div class="animated fadeIn input-group" style={{display: "block"}}>
              <div class="input-group addon">
                <span class="input-group-addon" id="basic-addon1"><i class="fa fa-brackets-curly"></i></span>
                <input value={code} onChange={(e) => setCode(e.target.value)} placeholder="Invite code" class="form-control form-control-dark" autocomplete="off" type="Code" required="true"/>
              </div>
            </div>
          }
          <a className="color-1 opacity-7 opacity-10-hover" style={{marginTop: "-15px"}} onClick={()=> setToggleCode(!toggleCode)}>{toggleCode ? "Invite code" : "Invite code"}</a>
        </div>

        {/* Submit */}
        <div class="field">
          <p id="signupError" className="color-red"></p>
          <input class="display-none" type="text" name="tour[false]" value="false" />
          <button disabled={disabled || isTaken || (email.length == 0) || (username.length == 0)} type="submit" class="btn btn-primary btn-block default-padding">Continue</button>
        </div>
      </form>

      <div class="form-group text-center">
        <p className="font-12">
          <a class="color-1" onClick={() => setStep("Check")}>Login instead</a>
        </p>
      </div>
    </React.Fragment>
  )
}

export default NewAccount