import React from 'react';
import Skeleton from "react-loading-skeleton";

const KanBanSkeleton = () => {
  return(
    <React.Fragment>
      <div className="row default-padding">
        <div className="kanban-column default-padding">
          <div className="background-3 border-radius default-padding">
            <Skeleton className="skeleton-tab" style={{height: "20px", marginBottom: "15px"}} />
            <Skeleton className="skeleton-card" />
            <Skeleton className="skeleton-card" />
          </div>
        </div>

        <div className="kanban-column default-padding">
          <div className="background-3 border-radius default-padding">
            <Skeleton className="skeleton-tab" style={{height: "20px", marginBottom: "15px"}} />
            <Skeleton className="skeleton-card" />
            <Skeleton className="skeleton-card" />
          </div>
        </div>

        <div className="kanban-column default-padding">
          <div className="background-3 border-radius default-padding">
            <Skeleton className="skeleton-tab" style={{height: "20px", marginBottom: "15px"}} />
            <Skeleton className="skeleton-card" />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default KanBanSkeleton;