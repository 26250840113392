import React , { useState, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Edit from "./Edit.js";
import {UserContext} from "../Shared/UserContext.js";
import {OrganizationContext} from "../Shared/OrganizationContext.js"
import {Menu, Item, useContextMenu} from "react-contexify";
import {ProjectContext} from "./ProjectContext.js";
import { confirmAlert } from 'react-confirm-alert'; 

const ProjectDropdown = ({project, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [openProjectEdit, setOpenProjectEdit] = useState(false);
  const {organizationAuthorizations, hasPermission} = useContext(OrganizationContext);
  const {fetchProjects, projects, setProjects} = useContext(ProjectContext);
  
  // Update the handleContextMenu to prevent default right-click menu
  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ 
      id: `project-${project.token}-dropdown`,
      position: { x: e.clientX - 200, y: e.clientY + 20 }
    }).show({ event: e });
  };

  const archive = async () => {
    try {
      const response = await axios.post(`/api/o/${match.params.organization_id}/projects/${project.token}/project_archives`)
      console.log(response);
      if(response.data.success){
        refetchData();
        notice("Project has been archived");

        if(location.href.includes(response.data.project.token)){
          history.push(`/o/${match.params.organization_id}/projects`);
        }
      }
    } catch (error) {
      console.log(error)
      notice("An error occured")
    }
  }

  const deleteProject = () => {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this?',
      buttons: [
        {
          label: 'No',
          className: 'btn background-3 btn-small',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Yes',
          className: 'btn btn-danger btn-small',
          onClick: () => {
            axios.delete(`/api/o/${match.params.organization_id}/projects/${project.token}`)
            .then(function(response){
              if(response.data.success){
                
                if(location.href.includes(project.token)) {
                  history.push(`/o/${match.params.organization_id}/projects`);
                }
                refetchData();
                setProjects(projects.filter(p => p.token !== project.token));
              } else {
                response.data.errors.forEach((error) => {
                  notice(error);
                });
              }
            })
            .catch(function(error){
              console.log(error)
              notice("An error occured");
              reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
            })
            .then(function () {

            });
          }
        },
      ]
    });
  }

  const leaveProject = () => {
    axios.post(`/api/o/${match.params.organization_id}/projects/${project.token}/project_users/leave_project`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        location.href = "/"
        notice("Left project")
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {

    });
  }

  return(
    <React.Fragment>
      <a onClick={handleContextMenu} className="navigation-project-item-btn background-hover color-1 opacity-4 opacity-10-hover sidebar-text-color">
        <i className="fas fa-ellipsis-v"></i>
      </a>

      <Menu id={`project-${project.token}-dropdown`}>
        <Item onClick={archive}>
          <i className="fal fa-eye-slash"></i>Hide
        </Item>
        
        {organizationAuthorizations.organization.organization_user && (
          <>
            <Item onClick={() => {setOpenProjectEdit(true)}}>
              <i className="fas fa-pencil"></i>Edit
            </Item>
            
            {hasPermission("delete_project") && (
              <Item onClick={deleteProject}>
                <i className="fal fa-trash"></i>Delete
              </Item>
            )}
          </>
        )}

        {!organizationAuthorizations.organization.organization_user && (
          <Item onClick={leaveProject}>
            <i className="fal fa-sign-out-alt"></i>Leave project
          </Item>
        )}
      </Menu>

      <Modal open={openProjectEdit} onClose={() => setOpenProjectEdit(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Edit onCloseModal={() => setOpenProjectEdit(false)} project={project} closeModal={() => setOpenProjectEdit(false)}/>
      </Modal>
    </React.Fragment>
  )
}

export default ProjectDropdown