import React , { useState, useEffect, useRef } from 'react';


const Approved = ({item}) => {
  const [show, setShow] = useState(false);

  return(
    <React.Fragment>
      <div className="padding-10 border-all border-radius font-13 mt-10">
        <div className="display-row justify-content-between cp" onClick={() => setShow(!show)}>
          
          <div className="">
            {show ?
              <i class="fas fa-chevron-down animated fadeIn mr-8 color-4"></i>
              :
              <i class="fas fa-chevron-right animated fadeIn mr-8 color-4"></i>
            }
            {item.approved ?
            <React.Fragment>
              Approved by {item.user.email}
            </React.Fragment>
             :
             <React.Fragment>
               Changes requested by {item.user.email}
             </React.Fragment>
             }

          </div>
          <div className="" style={{minWidth: "80px"}}>
            {item.created_at}
            <i style={{marginLeft: "8px"}} className={`${item.approved ? 'color-green fas fa-check-circle ' : 'fas fa-times color-red'}`}></i>
          </div>
          

        </div>
        
        {show && 
          <div className="background-5 border-radius color-4 padding-10 mr-10 mt-10 animated fadeIn">{item.comment != null ? item.comment : "No comment"}</div>
        }
      </div>
    </React.Fragment>
  )
}

export default Approved;