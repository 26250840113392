import React , { useState } from 'react';
import axios from "axios"; 
import {Menu, Item, useContextMenu} from "react-contexify";

const NewProperty = ({refetchData, project_id, task, setTask}) => {
  const [disabled, setDisabled] = useState(false);
  const [label, setLabel] = useState("");
  const [picked, setPicked] = useState(null);


  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ id: `dropdown-add-property` }).show({ event: e });
  };

  const addField = (fieldType, kind) => {
    const newField = {
      id: Date.now(),
      type: fieldType,
      label: label || fieldType,
      value: fieldType === 'checkbox' ? [] : '',
      options: [], 
      placeholder: null, 
      position: task.field_data?.length + 1, 
      kind: kind, 
      isRequired: false, 
      maxCharacters: null
    };

    console.log(newField);

    axios.post(`/api/portal/projects/${project_id}/tasks/${task.token}/add_field`, {
      field: newField
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setTask(prevTask => ({
          ...prevTask,
          field_data: [...prevTask.field_data, response.data.field]
        }));
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const resetStuff = () => {
    setPicked(null);
    setLabel("");
  }

  return(
    <React.Fragment>
      <a className="color-1 btn background-hover btn-small opacity-6 opacity-10-hover" onClick={handleContextMenu} style={{marginLeft: "-5px"}}>
        <i className="fal fa-plus mr-8"></i>Add property
      </a>

      <Menu id={`dropdown-add-property`}>
        {!picked ? (
          <>
            <Item closeOnClick={false} onClick={() => setPicked({type: "text", kind: "text"})}>
              <i className="far fa-horizontal-rule"></i> Text
            </Item>

            <Item closeOnClick={false} onClick={() => setPicked({type: "textarea", kind: ""})}>
              <i className="far fa-grip-lines"></i> Textarea
            </Item>

            <Item closeOnClick={false} onClick={() => setPicked({type: "text", kind: "link"})}>
              <i class="far fa-link"></i> Link
            </Item>

            <Item closeOnClick={false} onClick={() => setPicked({type: "text", kind: "date"})}>
              <i className="far fa-calendar"></i> Date
            </Item>

            <Item closeOnClick={false} onClick={() => setPicked({type: "upload", kind: ""})}>
              <i class="far fa-upload"></i> Upload
            </Item>
          </>
        ):(
          <>
            <Item closeOnClick={false} onKeyDown={(e) => e.stopPropagation()}>
              <div>
                <a className="btn btn-small color-1 ml-15-negative mb-15 opacity-4 opacity-10-hover" onClick={resetStuff}><i className="fa fa-angle-left mr-8"></i>Back</a>
                <div class="input-group">
                  <span class="input-group-addon">
                    {picked.type === "text" && picked.kind === "text" && <i className="far fa-horizontal-rule"></i>}
                    {picked.type === "textarea" && <i className="far fa-grip-lines"></i>}
                    {picked.type === "text" && picked.kind === "link" && <i className="far fa-link"></i>}
                    {picked.type === "text" && picked.kind === "date" && <i className="far fa-calendar"></i>}
                    {picked.type === "upload" && <i className="far fa-upload"></i>}
                  </span>
                  <input 
                    type="text" 
                    class="form-control" 
                    value={label} 
                    onChange={(e) => setLabel(e.target.value)} 
                    required="true"
                    placeholder="Property name"
                    onKeyDown={(e) => {
                      e.stopPropagation();
                      if (e.key === 'Escape') {
                        resetStuff();
                      } else if (e.key === 'Enter') {
                        addField(picked.type, picked.kind);
                        resetStuff();
                      }
                    }}
                    autoFocus
                  />
                  <span class="input-group-btn">
                    <button class="btn btn-primary" type="submit" onClick={() => {addField(picked.type, picked.kind); resetStuff(); }}>Add</button>
                  </span>
                </div>
              </div>
            </Item>
          </>
        )}
      </Menu>
    </React.Fragment>
  )
}

export default NewProperty;