import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import {UserContext} from "../Shared/UserContext.js";
import _ from 'lodash';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import OrganizationUsers from "./OrganizationUsers.js";
import Clients from "./Clients.js";

const People = (props) => {
  const currentUser = useContext(UserContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [copied, setCopied] = useState(false)
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([])
  const [clients, setClients] = useState([]); 
  const [organization, setOrganization] =  useState(); 
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [invites, setInvites] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam !== null) {
      setActiveTab(parseInt(tabParam, 10));
    }
  }, [location.search]);

  const handleTabChange = (index) => {
    setActiveTab(index);
    history.push(`${location.pathname}?tab=${index}`);
  };

  useEffect(() => {
    fetchPeople();
    fetchRoles();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  },[copied])

  const fetchPeople = () => {
    axios.get(`/api/o/${match.params.organization_id}/people.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganizationUsers(response.data.organization_users);
        setProjectUsers(response.data.project_users);
        setClients(response.data.clients);
        setOrganization(response.data.organization)
        setCompanies(response.data.companies);
        setInvites(response.data.invites);
        setLoaded(true)
      } else {
        notice(response.data.error);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
    });
  }

  const fetchRoles = () => {
    axios.get(`/api/o/${match.params.organization_id}/roles.json`)
    .then(function(response){
      if(response.data.success){
        setRoles(response.data.roles);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {

    });
  }

  return(
    loaded && 
    <React.Fragment>
      <title>Team</title>

      <Tabs selectedIndex={activeTab} onSelect={handleTabChange}>
        <div className="container-fluid pt-25">
          <TabList>
            <Tab>Team</Tab>
            <Tab>Clients</Tab>
          </TabList>

          <TabPanel>
            <OrganizationUsers invites={invites} organizationUsers={organizationUsers} roles={roles} refetchData={() => {fetchPeople(); fetchRoles();}} organization={organization} setOrganizationUsers={setOrganizationUsers}/>
          </TabPanel>

          <TabPanel>
            <Clients clients={clients} setClients={setClients} companies={companies} refetchData={() => {fetchPeople(); fetchRoles();}} invites={invites}/>
          </TabPanel>
        </div>
      </Tabs>

    </React.Fragment>
  )
}

export default People