import React , { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 

const RecentReplays = ({task_id, refetchData, project_id}) => {
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [replays, setReplays] = useState([]);

  useEffect(() => {
    fetchReplays();
  }, []);

  const fetchReplays = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}/all_replays.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setReplays(response.data.replays);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const createTaskReplay = (replay_id) => {
    setDisabled(true);

    axios.post(`/api/replays/${replay_id}/task_replays`, {
      task_id: task_id
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        notice("Added successfully");
      } else {
        errors_list = response.data.errors;
        errors_list.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const replays_list = replays.map(replay => {
    return(
      <tr className="background-3">
        <td><img src={replay.preview_image} style={{height: 30, width: 69, borderRadius: 5, marginRight: 5}}/></td>
        <td>{replay.title}</td>
        <td>{replay.created_at}</td>
        <td>{replay.user.username}</td>
        <td className="text-right">
          <button disabled={disabled} onClick={() => createTaskReplay(replay.token, task_id)} className="btn btn-primary btn-small">Add</button>
        </td>
      </tr>
    )
  })

  return(
    loaded ? (
      <React.Fragment>
        <div className="row">
          <table class="table react-table-table">
            <thead>
              <tr>
                <th className="react-table-th" scope="col"></th>
                <th className="react-table-th" scope="col">Title</th>
                <th className="react-table-th" scope="col">Uploaded</th>
                <th className="react-table-th" scope="col">By</th>
                <th className="react-table-th" scope="col"></th>
              </tr>
            </thead>

            <tbody>
              {replays_list}
            </tbody>
          </table>
        </div>
      </React.Fragment>
    ):(
      <div className="row">
        <div className="text-center large-padding">
          <i className="fas fa-spinner fa-spin mr-10"></i>Loading files...
        </div>
      </div>
    )
  )
}

export default RecentReplays;