import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import EmojiPicker from 'emoji-picker-react';
import "./emojis.css";

const Emojis = ({refetchData, task, taskComment, project_id, taskComments, setTaskComments, openEmoji}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const handleSubmit = (emoji) => {
    // const emoji = emojiObject.emoji;

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments/${taskComment.token}/reactions`,{
      emoji: emoji
    })
    .then(function(response){
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <a className="emoji-btn btn btn-small background-hover" onClick={() => handleSubmit("👍")}>
        <div className="emoji-size">👍</div>
      </a>

      <a className="emoji-btn btn btn-small background-hover" onClick={() => handleSubmit("👎")}>
        <div className="emoji-size">👎</div>
      </a>

      <a className="emoji-btn btn btn-small background-hover" onClick={() => handleSubmit("✅")}>
        <div className="emoji-size">✅</div>
      </a>

      <a className="emoji-btn btn btn-small background-hover" onClick={() => handleSubmit("😀")}>
        <div className="emoji-size">😀</div>
      </a>

      <a className="emoji-btn btn btn-small background-hover" onClick={() => handleSubmit("😍")}>
        <div className="emoji-size">😍</div>
      </a>

      <a className="emoji-btn btn btn-small background-hover" onClick={() => handleSubmit("🥰")}>
        <div className="emoji-size">🥰</div>
      </a>

      <a className="emoji-btn btn btn-small background-hover" onClick={() => handleSubmit("🥳")}>
        <div className="emoji-size">🥳</div>
      </a>

      <a className="emoji-btn btn btn-small background-hover" onClick={() => handleSubmit("💙")}>
        <div className="emoji-size">💙</div>
      </a>
    </React.Fragment>
  )
}

export default Emojis;