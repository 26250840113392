import React from 'react';
import Filestack from "./Filestack.js";

const File = ({project_id, folder_id, refetchData, closeModal, task_id}) => {
  return(
    <React.Fragment>
      <Filestack project_id={project_id} folder_id={folder_id} task_id={task_id} refetchData={refetchData} closeModal={() => {closeModal()}} closeParentModal={() => closeModal()}/>
    </React.Fragment>
  )
}

export default File