import React from 'react';
import axios from "axios"; 
import { PickerOverlay, PickerInline } from 'filestack-react';

const FilestackAttachements = ({setAttachements, closeModal}) => {
  const uploadAttachements = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    console.log(filesUploaded)
    setAttachements(filesUploaded)
    Object.values(filesUploaded).map((file, index) => {
      console.log(file, index);
    });
  }

  return(
    <React.Fragment>
      <PickerInline
        apikey={"AKVhxMbEQhkOIryqBvSEQz"}
        onSuccess={(res) => console.log(res)}
        onUploadDone={(res) => {
          uploadAttachements(res);
        }}
        clientOptinos={{
          sessionCache:true
        }}
        pickerOptions={{
          onClose: () => {
            closeModal();
          }, 
          fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
          maxFiles: 15, 
          storeTo: {
            location: 'gcs',
            path: '/',
            container: 'queue-filestack'
          }
        }}
      />
    </React.Fragment>
  )
}

export default FilestackAttachements