import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import {Menu, Item, useContextMenu} from "react-contexify";
import StageIcon from "../../../../../Shared/StageIcon.js";
import {TaskboardContext} from "../../../../../Shared/TaskboardContext.js";

const UpdateColumn = ({column, project_id, task_id}) => {
  const {columns, setColumns} = useContext(TaskboardContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const changeColumn = (column_id) => {
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task_id}/update_position`, {
      // column_id: column_id,
      position: 0,
      title: column.title
    })
    .then(function (response) {
      if (response.data.success && response.data.task_data) {
        setColumns(prevColumns => {
          // Create a new copy of columns
          const newColumns = [...prevColumns];
          
          // Find and remove task from its current column
          newColumns.forEach(col => {
            if (col.tasks) {
              col.tasks = col.tasks.filter(task => task.token !== response.data.task_data.token);
            }
          });

          // Find target column and add task to it
          const targetColumn = newColumns.find(col => col.title === response.data.task_data.column.title);
          if (targetColumn) {
            if (targetColumn.tasks) {
              targetColumn.tasks.unshift(response.data.task_data);
            } else if (targetColumn.tasks) {
              targetColumn.tasks.unshift(response.data.task_data);
            }
          }

          return newColumns;
        });
      } else {
        notice("Failed to update column");
      }
    })
    .catch(function (error) {
      console.log(error);
      notice("An error occurred while updating the column");
    });
  }


  return(
    <React.Fragment>
      <Item onClick={() => changeColumn(column.token)}>
        <StageIcon stage={column.stage}/>{column.title}
      </Item>
    </React.Fragment>
  )
}

export default UpdateColumn;